import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
      BlogContainer,
      BlogContent,
      BlogDescription,
      BlogImage,
      BlogRelated,
      BlogTitle
} from '../../styles/frontend/blogs';
import { Link, useParams } from 'react-router-dom';
import SocialMediaList from '../../components/frontend/blogs/SocialMediaList';
import { Helmet } from 'react-helmet';

export const BlogDetail = () => {
      const { blogId } = useParams();
      const [blog, setBlog] = useState([]);
      const [blogs, setBlogs] = useState([]);
      const [loading, setLoading] = useState(false);

      useEffect(() => {
            const loadData = async () => {
                  setLoading(true);
                  await axios
                        .get(
                              `${process.env.REACT_APP_SECRET_KEY}/api/blogs/${blogId}`,
                              {
                                    headers: {
                                          apikey: process.env.REACT_APP_Api_Key
                                    }
                              }
                        )
                        .then((response) => {
                              if (response.data.result === 'success') {
                                    setBlog(response.data.blog);
                                    setBlogs(response.data.blogs);
                              }
                              setLoading(false);
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
            };

            loadData();
      }, [blogId]);

      return (
            <>
                  {!loading && (
                        <Helmet>
                              <title>{blog.seo_title || blog.title}</title>
                              <meta
                                    name="description"
                                    content={
                                          blog.seo_description || blog.summary
                                    }
                              />
                              <meta name="keyword" content={blog.seo_keyword} />
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  )}
                  <BlogContainer>
                        <Row>
                              <Col lg={12}>
                                    <BlogImage>
                                          <img
                                                src={blog.image}
                                                alt={blog.title}
                                                height="100%"
                                                width="100%"
                                          />
                                    </BlogImage>
                              </Col>
                              <Col lg={8}>
                                    <BlogContent>
                                          <BlogTitle>{blog.title}</BlogTitle>
                                          <BlogDescription>
                                                <div
                                                      dangerouslySetInnerHTML={{
                                                            __html: blog.description
                                                      }}
                                                ></div>
                                          </BlogDescription>
                                    </BlogContent>
                              </Col>
                              <Col lg={4}>
                                    <BlogRelated>
                                          <ul>
                                                {!loading ? (
                                                      blogs.length > 0 ? (
                                                            blogs.map(
                                                                  (
                                                                        blog,
                                                                        index
                                                                  ) => (
                                                                        <li
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <Link
                                                                                    to={`/blogs/${blog.slug}`}
                                                                              >
                                                                                    <div>
                                                                                          {
                                                                                                blog.index
                                                                                          }
                                                                                    </div>
                                                                                    <div>
                                                                                          <span>
                                                                                                {
                                                                                                      blog.title
                                                                                                }
                                                                                          </span>
                                                                                    </div>
                                                                              </Link>
                                                                        </li>
                                                                  )
                                                            )
                                                      ) : (
                                                            <>
                                                                  No Related
                                                                  Blog Found
                                                            </>
                                                      )
                                                ) : (
                                                      <>Loading...</>
                                                )}
                                          </ul>
                                    </BlogRelated>
                              </Col>
                        </Row>
                  </BlogContainer>
                  <SocialMediaList />
            </>
      );
};
