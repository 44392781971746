import React, { useState, useEffect, useContext } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Col, Row } from 'react-bootstrap';
import {
      FaqTabContainer,
      FaqTabContent,
      FaqTabHeader,
      FaqTabPanel
} from '../../styles/frontend/faqs';
import { PolicyContainer, PolicyTabLink } from '../../styles/frontend/policy';
import axios from 'axios';
import FooterBreadcrumb from '../../components/common/FooterBreadcrumb';
import MetaContext from '../../stores/MetaContext';

const PrivacyPolicy = () => {
      const [policy, setPolicy] = useState([]);
      const [loading, setLoading] = useState(false);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('privacy-policy');

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/pages/privacy-policy`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPolicy(response.data.page);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Breadcrumb title="Privacy Policy " summary="All Policy" />

                  <PolicyContainer>
                        <Row>
                              <Col lg={12}>
                                    <FaqTabContainer>
                                          <FaqTabHeader>
                                                <PolicyTabLink to="/dealer-policy">
                                                      Dealer Policy
                                                </PolicyTabLink>
                                                <PolicyTabLink to="/terms-and-conditions">
                                                      Terms & Condition
                                                </PolicyTabLink>
                                                <PolicyTabLink
                                                      to="/privacy-policy"
                                                      className="active"
                                                >
                                                      Privacy Policy
                                                </PolicyTabLink>
                                          </FaqTabHeader>

                                          <FaqTabContent>
                                                <FaqTabPanel className="active">
                                                      {!loading ? (
                                                            <div
                                                                  dangerouslySetInnerHTML={{
                                                                        __html: policy.description
                                                                  }}
                                                            ></div>
                                                      ) : (
                                                            <div>
                                                                  Loading...
                                                            </div>
                                                      )}
                                                </FaqTabPanel>
                                          </FaqTabContent>
                                    </FaqTabContainer>
                              </Col>
                        </Row>
                  </PolicyContainer>

                  <FooterBreadcrumb />
            </>
      );
};

export default PrivacyPolicy;
