import styled from 'styled-components';

export const CatalogueBody = styled.div`
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 20px;
      margin-bottom: 50px;
      padding: 0 50px;

      @media screen and (max-width: 1200px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @media screen and (max-width: 640px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
            padding: 0;
      }
`;

export const CatalogueItem = styled.div`
      border: 1px solid #f2f2f2;
`;

export const CatalogueItemImage = styled.div`
      border-bottom: 1px solid #f2f2f2;

      img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
      }

      a {
            display: block;
            height: 322px;
      }
`;

export const CatalogueItemTitle = styled.div`
      font-size: 18px;

      a {
            color: ${(props) => props.theme.primaryColor};
      }
`;

export const CatalogueItemDetail = styled.div`
      padding: 20px 15px;
      background-color: #d9d9d9;
`;

export const CatalogueItemIcon = styled.div`
      height: 20px;
      font-size: 20px;
      display: flex;
      gap: 10px;
      align-items: center;
      margin: 5px 0;
      justify-content: space-between;

      a {
            color: #68798b;
            font-weight: 400;
            font-size: 16px;
      }
`;

export const CatalogueItemShare = styled.div`
      display: flex;
      gap: 10px;
      align-items: center;

      button {
            color: ${(props) => props.theme.black};
            font-size: 18px;
            line-height: 0;
            background-color: transparent;
            border: 0;
      }
`;
