import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Breadcrumb from '../../components/common/breadcrumb';
import { CommonContainer } from '../../auth/Login/style';
import {
      CartDescription,
      CartImage,
      CartInfo,
      CartRemove,
      CartTable,
      CartTitle,
      CheckoutLink
} from './style';
import CartContext from '../../stores/CartContext';
import { Loading } from '../../styles/components/form/Loading';

const CartPage = () => {
      const cartCtx = useContext(CartContext);

      const [navigate, setNavigate] = useState(false);

      const checkTokenExpiration = () => {
            const tokenExpiration = localStorage.getItem('tokenExpiration');
            if (!tokenExpiration || new Date(tokenExpiration) < new Date()) {
                  localStorage.removeItem('token');
                  localStorage.removeItem('isLoggedIn');
                  localStorage.removeItem('tokenExpiration');

                  setNavigate(true);
            }
      };

      const handleQuantityChange = (event, index) => {
            const { value } = event.target;

            const itemId = cartCtx.carts[index].slug;

            cartCtx.updateQuantity(itemId, value, index);
      };

      useEffect(() => {
            checkTokenExpiration();
            const intervalId = setInterval(checkTokenExpiration, 10000);
            return () => clearInterval(intervalId);
      }, []);

      if (navigate) {
            return <Navigate to="/login" />;
      }

      return (
            <>
                  <Breadcrumb title="Shopping Cart" />
                  <section className="mb-5">
                        <CommonContainer>
                              <CartTable bordered>
                                    <thead>
                                          <tr>
                                                <th
                                                      className="table-product"
                                                      width="60%"
                                                >
                                                      Product
                                                </th>
                                                <th className="table-qty">
                                                      Quantity
                                                </th>
                                                <th className="table-price">
                                                      Price (Rs)
                                                </th>
                                                <th className="table-total">
                                                      Total (Rs)
                                                </th>
                                          </tr>
                                    </thead>
                                    <tbody>
                                          {!cartCtx.loading ? (
                                                cartCtx.carts?.length > 0 ? (
                                                      cartCtx.carts?.map(
                                                            (item, index) => (
                                                                  <tr>
                                                                        <td>
                                                                              <CartInfo>
                                                                                    <CartRemove
                                                                                          onClick={() =>
                                                                                                cartCtx.removeProduct(
                                                                                                      item.slug
                                                                                                )
                                                                                          }
                                                                                    >
                                                                                          <svg
                                                                                                fill="currentColor"
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                viewBox="0 0 24 24"
                                                                                                width="16px"
                                                                                                height="16px"
                                                                                          >
                                                                                                <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path>
                                                                                          </svg>
                                                                                    </CartRemove>
                                                                                    <CartDescription>
                                                                                          <CartImage>
                                                                                                <img
                                                                                                      className="table-p-img"
                                                                                                      src={
                                                                                                            item.image
                                                                                                      }
                                                                                                />
                                                                                          </CartImage>
                                                                                          <CartTitle>
                                                                                                {
                                                                                                      item.title
                                                                                                }
                                                                                          </CartTitle>
                                                                                    </CartDescription>
                                                                              </CartInfo>
                                                                        </td>
                                                                        <td>
                                                                              <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    min="1"
                                                                                    style={{
                                                                                          width: '100px',
                                                                                          margin: 'auto'
                                                                                    }}
                                                                                    defaultValue={
                                                                                          item.qty
                                                                                    }
                                                                                    onChange={(
                                                                                          event
                                                                                    ) =>
                                                                                          handleQuantityChange(
                                                                                                event,
                                                                                                index
                                                                                          )
                                                                                    }
                                                                              />
                                                                        </td>
                                                                        <td>
                                                                              {
                                                                                    item.price
                                                                              }
                                                                        </td>
                                                                        <td>
                                                                              {
                                                                                    item.subtotal
                                                                              }
                                                                        </td>
                                                                  </tr>
                                                            )
                                                      )
                                                ) : (
                                                      <>
                                                            <tr>
                                                                  <td
                                                                        colSpan={
                                                                              8
                                                                        }
                                                                  >
                                                                        <div className="text-center">
                                                                              Product
                                                                              Not
                                                                              Found
                                                                        </div>
                                                                  </td>
                                                            </tr>
                                                      </>
                                                )
                                          ) : (
                                                <>
                                                      <tr>
                                                            <td
                                                                  colSpan={4}
                                                                  className="text-center"
                                                            >
                                                                  <Loading />
                                                            </td>
                                                      </tr>
                                                </>
                                          )}

                                          {!cartCtx.loading &&
                                                cartCtx.carts?.length > 0 && (
                                                      <tr>
                                                            <td
                                                                  colSpan={2}
                                                            ></td>
                                                            <td>
                                                                  <b>Total</b>
                                                            </td>
                                                            <td>
                                                                  <b>
                                                                        Rs.{' '}
                                                                        {
                                                                              cartCtx.totalAmount
                                                                        }
                                                                  </b>
                                                            </td>
                                                      </tr>
                                                )}
                                    </tbody>
                              </CartTable>

                              <div className="mt-3 text-end">
                                    <CheckoutLink
                                          to="/products"
                                          color="#111111"
                                    >
                                          Continue shopping
                                    </CheckoutLink>

                                    <CheckoutLink to="/checkouts">
                                          Checkout
                                    </CheckoutLink>
                              </div>
                        </CommonContainer>
                  </section>
            </>
      );
};

export default CartPage;
