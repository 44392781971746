import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Col, Container, Form, Row } from 'react-bootstrap';
import {
      EnquiryBox,
      EnquiryFooter,
      EnquirySlider
} from '../../styles/frontend/dealers';
import { Link } from 'react-router-dom';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { toast } from 'react-hot-toast';

import $ from 'jquery';
import axios from 'axios';
import FormInput from '../../components/common/form/FormInput';
import {
      DealerIcon,
      PrivacyIcon,
      TermsCondition
} from '../../components/icons';
import SupportTicketIcon from '../../components/icons/SupportTicketIcon';
import { SwiperSlide } from 'swiper/react';
import FormTextarea from '../../components/common/form/FormTextarea';
import FormSelect from '../../components/common/form/FormSelect';
import FormButton from '../../components/common/form/FormButton';
import MetaContext from '../../stores/MetaContext';

const YourQuestion = () => {
      const [faqs, setFaqs] = useState([]);
      const [loadingData, setLoadingData] = useState(false);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('ask-your-question');

      const [name, setName] = useState('');
      const [faq, setFaq] = useState('');
      const [email, setEmail] = useState('');
      const [message, setMessage] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/faq_types`, {
                        headers: {
                              apikey: process.env.REACT_APP_Api_Key
                        }
                  })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setFaqs(response.data.types);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleSubmit = async (event) => {
            event.preventDefault();

            const data = {
                  name: name,
                  type: faq,
                  email: email,
                  question: message,
                  phone_number: phoneNumber
            };
            setLoading(true);
            await axios
                  .post(`${process.env.REACT_APP_SECRET_KEY}/api/faqs`, data, {
                        headers: {
                              apikey: process.env.REACT_APP_Api_Key
                        }
                  })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              $('form').trigger('reset');
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.type) {
                                    toast.error(response.data.message.type);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                              if (response.data.message.question) {
                                    toast.error(response.data.message.question);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoading(false);
      };

      const options = {
            margin: 30,
            responsiveClass: true,
            nav: false,
            dots: false,
            lazyLoad: true,
            autoplay: false,
            navText: ['Prev', 'Next'],
            smartSpeed: 1000,
            responsive: {
                  0: {
                        items: 1
                  },
                  400: {
                        items: 1
                  },
                  600: {
                        items: 2
                  },
                  700: {
                        items: 3
                  },
                  1000: {
                        items: 4
                  }
            }
      };

      return (
            <>
                  <Breadcrumb
                        otherTitle="FAQs"
                        otherLink="/faqs"
                        title="Ask your question"
                  />

                  <Container>
                        <Row className="justify-content-center">
                              <Col lg={10}>
                                    <EnquiryBox>
                                          <Form onSubmit={handleSubmit}>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Full Name"
                                                            required={true}
                                                            handleChange={
                                                                  setName
                                                            }
                                                      />
                                                      <FormSelect
                                                            title="Faq Type"
                                                            loading={
                                                                  loadingData
                                                            }
                                                            options={faqs}
                                                            required={true}
                                                            setData={setFaq}
                                                      />
                                                      <FormInput
                                                            title="Email Address"
                                                            required={true}
                                                            type="email"
                                                            handleChange={
                                                                  setEmail
                                                            }
                                                      />

                                                      <FormInput
                                                            title="Phone No."
                                                            required={true}
                                                            handleChange={
                                                                  setPhoneNumber
                                                            }
                                                      />
                                                      <FormTextarea
                                                            title="Your Query"
                                                            required={true}
                                                            classes={12}
                                                            handleChange={
                                                                  setMessage
                                                            }
                                                      />
                                                      <FormButton
                                                            loading={loading}
                                                      />
                                                </Row>
                                          </Form>
                                    </EnquiryBox>
                              </Col>
                        </Row>
                  </Container>

                  <EnquiryFooter>
                        <Container>
                              <EnquirySlider {...options} className="owl-theme">
                                    <SwiperSlide>
                                          <Link to="/customer-enquiry">
                                                <div>
                                                      <SupportTicketIcon />
                                                </div>
                                                <div>Customer Enquiry</div>
                                          </Link>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                          <Link to="/dealer-enquiry">
                                                <div>
                                                      <DealerIcon />
                                                </div>
                                                <div>Dealer Enquiry</div>
                                          </Link>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                          <Link to="/terms-and-conditions">
                                                <div>
                                                      <TermsCondition />
                                                </div>
                                                <div>Terms & Conditions</div>
                                          </Link>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                          <Link to="/support-ticket">
                                                <div>
                                                      <SupportTicketIcon />
                                                </div>
                                                <div>Support Ticket</div>
                                          </Link>
                                    </SwiperSlide>
                              </EnquirySlider>
                        </Container>
                  </EnquiryFooter>
            </>
      );
};

export default YourQuestion;
