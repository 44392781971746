import React, { useContext } from 'react';
import {
      Brand,
      Catalogue,
      HomeCategoryList,
      HomeProductSlider,
      Testimonial,
      WhyUs
} from '../../components/frontend/home';
import MetaContext from '../../stores/MetaContext';

const HomePage = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('home');

      return (
            <>
                  <h1 className="d-none">{metaCtx.meta.seo_title}</h1>
                  <HomeCategoryList />
                  <HomeProductSlider />
                  <WhyUs />
                  <Brand />
                  <Testimonial />
                  <Catalogue />
            </>
      );
};
export default HomePage;
