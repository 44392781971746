import React, { useContext } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Col } from 'react-bootstrap';
import {
      CareerBody,
      CareerContainer,
      CareerHeader,
      CareerHeaderIcon,
      CareerHeaderImage,
      CareerItem,
      CareerLink,
      CareerRow
} from '../../styles/frontend/career';
import { ClockIcon } from '../../components/icons';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import MetaContext from '../../stores/MetaContext';

export const Career = () => {
      const [loading, setLoading] = useState(false);
      const [careers, setCareers] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/careers`, {
                        headers: {
                              apikey: process.env.REACT_APP_Api_Key
                        }
                  })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCareers(response.data.jobs);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('careers');

      return (
            <>
                  <Breadcrumb title="Careers" />
                  <CareerContainer>
                        <CareerRow>
                              {!loading &&
                                    careers.length > 0 &&
                                    careers.map((career, index) => (
                                          <Col lg={8} key={index}>
                                                <CareerItem>
                                                      <CareerHeader>
                                                            <CareerHeaderImage>
                                                                  <img
                                                                        src="/images/png/headerlogo.png"
                                                                        alt="Bathnroom Logo"
                                                                  />
                                                                  <div>
                                                                        <div>
                                                                              {
                                                                                    career.title
                                                                              }
                                                                        </div>
                                                                        <div>
                                                                              {
                                                                                    career.type
                                                                              }
                                                                        </div>
                                                                  </div>
                                                            </CareerHeaderImage>
                                                            <CareerHeaderIcon>
                                                                  <ClockIcon />
                                                                  <div>
                                                                        Posted{' '}
                                                                        {
                                                                              career.date
                                                                        }
                                                                  </div>
                                                            </CareerHeaderIcon>
                                                      </CareerHeader>
                                                      <CareerBody>
                                                            <p>
                                                                  No of Vacant:{' '}
                                                                  {
                                                                        career.vacancy
                                                                  }{' '}
                                                                  <br />
                                                                  Salary:{' '}
                                                                  {
                                                                        career.salary
                                                                  }{' '}
                                                                  <br />
                                                                  Deadline:{' '}
                                                                  {
                                                                        career.deadline
                                                                  }{' '}
                                                                  <br />
                                                                  Qualification:{' '}
                                                                  {
                                                                        career.education
                                                                  }
                                                                  <br />
                                                                  Experience:{' '}
                                                                  {
                                                                        career.experience
                                                                  }
                                                            </p>
                                                      </CareerBody>
                                                      <CareerLink>
                                                            <Link
                                                                  to={`/careers/${career.slug}`}
                                                            >
                                                                  Read More
                                                            </Link>
                                                      </CareerLink>
                                                </CareerItem>
                                          </Col>
                                    ))}
                        </CareerRow>
                  </CareerContainer>
            </>
      );
};
