import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';

import { FreeMode, Thumbs } from 'swiper';
import { SwiperThumbnail } from './style';

const ProductSlider = ({ loading, product }) => {
      const [thumbsSwiper, setThumbsSwiper] = useState(null);

      return (
            <>
                  <Swiper
                        spaceBetween={10}
                        thumbs={{ swiper: thumbsSwiper }}
                        modules={[FreeMode, Thumbs]}
                        className="mySwiper2 mb-3 border-bottom"
                  >
                        <SwiperSlide>
                              <div
                                    style={{
                                          width: '100%',
                                          height: '100%',
                                          padding: '20px'
                                    }}
                              >
                                    <div
                                          style={{
                                                height: '320px',
                                                width: '320px'
                                          }}
                                          className="m-auto"
                                    >
                                          <img
                                                src={product.image}
                                                style={{
                                                      height: '100% !important',
                                                      objectFit: 'contain'
                                                }}
                                                alt={product.title}
                                          />
                                    </div>
                              </div>
                        </SwiperSlide>
                        {!loading &&
                              product.images &&
                              product.images.map((image, index) => (
                                    <SwiperSlide key={index}>
                                          <div
                                                style={{
                                                      width: '100%',
                                                      height: '100%',
                                                      padding: '20px'
                                                }}
                                          >
                                                <div
                                                      style={{
                                                            height: '320px',
                                                            width: '320px'
                                                      }}
                                                      className="m-auto"
                                                >
                                                      <img
                                                            src={image.image}
                                                            style={{
                                                                  height: '100% !important',
                                                                  objectFit:
                                                                        'contain'
                                                            }}
                                                      />
                                                </div>
                                          </div>
                                    </SwiperSlide>
                              ))}
                  </Swiper>
                  <SwiperThumbnail
                        onSwiper={setThumbsSwiper}
                        spaceBetween={10}
                        slidesPerView={4}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Thumbs]}
                        className="mySwiper"
                  >
                        <SwiperSlide>
                              <img src={product.image} />
                        </SwiperSlide>
                        {!loading &&
                              product.images &&
                              product.images.map((image, index) => (
                                    <SwiperSlide key={index}>
                                          <img src={image.image} />
                                    </SwiperSlide>
                              ))}
                  </SwiperThumbnail>
            </>
      );
};

export default ProductSlider;
