import { Accordion } from 'react-bootstrap';
import styled from 'styled-components';

export const CategoryList = styled.div`
      ul {
            li {
                  list-style-type: none;

                  a {
                        list-style-type: none;
                        font-size: 16px;
                        color: var(--background-color);
                  }
            }
      }
`;

export const ProductListItems = styled.div`
      background: white;
      padding: 10px 20px;
      margin-bottom: 20px;
`;

export const ProductListTitle = styled.div`
      h2 {
            border-bottom: 1px dotted #000000;
            font-size: 20px;
            text-transform: uppercase;
            letter-spacing: 1px;
      }
`;

export const ProductListContent = styled.div`
      max-height: 286px;
      overflow: hidden auto;
      padding: 10px 0;
      overflow-x: hidden !important;

      &::-webkit-scrollbar {
            width: 6px;
      }

      &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
            transition: background 0.3s ease-in-out;
      }

      &::-webkit-scrollbar-thumb:hover {
            background: #555;
      }
`;

export const ProductImage = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      height: 220px;
      padding: 10px;

      a {
            display: block;
            height: 100%;
            width: 100%;

            img {
                  object-fit: contain;
                  height: 100%;
                  width: 100%;
            }
      }

      @media screen and (max-width: 600px) {
            height: 178px;
      }
`;

export const ProductTitle = styled.div`
      a h4 {
            font-size: 16px;
            color: #333;
      }

      h4 {
            line-height: 24px;
      }
`;

export const ProductPrice = styled.div`
      font-size: 16px;
      color: #525252;
      font-weight: 600;
`;

export const Title = styled.div`
      position: relative;
      font-weight: 300;
      font-style: italic;
      padding: 15px 20px;

      &::after {
            position: absolute;
            content: '';
            height: 3px;
            width: 20px;
            background-color: #008080;
            top: 30px;
            left: 0;
      }
`;

export const CategoryFilterContainer = styled(Accordion)`
      padding-right: 10px;
`;

export const CategoryFilterHeader = styled(Accordion.Header)`
      button {
            padding: 0;
            background-color: transparent !important;
            box-shadow: none !important;
            font-weight: 400;
            font-size: 1rem;
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      }
`;

export const CategoryFilterBody = styled(Accordion.Body)`
      padding: 0;

      ul {
            padding-left: 16px;

            li {
                  border-bottom: 1px solid #dee2e6;
                  padding-bottom: 5px;
                  padding-top: 5px;

                  &:last-child {
                        border-bottom: 0 !important;
                  }
            }

            .accordion {
                  border-bottom: 1px solid #dee2e6 !important;

                  &:last-child {
                        border-bottom: 0 !important;
                  }
            }
      }
`;

export const CategoryListFilter = styled.ul`
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;
      margin: 0;
      padding-left: 17px !important;
      grid-gap: 10px;
      padding-bottom: 20px;
`;

export const CategoryListItem = styled.div`
      position: relative;
      background-color: #fff;
      text-transform: capitalize;
      color: #3e4152;
      cursor: default;
      font-size: 14px;
      padding: 6px 36px 5px 20px;
      -webkit-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
      border-radius: 20px;
      border: 1px solid #d4d5d9;
`;

export const CategoryListRemove = styled.label`
      position: absolute;
      top: 4px;
      right: 4px;
      width: 18px;
      height: 20px;
      z-index: 1;
      text-align: center;
      cursor: pointer;
      right: 10px;
      top: 4px;
`;

export const CategoryListRemoveIcon = styled.span`
      vertical-align: middle;
      opacity: 0.7;
      -webkit-transform: scale(0.7);
      transform: scale(0.7);

      i {
            font-size: 20px;
      }
`;

export const FilterContainer = styled.div`
      display: flex;
      margin-bottom: 2rem;
      justify-content: space-between;
      align-items: center;
      background: white;
      padding: 8px 12px;

      @media screen and (max-width: 600px) {
            margin-bottom: 1rem;
      }
`;

export const DesktopFilter = styled.div`
      @media screen and (max-width: 600px) {
            display: none;
      }
`;

export const MobileFilter = styled.div`
      display: none;

      @media screen and (max-width: 1200px) {
            display: block;

            ${DesktopFilter} {
                  display: none;
            }
      }
`;

export const MobileFilterIcon = styled.div``;

export const MobileFilterBtn = styled.button`
      @media screen and (max-width: 1200px) {
            width: fit-content;
            padding: 4px 10px;
            border-radius: 5px;
            border: 1px solid #e4e4e4;
            background-color: white;
            color: #50af31;
      }
`;
