import React from 'react';
import Breadcrumb from '../../components/common/breadcrumb';

const PageNotFound = () => {
      return (
            <>
                  <Breadcrumb title="Page Not Found" />
            </>
      );
};

export default PageNotFound;
