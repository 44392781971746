import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SlideButton = styled(Link)`
      display: block;
      text-align: left;
      text-decoration: none;
      color: var(--black-color);
      background-color: var(--white-color);
      width: 190px;
      font-size: 14px;
      padding: 15px 35px;
      letter-spacing: 0.1rem;
      position: relative;
      text-transform: uppercase;
      text-align: center;

      &::after {
            content: '';
            border: 1px solid var(--primary-color);
            position: absolute;
            width: 190px;
            height: 100%;
            padding: 20px;
            transition: 0.3s;
            top: 7px;
            left: 7px;
      }

      &:hover {
            padding: 15px 35px;

            &:after {
                  transform: translate(-7px, -7px);
            }
      }

      @media screen and (max-width: 600px) {
            padding: 10px 30px;
            width: 180px;

            &::after {
                  width: 180px;
            }

            &:hover {
                  padding: 10px 30px;
            }
      }
`;

export const SlideContent = styled.div`
      position: absolute;
      right: 20px;
      display: flex;
      justify-content: start;
      flex-direction: column;
      bottom: 20px;
      color: #333;
      width: 620px;
      z-index: 1;
      background-color: #ffffff99;
      padding: 10px 20px;
      border-radius: 5px;box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

      h2 {
            font-size: 3rem;
            text-align: left;
            margin-bottom: 10px;
      }

      p {
            text-align: left;
            font-size: 18px;
            /* margin-bottom: 40px; */
            line-height: 30px;
            font-family: 'Roboto', sans-serif;
      }

      a {
            text-decoration: none !important;
            color: #1b1b1b !important;
            display: inline-block;

            &:hover {
                  color: #008080 !important;
            }
      }

      @media screen and (max-width: 1224px) {
            left: 80px;
            top: 4rem;
            width: calc(100% - 120px);

            h2 {
                  font-size: 24px;
            }
      }

      @media screen and (max-width: 600px) {
            left: 20px;
            top: 4rem;
            width: calc(100% - 40px);

            h2 {
                  font-size: 20px;
                  margin-bottom: 10px;
                  line-height: 28px;
            }

            p {
                  font-size: 14px;
                  line-height: 18px;
                  margin-bottom: 20px;
            }
      }
`;
