import React from 'react';

const ShoppingCart = () => {
      return (
            <>
                  <span className="material-symbols-outlined">
                        shopping_cart
                  </span>
            </>
      );
};

export default ShoppingCart;
