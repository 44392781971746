import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';

export default function ShopSlider() {
      return (
            <>
                  <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        loo={true}
                        autoplay={{
                              delay: 3000,
                              disableOnInteraction: false
                        }}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                  >
                        <SwiperSlide>
                              <div>
                                    <img
                                          src="/images/sliders/63611a552a77b 1.png"
                                          alt="Slider"
                                    />
                              </div>
                        </SwiperSlide>
                        <SwiperSlide>
                              <div>
                                    <img
                                          src="/images/sliders/Collage-banner.png"
                                          alt="Slider Banner"
                                    />
                              </div>
                        </SwiperSlide>
                  </Swiper>
            </>
      );
}
