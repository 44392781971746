import styled from 'styled-components';
import OwlCarousel from 'react-owl-carousel';

export const EnquiryBox = styled.div`
      background-color: #f2f2f2;
      padding: 50px 30px;
      margin-top: 3rem;

      .form-label {
            font-size: 17px;
            font-weight: 600;
      }

      .form-control,
      .form-select {
            border-radius: 0;
      }
`;

export const EnquiryFooter = styled.div`
      margin-top: 3rem;
      padding: 2rem 0;
      background-color: #f2f2f2;
`;

export const EnquirySlider = styled(OwlCarousel)`
      a {
            color: #575757 !important;
            display: block;
            transition: all 0.3s ease-in;
            text-decoration: none !important;

            &:hover {
                  color: ${(props) => props.theme.primaryColor} !important;
            }
      }
      .material-symbols-outlined {
            font-size: 46px !important;
      }

      div:first-child {
            text-align: center;
      }

      div:last-child {
            font-size: 24px;
      }
`;
