import { Form } from 'react-bootstrap';
import styled from 'styled-components';

export const CheckoutSummary = styled.div`
      border-radius: 5px;
      background: #fafafa;
      border: 1px solid #e4e4e4;
      padding: 2rem 1rem;
      position: -webkit-sticky;
      position: sticky;
      top: 10rem;
`;

export const CheckoutTitle = styled.div`
      font-size: 24px;
      text-align: center;
      font-weight: 600;
      margin-bottom: 20px;
`;

export const CheckoutContent = styled.div``;

export const CheckoutItem = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e4e4e4;
      margin-bottom: 15px;
      padding-bottom: 15px;

      &:last-child {
            border-bottom: 0;
      }
`;

export const CheckoutInfo = styled.div`
      display: flex;
      align-items: center;
      gap: 10px;
`;

export const CheckoutItemImage = styled.div`
      width: 80px;
      height: 80px;
      border-radius: 5px;
      border: 1px solid #e4e4e4;

      img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: contain;
      }
`;

export const CheckoutItemTitle = styled.div`
      font-size: ${(props) => props.size || '16px'};
      font-weight: 600;
      width: ${(props) => props.width};
`;

export const CheckoutButton = styled.button`
      width: 100%;
      color: ${(props) => props.theme.white};
      font-size: 20px;
      text-align: center;
      padding: 10px 0;
      border-radius: 5px;
      background-color: ${(props) => props.theme.primaryColor};

      &:hover {
            background-color: ${(props) => props.theme.secondaryColor};
      }
`;

export const CheckoutForm = styled(Form)`
      .form-label {
            font-size: 16px;
            font-weight: 600;
      }
`;
