import React, { useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { CommonContainer } from '../Login/style';
import toast from 'react-hot-toast';
import FormInput from '../../components/common/form/FormInput';
import axios from 'axios';
import $ from 'jquery';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import FormButton from '../../components/common/form/FormButton';
import { RegisterContent } from './style';
import { Navigate } from 'react-router-dom';

const Register = () => {
      const [loading, setLoading] = useState(false);
      const [firstName, setFirstName] = useState('');
      const [lastName, setLastName] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [email, setEmail] = useState('');
      const [password, setPassword] = useState('');
      const [confirmPassword, setConfirmPassword] = useState('');

      const [redirect, setRedirect] = useState(false);

      const handleFormSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);
            const data = {
                  first_name: firstName,
                  last_name: lastName,
                  phone_number: phoneNumber,
                  email: email,
                  password: password,
                  password_confirmation: confirmPassword
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/customer/register`,
                        data
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                              setRedirect(true);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.first_name) {
                                    toast.error(
                                          response.data.message.first_name
                                    );
                              }
                              if (response.data.message.last_name) {
                                    toast.error(
                                          response.data.message.last_name
                                    );
                              }
                              if (response.data.message.password) {
                                    toast.error(response.data.message.password);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      if (redirect) {
            return <Navigate to="/login" />;
      }

      const login = localStorage.getItem('isLoggedIn');
      if (login) {
            return <Navigate to="/dashboard" />;
      }

      return (
            <>
                  <Breadcrumb title="Register" />
                  <CommonContainer>
                        <Container>
                              <Row className="justify-content-center">
                                    <Col md={6} className="mb-5">
                                          <RegisterContent>
                                                <h6 className="mb-4">
                                                      If you already have an
                                                      account with us. Please
                                                      login at the login page.
                                                </h6>
                                                <h5 className="mb-3">
                                                      Your Details
                                                </h5>
                                                <form
                                                      onSubmit={
                                                            handleFormSubmit
                                                      }
                                                >
                                                      <div className="mb-4">
                                                            <div className="row g-4">
                                                                  <FormInput
                                                                        title="First Name"
                                                                        handleChange={
                                                                              setFirstName
                                                                        }
                                                                  />
                                                                  <FormInput
                                                                        handleChange={
                                                                              setLastName
                                                                        }
                                                                        title="Last Name"
                                                                  />
                                                                  <FormInput
                                                                        classes={
                                                                              6
                                                                        }
                                                                        title="Phone Number"
                                                                        handleChange={
                                                                              setPhoneNumber
                                                                        }
                                                                  />
                                                                  <FormInput
                                                                        classes={
                                                                              6
                                                                        }
                                                                        title="E-mail Address"
                                                                        handleChange={
                                                                              setEmail
                                                                        }
                                                                  />
                                                                  <FormInput
                                                                        classes={
                                                                              6
                                                                        }
                                                                        type="password"
                                                                        title="Password"
                                                                        handleChange={
                                                                              setPassword
                                                                        }
                                                                  />
                                                                  <FormInput
                                                                        type="password"
                                                                        classes={
                                                                              6
                                                                        }
                                                                        handleChange={
                                                                              setConfirmPassword
                                                                        }
                                                                        title="Confirm Password"
                                                                  />
                                                            </div>
                                                      </div>
                                                      <FormButton
                                                            loading={loading}
                                                            title="Sign Up"
                                                      />
                                                </form>
                                          </RegisterContent>
                                    </Col>
                              </Row>
                        </Container>
                  </CommonContainer>
            </>
      );
};

export default Register;
