import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FeedbackModal } from '../../../styles/frontend/home';
import FormButton from '../form/FormButton';
import FormInput from '../form/FormInput';
import axios from 'axios';
import $ from 'jquery';
import { toast } from 'react-hot-toast';
import { ContactButton } from './style';

const Contact = () => {
      const [show, setShow] = useState(false);
      const [loading, setLoading] = useState(false);

      const [firstName, setFirstName] = useState('');
      const [lastName, setLastName] = useState('');
      const [email, setEmail] = useState('');
      const [phone, setPhone] = useState('');
      const [address, setAddress] = useState('');
      const [message, setMessage] = useState('');

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  name: firstName + ' ' + lastName,
                  email: email,
                  phone: phone,
                  address: address,
                  message: message
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/customer_enquiry`,
                        data,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              $('form').trigger('reset');
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.phone) {
                                    toast.error(response.data.message.phone);
                              }
                              if (response.data.message.address) {
                                    toast.error(response.data.message.address);
                              }
                              if (response.data.message.message) {
                                    toast.error(response.data.message.message);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoading(false);
      };

      return (
            <>
                  <ContactButton>
                        <Button
                              variant="btn"
                              onClick={handleShow}
                              className="d-flex justify-content-between align-items-center rotated__text"
                        >
                              <div className="text-container">Enquiry</div>
                              <i className="bx bx-chevrons-right ps-4"></i>
                        </Button>
                  </ContactButton>

                  <FeedbackModal
                        show={show}
                        backdrop="static"
                        size="lg"
                        centered
                        keyboard={false}
                        onHide={handleClose}
                  >
                        <Modal.Header
                              className="border-bottom-0"
                              style={{
                                    paddingTop: '10px',
                                    paddingBottom: '10px'
                              }}
                              closeButton
                        >
                              {/* <Modal.Title>Your Feedback</Modal.Title> */}
                        </Modal.Header>
                        <Modal.Body>
                              <Form onSubmit={handleSubmit}>
                                    <Row className="g-3">
                                          <FormInput
                                                title="First Name"
                                                required={true}
                                                handleChange={setFirstName}
                                          />
                                          <FormInput
                                                title="Last Name"
                                                required={true}
                                                handleChange={setLastName}
                                          />
                                          <FormInput
                                                title="Email Address"
                                                required={true}
                                                type="email"
                                                handleChange={setEmail}
                                          />
                                          <FormInput
                                                title="Phone No."
                                                required={true}
                                                handleChange={setPhone}
                                          />
                                          <FormInput
                                                title="Address"
                                                required={true}
                                                handleChange={setAddress}
                                                classes={12}
                                          />
                                          <Col lg={12}>
                                                <Form.Label>
                                                      Your Message
                                                </Form.Label>
                                                <Form.Control
                                                      as="textarea"
                                                      rows={4}
                                                      onChange={(event) =>
                                                            setMessage(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </Col>
                                          <FormButton loading={loading} />
                                    </Row>
                              </Form>
                        </Modal.Body>
                  </FeedbackModal>
            </>
      );
};
export default Contact;
