import React from 'react';
import {
      SocialMedia,
      SocialMediaContainer,
      SocialMediaLink
} from '../../../styles/frontend/blogs';
import { Container } from 'react-bootstrap';

const SocialMediaList = () => {
      return (
            <>
                  <SocialMediaContainer>
                        <Container>
                              <SocialMedia>
                                    <ul>
                                          <li>
                                                <h3>Share on</h3>
                                          </li>
                                          <li>
                                                <SocialMediaLink
                                                      primaryColor="#2f4574"
                                                      secondaryColor="#3e5b98"
                                                >
                                                      <div>
                                                            <i className="bx bxl-facebook"></i>
                                                      </div>
                                                      <div className="fb__link">
                                                            <span>
                                                                  Facebook
                                                            </span>
                                                      </div>
                                                </SocialMediaLink>
                                          </li>

                                          <li>
                                                <SocialMediaLink
                                                      primaryColor="#2791d1"
                                                      secondaryColor="#4da7de"
                                                >
                                                      <div>
                                                            <i className="bx bxl-twitter"></i>
                                                      </div>
                                                      <div className="tw__link">
                                                            <span>Twitter</span>
                                                      </div>
                                                </SocialMediaLink>
                                          </li>
                                          <li>
                                                <SocialMediaLink
                                                      primaryColor="#28588f"
                                                      secondaryColor="#3371b7"
                                                >
                                                      <div className="">
                                                            <i className="bx bxl-linkedin"></i>
                                                      </div>
                                                      <div>
                                                            <span>
                                                                  Linkedin
                                                            </span>
                                                      </div>
                                                </SocialMediaLink>
                                          </li>
                                          <li>
                                                <SocialMediaLink
                                                      primaryColor="#c23321"
                                                      secondaryColor="#dd4b39"
                                                >
                                                      <div>
                                                            <i className="bx bxl-gmail"></i>
                                                      </div>
                                                      <div>
                                                            <span>Gmail</span>
                                                      </div>
                                                </SocialMediaLink>
                                          </li>
                                    </ul>
                              </SocialMedia>
                        </Container>
                  </SocialMediaContainer>
            </>
      );
};

export default SocialMediaList;
