import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { CommonContainer } from '../../auth/Login/style';
import {
      CartDescription,
      CartImage,
      CartInfo,
      CartRemove,
      CartTable,
      CartTitle,
      CheckoutLink
} from '../carts/style';
import WishlistContext from '../../stores/WishlistContext';
import CartContext from '../../stores/CartContext';
import { Navigate } from 'react-router-dom';

const Wishlist = () => {
      const wishlistCtx = useContext(WishlistContext);
      const cartCtx = useContext(CartContext);

      const [navigate, setNavigate] = useState(false);

      const checkTokenExpiration = () => {
            const tokenExpiration = localStorage.getItem('tokenExpiration');
            if (!tokenExpiration || new Date(tokenExpiration) < new Date()) {
                  localStorage.removeItem('token');
                  localStorage.removeItem('isLoggedIn');
                  localStorage.removeItem('tokenExpiration');

                  setNavigate(true);
            }
      };

      useEffect(() => {
            checkTokenExpiration();
            const intervalId = setInterval(checkTokenExpiration, 10000);
            return () => clearInterval(intervalId);
      }, []);

      if (navigate) {
            return <Navigate to="/login" />;
      }

      return (
            <>
                  <Breadcrumb title="Wishlist" />
                  <section className="mb-5">
                        <CommonContainer>
                              <CartTable bordered>
                                    <thead>
                                          <tr>
                                                <th class="table-product">
                                                      Product
                                                </th>
                                                <th class="table-price">
                                                      Price
                                                </th>
                                                <th class="table-delete"></th>
                                          </tr>
                                    </thead>
                                    <tbody>
                                          {wishlistCtx.wishlists?.length > 0 ? (
                                                wishlistCtx.wishlists?.map(
                                                      (item, index) => (
                                                            <tr key={index}>
                                                                  <td>
                                                                        <CartInfo>
                                                                              <CartRemove
                                                                                    onClick={() =>
                                                                                          wishlistCtx.removeWishlistSubmit(
                                                                                                item.slug
                                                                                          )
                                                                                    }
                                                                              >
                                                                                    <svg
                                                                                          fill="currentColor"
                                                                                          xmlns="http://www.w3.org/2000/svg"
                                                                                          viewBox="0 0 24 24"
                                                                                          width="16px"
                                                                                          height="16px"
                                                                                    >
                                                                                          <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path>
                                                                                    </svg>
                                                                              </CartRemove>
                                                                              <CartDescription>
                                                                                    <CartImage>
                                                                                          <img
                                                                                                className="table-p-img"
                                                                                                src={
                                                                                                      item.image
                                                                                                }
                                                                                          />
                                                                                    </CartImage>
                                                                                    <CartTitle>
                                                                                          {
                                                                                                item.product_name
                                                                                          }
                                                                                    </CartTitle>
                                                                              </CartDescription>
                                                                        </CartInfo>
                                                                  </td>

                                                                  <td>
                                                                        Rs{' '}
                                                                        {
                                                                              item.price
                                                                        }
                                                                  </td>
                                                                  <td class="table-delete">
                                                                        <button
                                                                              onClick={() =>
                                                                                    cartCtx.addCartSubmit(
                                                                                          item.product_slug
                                                                                    )
                                                                              }
                                                                              className='rounded py-1 px-2'
                                                                        >
                                                                              <i class="bx bx-cart fs-2"></i>
                                                                        </button>
                                                                  </td>
                                                            </tr>
                                                      )
                                                )
                                          ) : (
                                                <tr>
                                                      <td
                                                            className="text-center"
                                                            colSpan={4}
                                                      >
                                                            No product found
                                                      </td>
                                                </tr>
                                          )}
                                    </tbody>
                              </CartTable>
                              <div className="mt-3 text-end">
                                    <CheckoutLink
                                          to="/products"
                                          color="#111111"
                                    >
                                          Continue shopping
                                    </CheckoutLink>
                              </div>
                        </CommonContainer>
                  </section>
            </>
      );
};

export default Wishlist;
