import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div`
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border: 0.15em solid currentColor;
      border-right-color: transparent;
      border-radius: 50%;
      margin-left: 0.85rem;
      animation: ${rotate} 2s linear infinite;
`;
