import { Accordion } from 'react-bootstrap';
import styled from 'styled-components';

export const FaqTabContainer = styled.div`
      border: none;
`;

export const FaqTabButton = styled.button`
      background-color: transparent;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
      transition: background-color 0.3s;
      font-size: 18px;

      :hover {
            border-bottom: 2px solid ${(props) => props.theme.primaryColor};
      }

      &.active {
            border: 0;
            border-bottom: 2px solid ${(props) => props.theme.primaryColor};

            &::after {
                  content: none;
            }
      }

      @media screen and (max-width: 600px) {
            min-width: max-content;
      }
`;

export const FaqTabHeader = styled.div`
      @media screen and (max-width: 600px) {
            overflow-x: scroll;
            display: flex;

            &::-webkit-scrollbar {
                  width: 0;
            }
      }
`;

export const FaqTabContent = styled.div`
      margin-top: 20px;
      background-color: #f5f5f5;
      padding: 20px;
`;

export const FaqTabPanel = styled.div`
      display: ${(props) => (props.display ? props.display : 'none')};
      font-size: 16px;

      &.active {
            display: block;
      }

      ul {
            margin-top: 10px;
            li {
                  list-style: disc;
            }
      }

      @media screen and (max-width: 600px) {
            h2,
            h3,
            h1 {
                  font-size: 24px;
                  margin-bottom: 0px;
            }
      }
`;

export const FaqAccordion = styled(Accordion)`
      background-color: #f5f5f5;
      font-size: 16px;

      .accordion-button {
            padding: 10px 0px;
            background-color: #f5f5f5;
            font-size: 20px;
            font-weight: 700;
            color: #333;

            &:focus {
                  box-shadow: none;
            }
      }

      .accordion-body {
            background-color: #f5f5f5;
            padding: 0;
            padding-bottom: 30px;
            font-size: 17px;
      }

      .accordion-button:not(.collapsed) {
            box-shadow: none !important;
      }

      .accordion-item {
            border-bottom: 1px solid #acacac;
      }
`;
