import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const Header = styled.div`
      position: relative;
`;

export const HeaderLogin = styled.div`
      display: flex;
      align-items: center;
      margin-right: -10px;

      li {
            &:last-child {
                  height: 24px;

                  a {
                        height: 24px;
                        width: 24px;
                        display: inline-block;
                  }
            }

            &:hover {
                  a {
                        color: #f2f2f299;
                  }
                  a::after {
                        content: none !important;
                  }
            }
      }
`;

export const HeaderImageLogo = styled.div`
      img {
            width: 200px;
            height: auto;
            padding: 0px 15px;
            padding-left: 0;
      }

      @media screen and (max-width: 600px) {
            img {
                  width: 140px;
                  padding: 0;
            }
      }
`;

export const WhiteImageLogo = styled.img`
      display: inline-block;

      @media screen and (max-width: 1224px) {
            display: none;
      }
`;

export const ImageLogo = styled.img`
      display: none;

      @media screen and (max-width: 1224px) {
            display: inline-block;
      }
`;

export const HomeHeaderSwiper = styled(Swiper)`
      @media screen and (max-width: 600px) {
            height: 280px;

            .swiper-pagination {
                  display: none;
            }
            .swiper__image {
                  height: 100%;
            }
            img {
                  height: 100%;
            }
      }
`;

export const HomeHeaderContainer = styled.section`
      /* position: absolute; */
      top: 0;
      z-index: 100;
      width: 100%;
      /* padding: 35px 30px; */

      @media screen and (max-width: 1224px) {
            padding: 12px 20px;
      }

      @media screen and (max-width: 1224px) {
            position: static;
      }
`;

export const HeaderContent = styled(Container)`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: #fff;
      padding: 10px;
      border-radius: 5px;
      padding-right: 20px;
      box-shadow: ${(props) =>
            !props.sticky && 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'};

      @media screen and (max-width: 600px) {
            padding: 0;
      }
`;

export const ContactButton = styled.div`
      position: absolute;
      top: 315px;
      left: -60px;
      z-index: 10000;

      button {
            background-color: ${(props) => props.theme.white};
            padding: 5px 15px;
            border-radius: 0;
            text-transform: uppercase;
            font-size: 14px;

            &:hover {
                  border: 1px solid ${(props) => props.theme.white};
                  color: ${(props) => props.theme.primaryColor} !important;
                  background-color: #f5f5f5;
            }

            &:active {
                  border: 1px solid ${(props) => props.theme.white};
                  color: ${(props) => props.theme.primaryColor} !important;
                  background-color: #f5f5f5 !important;
            }
            i {
                  color: var(--primary-color);
                  font-size: 35px;
            }
      }

      @media screen and (max-width: 600px) {
            display: none;
      }
`;

export const MobileMenuBox = styled.div`
      display: none;

      @media screen and (max-width: 1224px) {
            display: block;
      }
`;
