import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
      BlogItem,
      BlogItemDate,
      BlogItemDetail,
      BlogItemTag,
      BlogItemTitle
} from '../../../styles/frontend/blogs';

const BlogItemBox = ({ link, title, tags, date, image, classes }) => {
      return (
            <>
                  <Col lg={4} className={classes}>
                        <BlogItem>
                              <Link to={link} className='blog__image'>
                                    <img src={image} alt={title} />
                              </Link>
                              <BlogItemDetail>
                                    <BlogItemTag>
                                          <span>{tags}</span>
                                    </BlogItemTag>
                                    <BlogItemTitle>
                                          <Link to={link}>
                                                <span>{title}</span>
                                          </Link>
                                    </BlogItemTitle>
                                    <BlogItemDate>
                                          <span>{date}</span>
                                    </BlogItemDate>
                              </BlogItemDetail>
                        </BlogItem>
                  </Col>
            </>
      );
};

export default BlogItemBox;
