import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';

export const BrandBox = styled.div`
      padding-top: 3rem;
      padding-bottom: 3rem;

      @media screen and (max-width: 600px) {
            margin-left: 5px;
            margin-right: 5px;
      }
`;

export const BrandContent = styled.div`
      display: flex;
      justify-content: space-between;
      background-color: ${(props) => props.theme.white};
      border: 1px solid #f2f2f2;
      position: relative;
      z-index: 9999;
`;

export const BrandSliderItem = styled(SwiperSlide)`
      img {
            height: 120px;
            border-right: 1px;
            border-right-style: solid;
            border-right-color: #ddd;
            border-bottom: 1px;
            border-bottom-style: solid;
            border-bottom-color: #ddd;
            padding: 16px;
            object-fit: contain;
      }

      .row {
            .col-lg-3:nth-child(4),
            .col-lg-3:nth-child(8) {
                  img {
                        border-right: 0;
                  }
            }

            .col-lg-3:nth-child(5),
            .col-lg-3:nth-child(6),
            .col-lg-3:nth-child(7),
            .col-lg-3:nth-child(8) {
                  img {
                        border-bottom: 0;
                  }
            }

            @media screen and (max-width: 600px) {
                  .col-lg-3:nth-child(2),
                  .col-lg-3:nth-child(4),
                  .col-lg-3:nth-child(6) {
                        img {
                              border-right: 0;
                        }
                  }

                  .col-lg-3:nth-child(5),
                  .col-lg-3:nth-child(6) {
                        img {
                              border-bottom: 1px solid rgb(221, 221, 221);
                        }
                  }
            }
      }
`;

export const BrandDetail = styled.div`
      h2 {
            letter-spacing: 1.5px;
            text-transform: uppercase;
            font-weight: 300 !important;
            font-family: 'HelveticaNowDisplay-Light', sans-serif;
            padding-bottom: 20px;
            margin-bottom: 20px;
            font-size: 36px;
            position: relative;

            &::before {
                  content: '';
                  display: block;
                  height: 0.5px;
                  width: 100%;
                  background: #004650;
                  position: absolute;
                  left: -88%;
                  bottom: 0;
            }
      }

      p {
            font-family: 'HelveticaNowDisplay-Light', sans-serif;
            line-height: 1.6;
            font-size: 16px;
            letter-spacing: 0.9px;
      }

      @media screen and (max-width: 600px) {
            h2 {
                  font-size: 28px;
                  line-height: 1.1;

                  &::before {
                        left: -60%;
                  }
            }
      }
`;

export const CatalogueBox = styled.div`
      background-color: ${(props) => props.theme.black};
      padding: 20px 40px;
      height: 250px;
      margin-top: 3rem;
      margin-bottom: 3rem;

      .row {
            align-items: center;
            height: 100%;
            width: 100%;
            margin: auto;
      }

      @media screen and (max-width: 1200px) {
            .row {
                  flex-direction: column;
                  width: 100%;

                  .col {
                        width: 100%;
                  }
            }
      }

      @media screen and (max-width: 600px) {
            height: auto;
            padding: 20px 10px;

            .row {
                  flex-wrap: nowrap;

                  a {
                        display: inline-block;
                  }
            }
      }
`;

export const CatalogueDownload = styled.div`
      color: ${(props) => props.theme.white};

      span {
            font-size: 12px;
      }

      h2 {
            font-size: 40px;
      }

      @media screen and (max-width: 600px) {
            h2 {
                  font-size: 32px;
            }
      }
`;

export const CatalogueDetail = styled.div`
      font-size: 17px;
      color: ${(props) => props.theme.white};
      p {
            margin-bottom: 10px;
      }

      div {
            margin-top: 40px;
      }

      a {
            text-transform: uppercase;
            padding: 14px 30px;
            color: ${(props) => props.theme.black};
            background-color: ${(props) => props.theme.white};

            &:hover {
                  background-color: #f5f5f5;
            }
      }

      @media screen and (max-width: 600px) {
            div {
                  margin-top: 20px;
            }
            a {
                  padding: 8px 16px;
                  font-size: 14px;
            }
      }
`;

export const ProductSlider = styled(Swiper)`
      .swiper-button-prev {
            position: absolute;
            right: 0;
            top: 0;
            left: auto;
      }

      .swiper-nav-btns {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            align-items: center;
            margin-top: 10px;

            a {
                  padding: 8px 20px;
                  text-align: center;
                  display: block;
                  color: ${(props) => props.theme.white};
                  background-color: ${(props) => props.theme.primaryColor};

                  &:hover {
                        background-color: ${(props) =>
                              props.theme.secondaryColor};
                  }
            }

            button {
                  background-color: transparent;
                  height: 24px;

                  i {
                        font-size: 24px;
                  }
            }

            button:first-child {
                  margin-right: 16px;
                  padding-right: 16px;
                  border-right: 2px solid ${(props) => props.theme.black};
            }

            div:last-child {
                  display: flex;
                  align-items: center;
            }
      }
`;

export const ProductSliderItem = styled(SwiperSlide)`
      img {
            height: 400px;
            object-fit: cover;
            width: 100%;
      }

      div {
            font-size: 20px;
            padding: 10px;
            color: ${(props) => props.theme.black};
      }

      @media screen and (max-width: 600px) {
            img {
                  height: 320px;
            }
      }
`;

export const CategorySliderItem = styled(SwiperSlide)`
      img {
            height: 120px;
            padding: 16px;
            padding-bottom: 0;
            object-fit: contain;
      }

      .row {
            background-color: #f2f2f2;
            text-align: center;

            a {
                  color: ${(props) => props.theme.black};
                  font-size: 16px;
                  padding-bottom: 10px;
                  display: block;
            }

            .col-lg-4 {
                  border-right: 1px;
                  border-right-style: solid;
                  border-right-color: #ddd;
                  border-bottom: 1px;
                  border-bottom-style: solid;
                  border-bottom-color: #ddd;
            }

            .col-lg-4:hover {
                  background-color: ${(props) => props.theme.white};
            }

            .col-lg-4:nth-child(3),
            .col-lg-4:nth-child(6),
            .col-lg-4:nth-child(9) {
                  border-right: 0 !important;
            }

            .col-lg-4:nth-child(7),
            .col-lg-4:nth-child(8),
            .col-lg-4:nth-child(9) {
                  border-bottom: 0;
            }
      }
`;

export const TestimonialBox = styled.div`
      background-color: ${(props) => props.theme.white};
      position: relative;
`;

export const TestimonialWrapper = styled.div`
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      margin: 0px auto;
      border-radius: 12px;
      display: flex;

      @media screen and (max-width: 600px) {
            display: block;
            box-shadow: none;
      }
`;

export const TestimonialImage = styled.div`
      width: 300px;
      height: 344px;

      img {
            width: 100%;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
      }

      @media screen and (max-width: 600px) {
            width: 100%;
            img {
                  border-radius: 12px;
                  border-bottom-left-radius: 0;
                  border-bottom-right-radius: 0;
            }
      }
`;

export const TestimonialContent = styled.div`
      padding: 45px 50px 45px 52px;
      background-color: #f5f5f5;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      width: calc(100% - 300px);

      @media screen and (max-width: 600px) {
            width: 100%;
            padding: 12px 15px;
            border-radius: 0;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
      }
`;

export const TestimonialDate = styled.div`
      span {
            font-size: 12px;
            line-height: 40px;
            color: #525252;
      }
`;

export const TestimonialTitle = styled.div`
      span {
            font-size: 22px;
      }
`;

export const TestimonialDescription = styled.div`
      p {
            color: #525252;
            text-align: justify;
      }
`;

export const FeedbackModal = styled(Modal)`
      .modal-content {
            background: #008080;
            border-radius: 5px;

            .btn-close {
                  background-color: white;
                  margin-top: -20px;
                  position: absolute;
                  right: 0;
                  border-radius: 14px;
            }

            .modal-body {
                  margin: 40px;
                  background: white;
                  border-radius: 10px;
                  padding: 40px 30px;
            }

            .form-control {
                  border-radius: 0;
            }
      }
`;
