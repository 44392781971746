import React, { useEffect, useState } from 'react';
import DashboardLayout from '../DashboardLayout';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { DashboardBreadcrumb } from '../Dashboard/style';
import { Container } from 'react-bootstrap';
import DashboardTableList from '../DashboardTableList';

const OrderList = () => {
      const [loading, setLoading] = useState(false);
      const [orders, setOrders] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/customer/orders`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setOrders(response.data.orders);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <DashboardBreadcrumb>
                        <Container>
                              <div className="page-breadcrumb">
                                    <Link to="/">Dashboard</Link> {'>'} My
                                    Orders
                              </div>
                        </Container>
                  </DashboardBreadcrumb>
                  <DashboardLayout>
                        <DashboardTableList
                              title="My Order"
                              loading={loading}
                              orders={orders}
                              loadData={loadData}
                        />
                  </DashboardLayout>
            </>
      );
};
export default OrderList;
