import styled from 'styled-components';

export const DashboardBreadcrumb = styled.div`
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #f2f2f2;
`;

export const DashboardContentGrid = styled.div`
      display: grid;
      margin-bottom: 2rem;
      gap: 1rem;

      @media screen and (min-width: 768px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @media screen and (min-width: 1280px) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
      }
`;

export const DashboardContentTitle = styled.h2`
      margin-bottom: 1.25rem;
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 600;
`;
