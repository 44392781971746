import { Form, Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const SearchModal = styled(Modal)`
      z-index: 9999999;
      .modal-header {
            padding: 0;
            border: 0;
      }
      .modal-content {
            border-radius: 5px;
            background-color: transparent;
            box-shadow: none;
            border: 0;

            .btn-close {
                  background-color: white;
                  top: -20px;
                  position: absolute;
                  right: 10px;
                  border-radius: 14px;
                  z-index: 9;
            }

            .modal-body {
                  margin: 0px;
                  background: transparent;
                  border-radius: 10px;
                  padding: 30px;
                  box-shadow: none;

                  .form-control {
                        color: #767676;

                        &:focus {
                              background-color: transparent;
                              color: #767676;
                        }

                        &::placeholder {
                              color: #767676;
                        }
                  }
            }
      }
`;

export const SearchForm = styled(Form)`
      border: 1px solid rgba(255, 255, 255, 0.1);
      outline: none;
      padding: 5px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      background-color: white;
`;

export const SearchInput = styled.input`
      background-color: transparent;
      width: 100%;
      background: none;
      border: none;
      padding-left: 20px;
      font-size: 20px;
`;

export const SearchBtn = styled.button`
      margin: 0;
      padding: 0;
      background-color: transparent;
      height: 24px;
      width: 24px;
      margin-right: 20px;

      i {
            font-size: 24px;
      }
`;
