import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const ProductContainer = styled(Container)`
      padding-top: 3rem;
      padding-bottom: 3rem;

      @media screen and (max-width: 600px) {
            padding: 2rem 15px;
      }
`;

export const ProductImageContent = styled.div`
      background-color: white;
      height: 100%;
`;

export const ProductContentDetail = styled.div`
      background-color: white;
      padding: 1rem 1.5rem;
      height: 100%;

      @media screen and (max-width: 600px) {
            margin-top: 20px;

            .product__content--title {
                  h1 {
                        font-size: 18px;
                  }
            }
      }
`;

export const SwiperThumbnail = styled(Swiper)`
      margin: 10px;

      .swiper-slide {
            border: 1px solid #f2f2f2;
            padding: 10px;
            background-color: #f2f2f2;
            border-radius: 5px;
            height: 80px !important;

            img {
                  height: 100% !important;
                  object-fit: contain;
            }
      }
`;

export const ProductDescription = styled.div`
      height: 190px;
      overflow-x: scroll;
      padding: 10px 0;
`;

export const ProductItem = styled.div`
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      padding: 8px 20px;
      background-color: #fff;
      height: 100%;

      @media screen and (max-width: 600px) {
            .product__title a h4 {
                  font-size: 14px;
                  letter-spacing: 1px;
            }

            .product__price {
                  font-size: 14px;
                  letter-spacing: 1px;
            }
      }
`;

export const ProductItemImage = styled.div`
      height: 220px;
      text-align: center;

      a {
            display: block;
            height: 100%;

            img {
                  height: 100%;
            }
      }

      @media screen and (max-width: 600px) {
            height: 178px;

            a {
                  height: 100%;

                  img {
                        height: 100%;
                        object-fit: contain;
                  }
            }
      }
`;

export const RelatedProduct = styled(Col)`
      position: sticky;
      height: 450px;
      top: 0px;

      @media screen and (max-width: 600px) {
            display: none;
      }
`;

export const AdditionContainer = styled.div`
      margin: 3rem 0;

      @media screen and (max-width: 600px) {
            margin: 2rem 0;

            .col-lg-9 {
                  padding: 0;
            }

            .product__additional ul li button {
                  font-size: 16px;
            }
      }
`;

export const ProductRecommended = styled.div`
      margin: 3rem 0;

      @media screen and (max-width: 600px) {
            margin: 0;
      }
`;

export const WishlistButton = styled.button`
      i {
            font-size: 24px;
      }
`;
