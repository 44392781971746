import React from 'react';
import { AboutRow } from '../../../../styles/frontend/about';
import { Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ServiceBox } from '../../../../styles/frontend/shop';
const Service = () => {
      return (
            <>
                  <ServiceBox image='url("/images/sliders/jaquar-care.jpg") no-repeat center center / cover'>
                        <Container>
                              <AboutRow
                                    color={true}
                                    className="justify-conten-end g-0 align-items-center mt-0"
                              >
                                    <Col lg={6}>
                                          <h2 className="text-white">
                                                Bathnroom CARE
                                          </h2>
                                          <p className="text-white">
                                                Bathnroom's unmatched customer
                                                service is backed by over 2400
                                                trained technicians and a
                                                10-year warranty on its
                                                products.
                                          </p>
                                          <Link to="/customer-enquiry">
                                                Let's connect{' '}
                                                <i className="bx bx-chevron-right"></i>
                                          </Link>
                                    </Col>
                                    <Col lg={6}></Col>
                              </AboutRow>
                        </Container>
                  </ServiceBox>
            </>
      );
};
export default Service;
