import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Col, Container, Form, Row } from 'react-bootstrap';
import {
      EnquiryBox,
      EnquiryFooter,
      EnquirySlider
} from '../../styles/frontend/dealers';
import { Link } from 'react-router-dom';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { toast } from 'react-hot-toast';

import $ from 'jquery';
import axios from 'axios';
import FormInput from '../../components/common/form/FormInput';
import { DealerIcon, TermsCondition } from '../../components/icons';
import SupportTicketIcon from '../../components/icons/SupportTicketIcon';
import { SwiperSlide } from 'swiper/react';
import FormSelect from '../../components/common/form/FormSelect';
import FormButton from '../../components/common/form/FormButton';
import FormFile from '../../components/common/form/FormFile';
import MetaContext from '../../stores/MetaContext';

const categories = [
      {
            id: 'Tiles',
            title: 'Tiles'
      },
      {
            id: 'Sanitaryware',
            title: 'Sanitaryware'
      },
      {
            id: 'Bath Ware',
            title: 'Bath Ware'
      },
      {
            id: 'Kitchen',
            title: 'Kitchen'
      },
      {
            id: 'Flooring',
            title: 'Flooring'
      },
      {
            id: 'Roofing',
            title: 'Roofing'
      },
      {
            id: 'Cladding',
            title: 'Cladding'
      },
      {
            id: 'Hardware',
            title: 'Hardware'
      },
      {
            id: 'Others',
            title: 'Others'
      }
];

const SupportTicket = () => {
      const [complains, setComplains] = useState([]);
      const [loadingData, setLoadingData] = useState(false);

      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [state, setState] = useState('');
      const [city, setCity] = useState('');
      const [address, setAddress] = useState('');
      const [street, setStreet] = useState('');
      const [landmark, setLandmark] = useState('');
      const [category, setCategory] = useState('');
      const [productName, setProductName] = useState('');
      const [serialNo, setSerialNo] = useState('');
      const [purchaseDate, setPurchaseDate] = useState('');
      const [symptom, setSymptom] = useState('');
      const [warrentyCard, setWarrentyCard] = useState('');
      const [billingCard, setBillingCard] = useState('');
      const [remark, setRemark] = useState('');
      const [loading, setLoading] = useState(false);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('support-ticket');

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/complains`, {
                        headers: {
                              apikey: process.env.REACT_APP_Api_Key
                        }
                  })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setComplains(response.data.complains);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleSubmit = async (event) => {
            event.preventDefault();

            const data = {
                  name: name,
                  email: email,
                  state: state,
                  city: city,
                  address: address,
                  street: street,
                  landmark: landmark,
                  product_name: productName,
                  serial_no: serialNo,
                  purchase_date: purchaseDate,
                  symptom: symptom,
                  warrenty_card: warrentyCard,
                  billing_card: billingCard,
                  type: category,
                  phone_number: phoneNumber,
                  remark: remark
            };
            setLoading(true);
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/complains`,
                        data,
                        {
                              headers: {
                                    'Content-Type': 'multipart/form-data',
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              $('form').trigger('reset');
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.type) {
                                    toast.error(response.data.message.type);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                              if (response.data.message.question) {
                                    toast.error(response.data.message.question);
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      const options = {
            margin: 30,
            responsiveClass: true,
            nav: false,
            dots: false,
            lazyLoad: true,
            autoplay: false,
            navText: ['Prev', 'Next'],
            smartSpeed: 1000,
            responsive: {
                  0: {
                        items: 1
                  },
                  400: {
                        items: 1
                  },
                  600: {
                        items: 2
                  },
                  700: {
                        items: 3
                  },
                  1000: {
                        items: 4
                  }
            }
      };

      return (
            <>
                  <Breadcrumb
                        otherTitle="Contact Us"
                        otherLink="/contact-us"
                        title="Support Ticket"
                  />

                  <Container>
                        <Row className="justify-content-center">
                              <Col lg={12}>
                                    <EnquiryBox>
                                          <Form onSubmit={handleSubmit}>
                                                <Row className="g-3">
                                                      <FormInput
                                                            title="Full Name"
                                                            classes={4}
                                                            required={true}
                                                            handleChange={
                                                                  setName
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Email Address"
                                                            required={true}
                                                            classes={4}
                                                            type="email"
                                                            handleChange={
                                                                  setEmail
                                                            }
                                                      />

                                                      <FormInput
                                                            title="Mobile No."
                                                            required={true}
                                                            classes={4}
                                                            handleChange={
                                                                  setPhoneNumber
                                                            }
                                                      />

                                                      <FormInput
                                                            title="State"
                                                            required={true}
                                                            classes={4}
                                                            handleChange={
                                                                  setState
                                                            }
                                                      />
                                                      <FormInput
                                                            title="City"
                                                            required={true}
                                                            classes={4}
                                                            handleChange={
                                                                  setCity
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Address"
                                                            required={true}
                                                            classes={4}
                                                            handleChange={
                                                                  setAddress
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Street"
                                                            required={true}
                                                            classes={4}
                                                            handleChange={
                                                                  setStreet
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Landmark"
                                                            classes={4}
                                                            handleChange={
                                                                  setLandmark
                                                            }
                                                      />
                                                      <FormSelect
                                                            title="Select Category"
                                                            classes={4}
                                                            loading={
                                                                  loadingData
                                                            }
                                                            options={categories}
                                                            setData={
                                                                  setCategory
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Product Name"
                                                            required={true}
                                                            classes={4}
                                                            handleChange={
                                                                  setProductName
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Serial No."
                                                            classes={4}
                                                            handleChange={
                                                                  setSerialNo
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Purchase Date"
                                                            required={true}
                                                            type="date"
                                                            classes={4}
                                                            handleChange={
                                                                  setPurchaseDate
                                                            }
                                                      />
                                                      <FormSelect
                                                            title="Complain Symptom"
                                                            classes={4}
                                                            loading={
                                                                  loadingData
                                                            }
                                                            options={complains}
                                                            required={true}
                                                            setData={setSymptom}
                                                      />
                                                      <FormFile
                                                            title="Warrenty Card"
                                                            classes={4}
                                                            handleChange={
                                                                  setWarrentyCard
                                                            }
                                                      />
                                                      <FormFile
                                                            title="Billing Card"
                                                            classes={4}
                                                            handleChange={
                                                                  setBillingCard
                                                            }
                                                      />
                                                      <FormInput
                                                            title="Remarks"
                                                            classes={12}
                                                            handleChange={
                                                                  setRemark
                                                            }
                                                      />
                                                      <FormButton
                                                            loading={loading}
                                                      />
                                                </Row>
                                          </Form>
                                    </EnquiryBox>
                              </Col>
                        </Row>
                  </Container>

                  <EnquiryFooter>
                        <Container>
                              <EnquirySlider {...options} className="owl-theme">
                                    <SwiperSlide>
                                          <Link to="/customer-enquiry">
                                                <div>
                                                      <SupportTicketIcon />
                                                </div>
                                                <div>Customer Enquiry</div>
                                          </Link>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                          <Link to="/dealer-enquiry">
                                                <div>
                                                      <DealerIcon />
                                                </div>
                                                <div>Dealer Enquiry</div>
                                          </Link>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                          <Link to="/terms-and-conditions">
                                                <div>
                                                      <TermsCondition />
                                                </div>
                                                <div>Terms & Conditions</div>
                                          </Link>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                          <Link to="/support-ticket">
                                                <div>
                                                      <SupportTicketIcon />
                                                </div>
                                                <div>Support Ticket</div>
                                          </Link>
                                    </SwiperSlide>
                              </EnquirySlider>
                        </Container>
                  </EnquiryFooter>
            </>
      );
};

export default SupportTicket;
