import React from 'react';

const OrderIcon = () => {
      return (
            <>
                  <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        class="flex-shrink-0 h-4 w-4"
                        aria-hidden="true"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                  >
                        <path
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="32"
                              d="M160 144h288M160 256h288M160 368h288"
                        ></path>
                        <circle
                              cx="80"
                              cy="144"
                              r="16"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="32"
                        ></circle>
                        <circle
                              cx="80"
                              cy="256"
                              r="16"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="32"
                        ></circle>
                        <circle
                              cx="80"
                              cy="368"
                              r="16"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="32"
                        ></circle>
                  </svg>
            </>
      );
};

export default OrderIcon;
