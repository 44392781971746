import React from 'react';
import NavBar from './NavBar';
import Contact from './Contact';
import { SliderBanner } from '../../sliders';
import { Header } from 'rsuite';
const HomeHeader = ({ handleShow, handleSearchShow }) => {
      return (
            <>
                  <Header>
                        <NavBar
                              handleShow={handleShow}
                              handleSearchShow={handleSearchShow}
                        />
                        <SliderBanner />
                        <Contact />
                  </Header>
            </>
      );
};
export default HomeHeader;
