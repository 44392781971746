import React from 'react';
import { ShopBannerContainer } from './style';
const ShopBanner = () => {
      return (
            <>
                  <ShopBannerContainer>
                        <div className="row">
                              <div className="col-lg-8">
                                    <img
                                          src="/images/sliders/d-tiles-visualization-preview-banner-website-illustration-design-background-147972524-transformed 1.png"
                                          className="img-fluid"
                                          alt="Slider Banner"
                                    />
                                    <div className="row">
                                          <div className="col-lg-6 col-md-6 col-sm-6">
                                                <img
                                                      src="/images/sliders/banner-roma-floor-wall-tile-marble-2 1.png"
                                                      className="img-fluid mt-4"
                                                      alt="Slider Banner"
                                                />
                                          </div>
                                          <div className="col-lg-6 col-md-6 col-sm-6">
                                                <img
                                                      src="/images/sliders/image 1.png"
                                                      className="img-fluid mt-4"
                                                      alt="Slider Banner"
                                                />
                                          </div>
                                    </div>
                              </div>
                              <div className="col-lg-4 hide">
                                    <img
                                          src="/images/sliders/image 2.png"
                                          className="img-fluid"
                                          alt="Slider Banner"
                                    />
                              </div>
                        </div>
                  </ShopBannerContainer>
            </>
      );
};
export default ShopBanner;
