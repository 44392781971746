import React from 'react';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';

import {
      BrandBox,
      BrandContent,
      BrandDetail
} from '../../../../styles/frontend/home';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BrandItem, BrandMoreButton } from '../../../../styles/frontend/shop';
import { useState } from 'react';

const Brand = ({ loading, brands }) => {
      const [more, setMore] = useState(24);

      const handleMore = () => {
            if (more > 24) {
                  setMore(24);
            } else {
                  setMore(brands.length);
            }
      };

      return (
            <>
                  <BrandBox
                        style={{ backgroundColor: '#f5f5f5' }}
                        className="mt-5 mx-0"
                  >
                        <Container>
                              <Row className="g-0 align-items-center">
                                    <Col lg={12}>
                                          <Row className="justify-content-start g-0">
                                                <Col
                                                      lg={10}
                                                      className="pt-5 pb-3"
                                                >
                                                      <BrandDetail>
                                                            <h2>
                                                                  Brands We
                                                                  Offer
                                                            </h2>
                                                      </BrandDetail>
                                                </Col>
                                          </Row>
                                    </Col>
                                    <Col lg={12}>
                                          <BrandContent>
                                                {!loading ? (
                                                      brands.length > 0 ? (
                                                            <BrandItem>
                                                                  <Row>
                                                                        {brands
                                                                              .slice(
                                                                                    0,
                                                                                    more
                                                                              )
                                                                              .map(
                                                                                    (
                                                                                          brand,
                                                                                          index
                                                                                    ) => (
                                                                                          <Col
                                                                                                lg={
                                                                                                      2
                                                                                                }
                                                                                                key={
                                                                                                      index
                                                                                                }
                                                                                                md={
                                                                                                      3
                                                                                                }
                                                                                                className="col-6"
                                                                                          >
                                                                                                <Link
                                                                                                      to={`/products/brands/${brand.slug}`}
                                                                                                >
                                                                                                      <img
                                                                                                            src={
                                                                                                                  brand.image
                                                                                                            }
                                                                                                            alt={
                                                                                                                  brand.title
                                                                                                            }
                                                                                                      />
                                                                                                      <div>
                                                                                                            {
                                                                                                                  brand.title
                                                                                                            }
                                                                                                      </div>
                                                                                                </Link>
                                                                                          </Col>
                                                                                    )
                                                                              )}
                                                                  </Row>
                                                            </BrandItem>
                                                      ) : (
                                                            <>No Brand Found</>
                                                      )
                                                ) : (
                                                      <>Loading</>
                                                )}
                                          </BrandContent>
                                    </Col>
                              </Row>

                              <BrandMoreButton>
                                    <button
                                          onClick={(event) => handleMore(event)}
                                    >
                                          {!(more >= brands.length) ? (
                                                <>Show More</>
                                          ) : (
                                                <>Show Less</>
                                          )}
                                    </button>
                              </BrandMoreButton>
                        </Container>
                  </BrandBox>
            </>
      );
};
export default Brand;
