import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BgGray = styled.div`
      background-color: #f2f2f2;
`;

export const DashboardContainer = styled.div`
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      max-width: 1536px;

      @media screen and (min-width: 640px) {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
      }
`;

export const DashboardContent = styled.div`
      display: flex;
      padding-bottom: 2.5rem;
      flex-direction: column;
      width: 100%;

      @media screen and (min-width: 640px) {
            padding-bottom: 3rem;
            flex-direction: row;
            align-items: baseline;
      }
`;

export const DashboardContainerGrid = styled.div`
      margin-right: 1.75rem;
      flex-shrink: 0;
      width: 100%;

      @media screen and (min-width: 640px) {
            margin-right: 2.5rem;
            width: 20rem;
      }

      @media screen and (min-width: 768px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @media screen and (min-width: 1280px) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
      }
`;

export const DashboardNavigation = styled.div`
      position: sticky;
      top: 8rem;
      padding: 1rem;
      background-color: #ffffff;
      border: 1px solid #e0dede;

      .active {
            background-color: #f2f2f2;

            a,
            svg {
                  color: ${(props) => props.theme.primaryColor} !important;
            }
      }

      @media screen and (min-width: 640px) {
            padding: 1rem;
      }

      @media screen and (min-width: 1024px) {
            padding: 1rem;
      }
`;

export const DashboardNavigationItem = styled.span`
      display: flex;
      padding: 0.5rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      align-items: center;
      width: 100%;
      font-size: 16px;

      &:hover {
            background-color: #f2f2f2;
            color: ${(props) => props.theme.primaryColor} !important;

            & > a,
            & > button {
                  color: ${(props) => props.theme.primaryColor} !important;
            }
      }
`;

export const DashboardNavigationLink = styled(Link)`
      display: inline-flex;
      margin-left: 0.5rem;
      font-size: 16px;
      line-height: 1.25rem;
      font-weight: 500;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      text-decoration: none;
      color: #343538;
`;

export const DashboardContentContainer = styled.div`
      overflow: hidden;
      padding: 1rem;
      margin-top: 1rem;
      background-color: #ffffff;
      width: 100%;
      border: 1px solid #e0dede;

      @media screen and (min-width: 640px) {
            padding: 1.25rem;
      }

      @media screen and (min-width: 1024px) {
            padding: 2rem;
            margin-top: 0;
      }
`;
