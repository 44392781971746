import React, { useEffect, useState } from 'react';
import MenuItem from './MenuItem';
import './style.css';
import {
      DashboardIcon,
      LogoutIcon,
      OrderIcon,
      PasswordIcon,
      ProfileIcon
} from '../../components/icons';
import { Container } from 'react-bootstrap';
import {
      BgGray,
      DashboardContainerGrid,
      DashboardContent,
      DashboardContentContainer,
      DashboardNavigation
} from './style';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';

const DashboardLayout = ({ children }) => {
      const [navigate, setNavigate] = useState(false);

      const checkTokenExpiration = () => {
            const tokenExpiration = localStorage.getItem('tokenExpiration');
            if (!tokenExpiration || new Date(tokenExpiration) < new Date()) {
                  localStorage.removeItem('token');
                  localStorage.removeItem('isLoggedIn');
                  localStorage.removeItem('tokenExpiration');

                  setNavigate(true);
            }
      };

      useEffect(() => {
            checkTokenExpiration();
            const intervalId = setInterval(checkTokenExpiration, 10000);
            return () => clearInterval(intervalId);
      }, []);

      const [loading, setLoading] = useState(false);
      const [redirect, setRedirect] = useState(false);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/customer/logout`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setTimeout(() => {
                                    localStorage.removeItem('token');
                                    localStorage.removeItem('isLoggedIn');
                                    localStorage.removeItem('tokenExpiration');
                                    toast.success(response.data.message);
                                    setRedirect(true);
                              }, 3000);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      if (navigate) {
            return <Navigate to="/login" />;
      }

      if (redirect) {
            return <Navigate to="/login" />;
      }

      return (
            <>
                  <BgGray>
                        <Container>
                              <DashboardContent>
                                    <DashboardContainerGrid>
                                          <DashboardNavigation>
                                                <MenuItem
                                                      title="Dashboard"
                                                      link="/dashboard"
                                                      icon={<DashboardIcon />}
                                                />
                                                <MenuItem
                                                      title="My Order"
                                                      link="/my-orders"
                                                      icon={<OrderIcon />}
                                                />
                                                <MenuItem
                                                      title="Update Profile"
                                                      link="/update-profile"
                                                      icon={<ProfileIcon />}
                                                />
                                                <MenuItem
                                                      title="Change Password"
                                                      link="/change-password"
                                                      icon={<PasswordIcon />}
                                                />
                                                <MenuItem
                                                      title="Logout"
                                                      link="#"
                                                      loading={loading}
                                                      onClick={loadData}
                                                      icon={<LogoutIcon />}
                                                />
                                          </DashboardNavigation>
                                    </DashboardContainerGrid>

                                    <DashboardContentContainer>
                                          <div className="overflow-hidden">
                                                {children}
                                          </div>
                                    </DashboardContentContainer>
                              </DashboardContent>
                        </Container>
                  </BgGray>
            </>
      );
};

export default DashboardLayout;
