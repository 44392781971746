import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styled from 'styled-components';

const useStyles = makeStyles({
      root: {
            '&$checked': {
                  color: '#000'
            }
      },
      checked: {},
      wrap: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginLeft: 0
      },
      label: {
            fontSize: '1rem'
      }
});

const ColorContainer = styled.div`
      background-color: ${(props) => props.color};
      height: 30px;
      width: 30px;
      border-radius: 10px;
      border: 1px solid #ccc;
`;

const ColorBox = ({ color }) => {
      return (
            <>
                  <ColorContainer color={color}></ColorContainer>
            </>
      );
};

const ColorFilter = ({ colors, loading, handleChangeColor, type }) => {
      const classes = useStyles();
      return (
            <>
                  {!loading
                        ? colors.map((color, index) => (
                                <FormControlLabel
                                      classes={{
                                            label: classes.label,
                                            root: classes.wrap
                                      }}
                                      key={index}
                                      control={
                                            <Checkbox
                                                  classes={{
                                                        checked: classes.checked,
                                                        root: classes.root
                                                  }}
                                                  size="small"
                                                  checked={color.checked}
                                                  onChange={() =>
                                                        handleChangeColor(
                                                              color.id,
                                                              type
                                                        )
                                                  }
                                                  inputProps={{
                                                        'aria-label':
                                                              'checkbox with small size'
                                                  }}
                                            />
                                      }
                                      label={<ColorBox color={color.title} />}
                                      // label={`${ category.title } (${category.products})`}
                                />
                          ))
                        : ''}
            </>
      );
};
export default ColorFilter;
