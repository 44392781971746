import React, { useEffect, useState } from 'react';
import DashboardLayout from '../DashboardLayout';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import FormButton from '../../components/common/form/FormButton';
import { DashboardBreadcrumb, DashboardContentTitle } from '../Dashboard/style';
import {
      Col,
      Container,
      Form,
      FormControl,
      FormLabel,
      Row,
      Spinner
} from 'react-bootstrap';

const ProfileUpdate = () => {
      const [loadingData, setLoadingData] = useState(false);
      const [loading, setLoading] = useState(false);
      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [dateOfBirth, setDateOfBirth] = useState('');

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/customer/profile`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        setName(response.data.user.name);
                        setEmail(response.data.user.email);
                        setPhoneNumber(response.data.user.phone_number);
                        setDateOfBirth(response.data.user.date_of_birth);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  name: name,
                  email: email,
                  phone_number: phoneNumber,
                  date_of_birth: dateOfBirth
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/customer/update-profile`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoading(false);
      };

      return (
            <>
                  <DashboardBreadcrumb>
                        <Container>
                              <div className="page-breadcrumb">
                                    <Link to="/dashboard">Dashboard</Link> {'>'}{' '}
                                    Profile Update
                              </div>
                        </Container>
                  </DashboardBreadcrumb>
                  <DashboardLayout>
                        <DashboardContentTitle>
                              Update Profile
                        </DashboardContentTitle>

                        <Row>
                              <Col md={12}>
                                    {!loadingData ? (
                                          <Form onSubmit={handleSubmit}>
                                                <Row className="g-3">
                                                      <Col md={6}>
                                                            <FormLabel>
                                                                  Full Name
                                                            </FormLabel>
                                                            <FormControl
                                                                  type="text"
                                                                  className="form-control"
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setName(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                                  value={name}
                                                            />
                                                      </Col>
                                                      <Col md={6}>
                                                            <FormLabel>
                                                                  Email Address
                                                            </FormLabel>
                                                            <FormControl
                                                                  type="text"
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setEmail(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                                  value={email}
                                                            />
                                                      </Col>
                                                      <Col md={6}>
                                                            <FormLabel>
                                                                  Phone No.
                                                            </FormLabel>
                                                            <FormControl
                                                                  type="text"
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setPhoneNumber(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                                  value={
                                                                        phoneNumber
                                                                  }
                                                            />
                                                      </Col>
                                                      <Col md={6}>
                                                            <FormLabel>
                                                                  Date of Birth
                                                            </FormLabel>
                                                            <FormControl
                                                                  type="date"
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setDateOfBirth(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                                  value={
                                                                        dateOfBirth
                                                                  }
                                                            />
                                                      </Col>
                                                      <FormButton
                                                            title="Submit"
                                                            loading={loading}
                                                      />
                                                </Row>
                                          </Form>
                                    ) : (
                                          <div className="text-center">
                                                <Spinner />
                                          </div>
                                    )}
                              </Col>
                        </Row>
                  </DashboardLayout>
            </>
      );
};

export default ProfileUpdate;
