import React from 'react';
import {
      ProductListContent,
      ProductListItems,
      ProductListTitle
} from '../../styles/frontend/category';
import ColorFilter from '../component/shop/productlist/ColorFilter';
import SizeFilter from '../component/shop/productlist/SizeFilter';
import { Form, FormControl } from 'react-bootstrap';
import CategoryManage from '../category/CategoryManage';

const BrandDesktopFilter = ({
      categories,
      handleChangeCategory,
      handleChangeColor,
      handleChangeSize,
      loadingCategory,
      colors,
      sizes,
      setMax,
      setMin,
      min,
      max,
      changePrice
}) => {
      const handlePrice = (event) => {
            event.preventDefault();
            changePrice();
      };
      return (
            <>
                  <ProductListItems>
                        <ProductListTitle>
                              <h2>Categories</h2>
                        </ProductListTitle>
                        <ProductListContent>
                              <CategoryManage
                                    loading={loadingCategory}
                                    categories={categories}
                                    handleChangeCategory={handleChangeCategory}
                              />
                        </ProductListContent>
                  </ProductListItems>
                  <ProductListItems>
                        <ProductListTitle>
                              <h2>Colors</h2>
                        </ProductListTitle>
                        <ProductListContent>
                              <ColorFilter
                                    loading={loadingCategory}
                                    colors={colors}
                                    type="color"
                                    handleChangeColor={handleChangeColor}
                              />
                        </ProductListContent>
                  </ProductListItems>
                  <ProductListItems>
                        <ProductListTitle>
                              <h2>Sizes</h2>
                        </ProductListTitle>
                        <ProductListContent>
                              <SizeFilter
                                    loading={loadingCategory}
                                    type="size"
                                    changeChecked={handleChangeSize}
                                    sizes={sizes}
                              />
                        </ProductListContent>
                  </ProductListItems>
                  <ProductListItems>
                        <ProductListTitle>
                              <h2>Price</h2>
                        </ProductListTitle>
                        <ProductListContent>
                              <Form onSubmit={handlePrice}>
                                    <div className="d-flex gap-3">
                                          <FormControl
                                                type="number"
                                                min={0}
                                                onChange={(event) =>
                                                      setMin(event.target.value)
                                                }
                                                value={min}
                                                placeholder="Min"
                                          />
                                          <FormControl
                                                type="number"
                                                min={1}
                                                onChange={(event) =>
                                                      setMax(event.target.value)
                                                }
                                                value={max}
                                                placeholder="Max"
                                          />
                                    </div>
                                    <div className="mt-3">
                                          <button
                                                type="submit"
                                                className="px-3 py-1"
                                          >
                                                Go
                                          </button>
                                    </div>
                              </Form>
                        </ProductListContent>
                  </ProductListItems>
            </>
      );
};

export default BrandDesktopFilter;
