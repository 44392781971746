import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Swiper } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';

import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper';
import { Col, Container, Row } from 'react-bootstrap';
import {
      BrandBox,
      BrandContent,
      BrandDetail,
      BrandSliderItem
} from '../../../../styles/frontend/home';
import { Link } from 'react-router-dom';

const Brand = () => {
      const [brands, setBrands] = useState([]);
      const [loading, setLoading] = useState(false);

      const loadBrand = async () => {
            setLoading(true);
            await axios(`${process.env.REACT_APP_SECRET_KEY}/api/brands`, {
                  headers: {
                        apikey: process.env.REACT_APP_Api_Key
                  }
            })
                  .then((response) => {
                        setBrands(response.data.brands);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadBrand();
      }, []);

      return (
            <>
                  <BrandBox style={{ backgroundColor: '#f5f5f5' }}>
                        <Container>
                              <Row className="g-0 align-items-center">
                                    <Col lg={6}>
                                          <BrandContent>
                                                {!loading &&
                                                      brands.length > 0 && (
                                                            <Swiper
                                                                  spaceBetween={
                                                                        0
                                                                  }
                                                                  slidesPerView={
                                                                        1
                                                                  }
                                                                  navigation={
                                                                        false
                                                                  }
                                                                  autoplay={{
                                                                        delay: 3000,
                                                                        disableOnInteraction: false
                                                                  }}
                                                                  pagination={
                                                                        false
                                                                  }
                                                                  modules={[
                                                                        Autoplay,
                                                                        FreeMode,
                                                                        Navigation,
                                                                        Pagination
                                                                  ]}
                                                                  className="mySwiper2"
                                                            >
                                                                  {brands.map(
                                                                        (
                                                                              brand,
                                                                              index
                                                                        ) => (
                                                                              <BrandSliderItem
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <Row className="g-0">
                                                                                          {brand.map(
                                                                                                (
                                                                                                      item,
                                                                                                      key
                                                                                                ) => (
                                                                                                      <Col
                                                                                                            lg={
                                                                                                                  3
                                                                                                            }
                                                                                                            className="col-6"
                                                                                                            key={
                                                                                                                  key
                                                                                                            }
                                                                                                      >
                                                                                                            <Link
                                                                                                                  to={`/products/brands/${item.slug}`}
                                                                                                            >
                                                                                                                  <img
                                                                                                                        src={
                                                                                                                              item.image
                                                                                                                        }
                                                                                                                        alt={
                                                                                                                              item.title
                                                                                                                        }
                                                                                                                        width="100%"
                                                                                                                        height="100%"
                                                                                                                  />
                                                                                                            </Link>
                                                                                                      </Col>
                                                                                                )
                                                                                          )}
                                                                                    </Row>
                                                                              </BrandSliderItem>
                                                                        )
                                                                  )}
                                                            </Swiper>
                                                      )}
                                          </BrandContent>
                                    </Col>
                                    <Col lg={6}>
                                          <Row className="justify-content-end g-0">
                                                <Col
                                                      lg={10}
                                                      className="pt-5 pb-5"
                                                >
                                                      <BrandDetail>
                                                            <h2>
                                                                  Brands We
                                                                  Offer
                                                            </h2>
                                                            <p>
                                                                  It's now
                                                                  easier than
                                                                  ever to give
                                                                  your space a
                                                                  makeover you
                                                                  deserve for.
                                                                  Visit your
                                                                  nearest
                                                                  Bathnroom
                                                                  World or
                                                                  Orientation
                                                                  Center or
                                                                  Authorized
                                                                  Dealer to know
                                                                  more to choose
                                                                  from the wide
                                                                  range of
                                                                  complete
                                                                  bathroom and
                                                                  lighting
                                                                  solutions.
                                                            </p>
                                                      </BrandDetail>
                                                </Col>
                                          </Row>
                                    </Col>
                              </Row>
                        </Container>
                  </BrandBox>
            </>
      );
};
export default Brand;
