import React from 'react';

const CatalogueView = ({ slug }) => {
      return (
            <>
                  <iframe
                        src={`${process.env.REACT_APP_SECRET_KEY}/catalogues/${slug}`}
                        style={{
                              border: 0,
                              width: '100%',
                              height: 'calc(100vh - 70px)',
                              overflow: 'hidden'
                        }}
                        allowFullscreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
            </>
      );
};

export default CatalogueView;
