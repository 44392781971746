import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { DashboardNavigationItem, DashboardNavigationLink } from './style';
import { Spinner } from 'react-bootstrap';

const MenuItem = ({ icon, title, link, onClick, loading }) => {
      const location = useLocation().pathname;
      return (
            <>
                  <DashboardNavigationItem
                        className={location === link ? 'active' : ''}
                  >
                        {icon}
                        <DashboardNavigationLink to={link} onClick={onClick}>
                              {title} {loading && (
                                    <Spinner />
                              )}
                        </DashboardNavigationLink>
                  </DashboardNavigationItem>
            </>
      );
};

export default MenuItem;
