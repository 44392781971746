import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { AdditionalDetail } from '../../../styles/frontend/products';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { AdditionContainer, RelatedProduct } from './style';
// import Rating from './Rating';

const ProductAdditional = ({ product, loading, slug }) => {
      const [products, setProducts] = useState([]);
      const [isLoading, setIsLoading] = useState(false);

      useEffect(() => {
            const loadData = async () => {
                  setIsLoading(true);
                  await axios
                        .get(
                              `${process.env.REACT_APP_SECRET_KEY}/api/products/${slug}/related`,
                              {
                                    headers: {
                                          apikey: process.env.REACT_APP_Api_Key
                                    }
                              }
                        )
                        .then((response) => {
                              setProducts(response.data.products);
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
                  setIsLoading(false);
            };
            loadData();
      }, []);

      return (
            <>
                  <AdditionContainer>
                        <Row>
                              <Col lg={9}>
                                    <div className="product__additional bg-white h-100 p-3">
                                          <ul
                                                className="nav nav-tabs"
                                                id="myTab"
                                                role="tablist"
                                          >
                                                <li
                                                      className="nav-item"
                                                      role="presentation"
                                                >
                                                      <button
                                                            className="nav-link active"
                                                            id="home-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#home-tab-pane"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="home-tab-pane"
                                                            aria-selected="true"
                                                      >
                                                            Description
                                                      </button>
                                                </li>
                                                <li
                                                      className="nav-item"
                                                      role="presentation"
                                                >
                                                      <button
                                                            className="nav-link"
                                                            id="profile-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#profile-tab-pane"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="profile-tab-pane"
                                                            aria-selected="false"
                                                      >
                                                            Additional
                                                            Information
                                                      </button>
                                                </li>
                                                {/* <li
                                                className="nav-item"
                                                role="presentation"
                                          >
                                                <button
                                                      className="nav-link"
                                                      id="contact-tab"
                                                      data-bs-toggle="tab"
                                                      data-bs-target="#contact-tab-pane"
                                                      type="button"
                                                      role="tab"
                                                      aria-controls="contact-tab-pane"
                                                      aria-selected="false"
                                                >
                                                      Reviews
                                                </button>
                                          </li> */}
                                          </ul>
                                          <div
                                                className="tab-content"
                                                id="myTabContent"
                                          >
                                                <div
                                                      className="tab-pane fade show active"
                                                      id="home-tab-pane"
                                                      role="tabpanel"
                                                      aria-labelledby="home-tab"
                                                      tabIndex="0"
                                                >
                                                      <div className="product__desc">
                                                            <div
                                                                  className="my-3"
                                                                  dangerouslySetInnerHTML={{
                                                                        __html: product.description
                                                                  }}
                                                            ></div>
                                                      </div>
                                                </div>
                                                <div
                                                      className="tab-pane fade"
                                                      id="profile-tab-pane"
                                                      role="tabpanel"
                                                      aria-labelledby="profile-tab"
                                                      tabIndex="0"
                                                >
                                                      <AdditionalDetail>
                                                            <li>
                                                                  <span>
                                                                        Brand :
                                                                  </span>
                                                                  <span>
                                                                        {
                                                                              product.brand
                                                                        }
                                                                  </span>
                                                            </li>
                                                            {product.size && (
                                                                  <li>
                                                                        <span>
                                                                              Size
                                                                              :
                                                                        </span>
                                                                        <span>
                                                                              {`${product.size_unit} ${product.unit}`}
                                                                        </span>
                                                                  </li>
                                                            )}
                                                            <li>
                                                                  <span>
                                                                        Category
                                                                        :
                                                                  </span>
                                                                  <span>
                                                                        {
                                                                              product.category
                                                                        }
                                                                  </span>
                                                            </li>

                                                            {!loading &&
                                                                  product.tabs &&
                                                                  product.tabs.map(
                                                                        (
                                                                              tab,
                                                                              index
                                                                        ) => (
                                                                              <li
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <span>
                                                                                          {
                                                                                                tab.title
                                                                                          }

                                                                                          :
                                                                                    </span>
                                                                                    <span>
                                                                                          {
                                                                                                tab.content
                                                                                          }
                                                                                    </span>
                                                                              </li>
                                                                        )
                                                                  )}
                                                      </AdditionalDetail>
                                                </div>
                                                {/* <div
                                                className="tab-pane fade"
                                                id="contact-tab-pane"
                                                role="tabpanel"
                                                aria-labelledby="contact-tab"
                                                tabIndex="0"
                                          >
                                                <ReviewContent>
                                                      <ReviewLogin>
                                                            <p>
                                                                  Login or
                                                                  Register to
                                                                  give your
                                                                  review !
                                                            </p>
                                                            <ReviewLoginContent>
                                                                  <LoginButton to="/login">
                                                                        Login
                                                                  </LoginButton>
                                                                  <LoginButton to="register">
                                                                        Register
                                                                  </LoginButton>
                                                            </ReviewLoginContent>
                                                      </ReviewLogin>
                                                </ReviewContent>
                                          </div> */}
                                          </div>
                                    </div>
                              </Col>
                              <RelatedProduct lg={3}>
                                    <div className="product__suggest">
                                          <div className="product__suggest--title mb-3">
                                                <h3>Related Products</h3>
                                          </div>
                                          <div className="product__suggest--container gap-3 d-flex flex-wrap">
                                                {!isLoading ? (
                                                      products.length > 0 &&
                                                      products.map(
                                                            (item, index) => (
                                                                  <div
                                                                        className="product__suggest--content w-100"
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <Link
                                                                              to={`/products/${item.slug}`}
                                                                              className="d-flex align-items-center gap-3"
                                                                        >
                                                                              <img
                                                                                    src={
                                                                                          item.image
                                                                                    }
                                                                                    alt="product"
                                                                                    className=" img-fit"
                                                                              ></img>
                                                                              <div className="product__suggest--info">
                                                                                    <h5 className="mb-2">
                                                                                          {
                                                                                                item.title
                                                                                          }
                                                                                    </h5>
                                                                                    <p>
                                                                                          Rs:{' '}
                                                                                          {
                                                                                                item.price
                                                                                          }
                                                                                    </p>
                                                                              </div>
                                                                        </Link>
                                                                  </div>
                                                            )
                                                      )
                                                ) : (
                                                      <>Loading</>
                                                )}
                                          </div>
                                    </div>
                              </RelatedProduct>
                        </Row>
                  </AdditionContainer>
            </>
      );
};
export default ProductAdditional;
