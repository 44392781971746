import React from 'react';

const ContactMap = () => {
      return (
            <>
                  <div>
                        <iframe
                              src="https://www.google.com/maps/d/u/0/embed?mid=1Q9mX8vGPdr6HC8o7ceIuOw2L_WO07KWq&ehbc=2E312F"
                              width="100%"
                              height="480"
                        ></iframe>
                  </div>
            </>
      );
};

export default ContactMap;
