import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const ShowroomDetail = () => {
      const { showroomId } = useParams();
      const [loading, setLoading] = useState(false);
      const [showroom, setShowroom] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/showrooms/${showroomId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setShowroom(response.data.showroom);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <iframe
                        className="w-100"
                        src={`${process.env.REACT_APP_SECRET_KEY}/showrooms/${showroomId}`}
                        frameborder="0"
                  ></iframe>
            </>
      );
};

export default ShowroomDetail;
