import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../frontend/home';
import ShopLanding from '../frontend/shop/shoplanding';
import ContactPage from '../frontend/contact';
import ProductDetail from '../frontend/product';
import CategoryPage from '../frontend/category';
import { WhoWeAre } from '../frontend/about';
import Showroom from '../frontend/showroom';
import {
      TermsAndCondition,
      PrivacyPolicy,
      DealerPolicy
} from '../frontend/policies';
import PageNotFound from '../frontend/pageNotFound';
import { BlogList, BlogDetail } from '../frontend/blogs';
import { Career } from '../frontend/career';
import { CareerDetail } from '../frontend/career/CareerDetail';
import Faq from '../frontend/faq';
import { DealerEnquiry, CustomerEnquiry } from '../frontend/enquiries';
import Catalogue from '../frontend/catalogue';
import { YourQuestion } from '../frontend/questions';
import SupportTicket from '../frontend/complain';
import CareerApply from '../frontend/career/CareerApply';
import ShowroomDetail from '../frontend/showroomDetail';
import SearchPage from '../frontend/search';
import Login from '../auth/Login';
import CartPage from '../customers/carts';
import Checkout from '../customers/checkout';
import { Dashboard } from '../customers';
import OrderList from '../customers/OrderList';
import OrderShow from '../customers/OrderShow';
import Wishlist from '../customers/wishlists';
import ProfileUpdate from '../customers/ProfileUpdate';
import ChangePassword from '../customers/ChangePassword';
import Register from '../auth/Register';
import { BrandPage } from '../frontend/brands';

const PublicRouter = () => {
      return (
            <>
                  <Routes>
                        <Route path="/" exact element={<HomePage />} />
                        <Route path="/products" element={<ShopLanding />} />

                        {/* Brand */}
                        <Route
                              path="/products/brands/:brandId"
                              element={<BrandPage />}
                        />
                        {/* Product Details */}
                        <Route
                              path="/products/:productId"
                              element={<ProductDetail />}
                        />

                        {/* Category */}
                        <Route
                              path="/products/categories/:categoryId"
                              element={<CategoryPage />}
                        />

                        {/* Search Page */}
                        <Route path="/search" element={<SearchPage />} />

                        {/* Catalgoue */}
                        <Route path="/catalogues" element={<Catalogue />} />

                        {/* Showroom */}
                        <Route path="showrooms" element={<Showroom />} />
                        <Route
                              path="/showrooms/:showroomId"
                              element={<ShowroomDetail />}
                        />
                        {/* Company Policies */}
                        <Route
                              path="/privacy-policy"
                              element={<PrivacyPolicy />}
                        />
                        <Route
                              path="terms-and-conditions"
                              element={<TermsAndCondition />}
                        />
                        <Route
                              path="/dealer-policy"
                              element={<DealerPolicy />}
                        />

                        {/* Ask Your Question */}
                        <Route
                              path="/ask-your-question"
                              element={<YourQuestion />}
                        />

                        {/* Contact Page */}
                        <Route path="/contact-us" element={<ContactPage />} />

                        {/* About Us */}
                        <Route path="/who-we-are" element={<WhoWeAre />} />

                        {/* Career */}
                        <Route path="/careers" element={<Career />} />
                        <Route
                              path="/careers/:careerId"
                              element={<CareerDetail />}
                        />
                        <Route
                              path="/careers/:careerId/apply"
                              element={<CareerApply />}
                        />

                        {/* Faq Page */}
                        <Route path="/faqs" element={<Faq />} />

                        {/* Blog */}
                        <Route path="/blogs" element={<BlogList />} />
                        <Route path="/blogs/:blogId" element={<BlogDetail />} />

                        {/* Dealer Enquiry */}
                        <Route
                              path="/dealer-enquiry"
                              element={<DealerEnquiry />}
                        />
                        <Route
                              path="/customer-enquiry"
                              element={<CustomerEnquiry />}
                        />
                        <Route
                              path="/support-ticket"
                              element={<SupportTicket />}
                        />

                        {/* Login */}
                        <Route path="/login" element={<Login />} />

                        {/* Register */}
                        <Route path="/register" element={<Register />} />

                        {/* Wishlist */}
                        <Route path="/wishlists" element={<Wishlist />} />

                        {/* Customer Product */}
                        <Route path="/carts" element={<CartPage />} />

                        {/* Customer Checkout */}
                        <Route path="/checkouts" element={<Checkout />} />

                        {/* Dashboard */}
                        <Route path="/dashboard" element={<Dashboard />} />

                        {/* My Order */}
                        <Route path="my-orders" element={<OrderList />} />
                        <Route
                              path="/my-orders/:orderId"
                              element={<OrderShow />}
                        />

                        {/* Update Update */}
                        <Route
                              path="/update-profile"
                              element={<ProfileUpdate />}
                        />

                        {/* Change Password */}
                        <Route
                              path="/change-password"
                              element={<ChangePassword />}
                        />

                        {/* Page Not Found */}
                        <Route path="*" element={<PageNotFound />} />
                  </Routes>
            </>
      );
};
export default PublicRouter;
