import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { DesktopFilter } from '../category/style';
import Progress from 'react-progress-2';
import BrandDesktopFilter from './BrandDesktopFilter';
import {
      CategoryListFilter,
      CategoryListItem,
      CategoryListRemove,
      CategoryListRemoveIcon,
      FilterContainer,
      MobileFilter
} from '../../styles/frontend/category';
import CategoryMobileFilter from '../category/CategoryMobileFilter';
import ProductSection from '../category/ProductSection';
import Pagination from 'react-js-pagination';
import { Helmet } from 'react-helmet';

const BrandPage = () => {
      const { brandId } = useParams();
      const [brand, setBrand] = useState('');
      const [products, setProducts] = useState([]);
      const [totalProducts, setTotalProducts] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);
      const [loading, setLoading] = useState(false);

      const [loadingCategory, setLoadingCategory] = useState(false);
      const [categories, setCategories] = useState([]);
      const [colors, setColors] = useState([]);
      const [sizes, setSizes] = useState([]);

      const [sort, setSort] = useState('desc');
      const [min, setMin] = useState('');
      const [max, setMax] = useState('');

      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      const [selectedColors, setSelectedColors] = useState([]);
      const [selectedColorLists, setSelectedColorLists] = useState([]);

      const [selectedCuisines, setSelectedCuisines] = useState([]);
      const [selectedCategories, setSelectedCategories] = useState([]);

      const [selectedSizes, setSelectedSizes] = useState([]);
      const [selectedSizeLists, setSelectedSizeLists] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/brands/${brandId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBrand(response.data.brand);
                              setProducts(response.data.products);
                              setTotalProducts(response.data.totalProducts);
                              setPerPages(response.data.perPages);
                              setCurrentPage(response.data.currentPage);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      const loadCategory = async () => {
            setLoadingCategory(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/brands/${brandId}/filter`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        }
                  )
                  .then((response) => {
                        setCategories(response.data.categories);
                        setColors(response.data.colors);
                        setSizes(response.data.sizes);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoadingCategory(false);
      };

      useEffect(() => {
            loadData();
            loadCategory();
      }, [brandId]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            Progress.show();
            setLoading(true);

            try {
                  const filters = [];
                  if (selectedCuisines.length) {
                        filters.push(
                              `categories=${selectedCuisines.join(',')}`
                        );
                  }
                  if (selectedColors.length) {
                        filters.push(`colors=${selectedColors.join(',')}`);
                  }
                  if (selectedSizes.length) {
                        filters.push(`sizes=${selectedSizes.join(',')}`);
                  }
                  const filterQueryString =
                        filters.length > 0 ? `&${filters.join('&')}` : '';
                  const url = `${process.env.REACT_APP_SECRET_KEY}/api/brands/${brandId}?${filterQueryString}&page=${pageNumber}&sort=${sort}&min=${min}&max=${max}`;

                  const response = await axios.get(url, {
                        headers: {
                              apikey: process.env.REACT_APP_Api_Key
                        }
                  });

                  setProducts(response.data.products);
                  setTotalProducts(response.data.totalProducts);
                  setPerPages(response.data.itemsCountPerPage);
                  setCurrentPage(response.data.currentPage);

                  Progress.hide();
                  setLoading(false);
            } catch (error) {
                  console.log(error.message);
                  Progress.hide();
                  setLoading(false);
            }
      }

      const handleChangeCategory = (id, type) => {
            const updatedCategories = categories.map((item) => {
                  if (type === 'category') {
                        return item.id === id
                              ? { ...item, checked: !item.checked }
                              : item;
                  } else if (type === 'subCategory') {
                        const updatedSubCategory = item.subCategory.map(
                              (subCategory) => {
                                    return subCategory.id === id
                                          ? {
                                                  ...subCategory,
                                                  checked: !subCategory.checked
                                            }
                                          : subCategory;
                              }
                        );
                        return { ...item, subCategory: updatedSubCategory };
                  } else if (type === 'childCategory') {
                        const updatedSubCategory = item.subCategory.map(
                              (subCategory) => {
                                    const updatedChildCategory =
                                          subCategory.childCategory.map(
                                                (childCategory) => {
                                                      return childCategory.id ===
                                                            id
                                                            ? {
                                                                    ...childCategory,
                                                                    checked: !childCategory.checked
                                                              }
                                                            : childCategory;
                                                }
                                          );
                                    return {
                                          ...subCategory,
                                          childCategory: updatedChildCategory
                                    };
                              }
                        );
                        return { ...item, subCategory: updatedSubCategory };
                  }
                  return item;
            });

            setCategories(updatedCategories);
      };

      const handleChangeColor = (id, type) => {
            const colorsStateList = colors;
            const changeCheckedColor = colorsStateList.map((item) => {
                  if (type === 'color') {
                        return item.id === id
                              ? { ...item, checked: !item.checked }
                              : item;
                  }
                  return item;
            });
            setColors(changeCheckedColor);
      };

      const handleChangeSize = (id, type) => {
            const sizesStateList = sizes;
            const changeCheckedSize = sizesStateList.map((item) => {
                  if (type === 'size') {
                        return item.id === id
                              ? { ...item, checked: !item.checked }
                              : item;
                  }
                  return item;
            });
            setSizes(changeCheckedSize);
      };

      const changePrice = async () => {
            const filters = [];

            const colorsChecked = colors
                  .filter((item) => item.checked)
                  .map((item) => ({
                        id: item.id,
                        title: item.title,
                        type: 'color'
                  }));

            const selectedColorIds = colorsChecked.map((item) => item.id);
            setSelectedColors(colorsChecked);
            setSelectedColorLists(colorsChecked);

            if (selectedColorIds.length) {
                  filters.push(`colors=${selectedColorIds.join(',')}`);
            }

            const sizesChecked = sizes
                  .filter((item) => item.checked)
                  .map((item) => ({
                        id: item.id,
                        title: item.title,
                        subtitle: item.subtitle,
                        type: 'size'
                  }));

            const selectedSizeIds = sizesChecked.map((item) => item.subtitle);
            setSelectedSizes(sizesChecked);
            setSelectedSizeLists(sizesChecked);

            if (selectedSizeIds.length) {
                  filters.push(`sizes=${selectedSizeIds.join(',')}`);
            }

            const cuisinesChecked = categories
                  .filter((item) => item.checked)
                  .map((item) => ({
                        id: item.id,
                        title: item.title,
                        type: 'category'
                  }));

            const subCategoriesChecked = categories
                  .flatMap((item) =>
                        item.subCategory.filter((subItem) => subItem.checked)
                  )
                  .map((subItem) => ({
                        id: subItem.id,
                        title: subItem.title,
                        type: 'subCategory'
                  }));

            const childCategoriesChecked = categories
                  .flatMap((item) =>
                        item.subCategory.flatMap((subItem) =>
                              subItem.childCategory.filter(
                                    (childItem) => childItem.checked
                              )
                        )
                  )
                  .map((childItem) => ({
                        id: childItem.id,
                        title: childItem.title,
                        type: 'childCategory'
                  }));

            const allCheckedIds = [
                  ...cuisinesChecked,
                  ...subCategoriesChecked,
                  ...childCategoriesChecked
            ];

            const selectedCuisinesIds = allCheckedIds.map((item) => item.id);
            setSelectedCuisines(selectedCuisinesIds);
            setSelectedCategories(allCheckedIds);

            if (allCheckedIds.length) {
                  filters.push(`categories=${selectedCuisinesIds.join(',')}`);
            }

            const apiUrl = `${
                  process.env.REACT_APP_SECRET_KEY
            }/api/brands/${brandId}${
                  filters.length > 0 ? '?' + filters.join('&') : '?'
            }${sort ? `&sort=${sort}` : ''}${
                  max > 0 ? `&min=${min}&max=${max}` : ``
            }`;

            await axios
                  .get(apiUrl, {
                        headers: {
                              apikey: process.env.REACT_APP_Api_Key
                        }
                  })
                  .then((response) => {
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      };

      useEffect(() => {
            changePrice();
      }, [totalProducts, categories, sizes, colors, sort]);

      return (
            <>
                  {!loading && (
                        <Helmet>
                              <title>{brand.seo_title || brand.title}</title>
                              {brand.seo_description && (
                                    <meta
                                          name="description"
                                          content={brand.seo_description}
                                    />
                              )}
                              {brand.seo_keyword && (
                                    <meta
                                          name="keyword"
                                          content={brand.seo_keyword}
                                    />
                              )}
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  )}

                  <Breadcrumb
                        otherLink="/products"
                        otherTitle="Products"
                        title={brand.title}
                        back="/products"
                  />

                  <div
                        className="product__list py-4 "
                        style={{ backgroundColor: '#f2f2f2' }}
                  >
                        <Container fluid>
                              <Row>
                                    <DesktopFilter className="col-md-3">
                                          <BrandDesktopFilter
                                                min={min}
                                                max={max}
                                                setMax={setMax}
                                                setMin={setMin}
                                                sizes={sizes}
                                                colors={colors}
                                                loadingCategory={
                                                      loadingCategory
                                                }
                                                categories={categories}
                                                handleChangeColor={
                                                      handleChangeColor
                                                }
                                                handleChangeSize={
                                                      handleChangeSize
                                                }
                                                handleChangeCategory={
                                                      handleChangeCategory
                                                }
                                                changePrice={changePrice}
                                          />
                                    </DesktopFilter>
                                    <Col lg={9}>
                                          <FilterContainer>
                                                <CategoryMobileFilter
                                                      show={show}
                                                      handleClose={handleClose}
                                                      handleShow={handleShow}
                                                >
                                                      <BrandDesktopFilter
                                                            min={min}
                                                            max={max}
                                                            setMax={setMax}
                                                            setMin={setMin}
                                                            sizes={sizes}
                                                            colors={colors}
                                                            loadingCategory={
                                                                  loadingCategory
                                                            }
                                                            categories={
                                                                  categories
                                                            }
                                                            handleChangeColor={
                                                                  handleChangeColor
                                                            }
                                                            handleChangeSize={
                                                                  handleChangeSize
                                                            }
                                                            handleChangeCategory={
                                                                  handleChangeCategory
                                                            }
                                                            changePrice={
                                                                  changePrice
                                                            }
                                                      />
                                                </CategoryMobileFilter>
                                                <DesktopFilter>
                                                      {totalProducts} products
                                                      found{' '}
                                                      {brand.title && (
                                                            <>
                                                                  on{' '}
                                                                  {brand.title}
                                                            </>
                                                      )}
                                                </DesktopFilter>
                                                <div>
                                                      <select
                                                            className="form-select"
                                                            onChange={(event) =>
                                                                  setSort(
                                                                        event
                                                                              .target
                                                                              .value
                                                                  )
                                                            }
                                                      >
                                                            <option value="">
                                                                  Filter By
                                                            </option>
                                                            <option value="asc">
                                                                  Price low to
                                                                  high
                                                            </option>
                                                            <option value="desc">
                                                                  Price high to
                                                                  low
                                                            </option>
                                                      </select>
                                                </div>
                                          </FilterContainer>
                                          <div>
                                                <MobileFilter className="mb-3">
                                                      {totalProducts} products
                                                      found{' '}
                                                      {brand.title && (
                                                            <>
                                                                  on{' '}
                                                                  {brand.title}
                                                            </>
                                                      )}
                                                </MobileFilter>
                                                <CategoryListFilter>
                                                      {selectedSizeLists.length >
                                                            0 &&
                                                            selectedSizeLists.map(
                                                                  (
                                                                        size,
                                                                        index
                                                                  ) => (
                                                                        <li
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <CategoryListItem>
                                                                                    {
                                                                                          size.title
                                                                                    }
                                                                                    <CategoryListRemove
                                                                                          onClick={() =>
                                                                                                handleChangeSize(
                                                                                                      size.id,
                                                                                                      size.type
                                                                                                )
                                                                                          }
                                                                                    >
                                                                                          <CategoryListRemoveIcon>
                                                                                                <i className="bx bx-x"></i>
                                                                                          </CategoryListRemoveIcon>
                                                                                    </CategoryListRemove>
                                                                              </CategoryListItem>
                                                                        </li>
                                                                  )
                                                            )}
                                                      {selectedColorLists.length >
                                                            0 &&
                                                            selectedColorLists.map(
                                                                  (
                                                                        color,
                                                                        index
                                                                  ) => (
                                                                        <li
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <CategoryListItem>
                                                                                    {
                                                                                          color.title
                                                                                    }
                                                                                    <CategoryListRemove
                                                                                          onClick={() =>
                                                                                                handleChangeColor(
                                                                                                      color.id,
                                                                                                      color.type
                                                                                                )
                                                                                          }
                                                                                    >
                                                                                          <CategoryListRemoveIcon>
                                                                                                <i className="bx bx-x"></i>
                                                                                          </CategoryListRemoveIcon>
                                                                                    </CategoryListRemove>
                                                                              </CategoryListItem>
                                                                        </li>
                                                                  )
                                                            )}
                                                      {selectedCategories.length >
                                                            0 &&
                                                            selectedCategories.map(
                                                                  (
                                                                        item,
                                                                        index
                                                                  ) => (
                                                                        <li
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <CategoryListItem>
                                                                                    {
                                                                                          item.title
                                                                                    }
                                                                                    <CategoryListRemove
                                                                                          onClick={() =>
                                                                                                handleChangeCategory(
                                                                                                      item.id,
                                                                                                      item.type
                                                                                                )
                                                                                          }
                                                                                    >
                                                                                          <CategoryListRemoveIcon>
                                                                                                <i className="bx bx-x"></i>
                                                                                          </CategoryListRemoveIcon>
                                                                                    </CategoryListRemove>
                                                                              </CategoryListItem>
                                                                        </li>
                                                                  )
                                                            )}
                                                </CategoryListFilter>
                                          </div>

                                          <ProductSection
                                                loading={loading}
                                                products={products}
                                          />

                                          {totalProducts > 20 && (
                                                <div className="mt-4 float-end">
                                                      <Pagination
                                                            activePage={
                                                                  currentPage
                                                            }
                                                            totalItemsCount={
                                                                  totalProducts
                                                            }
                                                            itemsCountPerPage={
                                                                  perPages
                                                            }
                                                            onChange={(
                                                                  pageNumber
                                                            ) =>
                                                                  getData(
                                                                        pageNumber
                                                                  )
                                                            }
                                                            itemClass="page-item"
                                                            linkClass="page-link"
                                                            firstPageText="First"
                                                            lastPageText="Last"
                                                      />
                                                </div>
                                          )}
                                    </Col>
                              </Row>
                        </Container>
                  </div>
            </>
      );
};

export default BrandPage;
