import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Mmenu from 'mmenu-js';
import 'mmenu-js/dist/mmenu.css';

const MobileMenu = ({ setShow }) => {
      const menuRef = useRef(null);

      useEffect(() => {
            if (!menuRef.current) {
                  menuRef.current = new Mmenu('#menu', {
                        offCanvas: {
                              use: false
                        },
                        counters: {
                              add: true
                        },
                        setSelected: {
                              hover: true
                        },
                        navbars: [
                              {
                                    position: 'top',
                                    content: ['searchfield']
                              }
                        ]
                  });
            }
      }, []);

      return (
            <>
                  <nav id="menu">
                        <ul>
                              <li>
                                    <Link onClick={() => setShow()} to="/">
                                          Home
                                    </Link>
                              </li>
                              <li>
                                    <span>About us</span>
                                    <ul>
                                          <li>
                                                <Link
                                                      onClick={() => setShow()}
                                                      to="/who-we-are"
                                                >
                                                      Who we are?
                                                </Link>
                                          </li>
                                          <li>
                                                <Link
                                                      onClick={() => setShow()}
                                                      to="/terms-and-conditions"
                                                >
                                                      Company Policy
                                                </Link>
                                          </li>
                                    </ul>
                              </li>
                              <li>
                                    <Link
                                          onClick={() => setShow()}
                                          to="/products"
                                    >
                                          Products
                                    </Link>
                              </li>
                              <li>
                                    <Link
                                          onClick={() => setShow()}
                                          to="/catalogues"
                                    >
                                          Catalogues
                                    </Link>
                              </li>
                              <li>
                                    <Link
                                          onClick={() => setShow()}
                                          to="/showrooms"
                                    >
                                          Showroom
                                    </Link>
                              </li>
                              <li>
                                    <Link
                                          onClick={() => setShow()}
                                          to="/contact-us"
                                    >
                                          Contact Us
                                    </Link>
                              </li>
                        </ul>
                  </nav>
            </>
      );
};

export default MobileMenu;
