import React, { useContext } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Col, Container, Row } from 'react-bootstrap';

import 'swiper/css';
import 'swiper/css/effect-fade';

import { Swiper } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';
import {
      AboutRow,
      AboutShowroomItem,
      AboutSliderItem
} from '../../styles/frontend/about';
import { AboutSlider } from './style';
import { Link } from 'react-router-dom';
import { WhyCol } from '../../components/frontend/home/WhyUs/style';
import MetaContext from '../../stores/MetaContext';

const WhoWeAre = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('who-we-are');

      return (
            <>
                  <Breadcrumb title="Who we are - Bathnroom" />

                  <Container>
                        <AboutRow>
                              <WhyCol order={2} lg={6}>
                                    <Row>
                                          <Col lg={9}>
                                                <h2>Smart Products</h2>
                                                <p>
                                                      At Bathnroom, we
                                                      constantly reinvent our
                                                      products and bring you the
                                                      innovative. Transform your
                                                      space into a smart space
                                                      with the latest
                                                      intelligent bath and light
                                                      products designed for
                                                      efficiency, comfort, and
                                                      convenience.
                                                </p>
                                                <ul>
                                                      <li>
                                                            <Link to="/products/categories/tiles">
                                                                  Tile
                                                            </Link>
                                                      </li>
                                                      <li>
                                                            <Link to="/products/categories/bath-ware">
                                                                  Bath Ware
                                                            </Link>
                                                      </li>
                                                      <li>
                                                            <Link to="/products/categories/sanitaryware">
                                                                  Sanitaryware
                                                            </Link>
                                                      </li>
                                                </ul>
                                          </Col>
                                    </Row>
                              </WhyCol>
                              <WhyCol order={1} lg={6}>
                                    <Swiper
                                          loop={true}
                                          autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false
                                          }}
                                          effect={'fade'}
                                          modules={[Autoplay, EffectFade]}
                                    >
                                          <AboutSliderItem>
                                                <img
                                                      src="/images/sliders/01.jpg"
                                                      alt="Bathnroom Banner Image"
                                                      height="100%"
                                                      width="100%"
                                                />
                                          </AboutSliderItem>
                                          <AboutSliderItem>
                                                <img
                                                      src="/images/sliders/02.jpg"
                                                      alt="Bathnroom Banner Image"
                                                      height="100%"
                                                      width="100%"
                                                />
                                          </AboutSliderItem>
                                    </Swiper>
                              </WhyCol>
                        </AboutRow>
                  </Container>

                  <Container fluid className="p-0">
                        <AboutRow
                              padding="0 15px"
                              bgColor="#f5f5f5"
                              className="g-0"
                        >
                              <Col lg={6}>
                                    <AboutSlider
                                          loop={true}
                                          autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false
                                          }}
                                          effect={'fade'}
                                          modules={[Autoplay, EffectFade]}
                                    >
                                          <AboutSliderItem>
                                                <img
                                                      src="/images/showrooms/crystal-chandelier-close-up-3.jpg"
                                                      alt="UPGRADE YOUR SPACE
                                                LIVE THE UBER LIFE"
                                                      height="100%"
                                                      width="100%"
                                                />
                                          </AboutSliderItem>
                                    </AboutSlider>
                              </Col>
                              <Col lg={6}>
                                    <Row className="justify-content-end g-0">
                                          <Col lg={10}>
                                                <AboutShowroomItem>
                                                      <h2>
                                                            UPGRADE YOUR SPACE
                                                            LIVE THE UBER LIFE
                                                      </h2>
                                                      <p>
                                                            It's now easier than
                                                            ever to give your
                                                            space a makeover you
                                                            deserve for. Visit
                                                            your nearest
                                                            Bathnroom World or
                                                            Orientation Center
                                                            or Authorized Dealer
                                                            to know more to
                                                            choose from the wide
                                                            range of complete
                                                            bathroom and
                                                            lighting solutions.
                                                      </p>
                                                </AboutShowroomItem>
                                          </Col>
                                    </Row>
                              </Col>
                        </AboutRow>
                  </Container>

                  <Container fluid>
                        <AboutRow>
                              <WhyCol order={2} lg={6}>
                                    <Row className="justify-content-end">
                                          <Col lg={10}>
                                                <AboutShowroomItem>
                                                      <h2>BRAND SHOWROOMS</h2>
                                                      <p>
                                                            Walk into our brand
                                                            showroom and
                                                            experience
                                                            incredible designs,
                                                            bath and light
                                                            concepts showcased
                                                            for you.
                                                      </p>
                                                </AboutShowroomItem>
                                          </Col>
                                    </Row>
                              </WhyCol>
                              <WhyCol order={1} lg={6}>
                                    <Swiper
                                          loop={true}
                                          autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false
                                          }}
                                          effect={'fade'}
                                          modules={[Autoplay, EffectFade]}
                                    >
                                          <AboutSliderItem>
                                                <img
                                                      src="/images/showrooms/brand-showroom.jpg"
                                                      alt="Bathnroom Brand Showroom Image"
                                                      height="100%"
                                                      width="100%"
                                                />
                                          </AboutSliderItem>
                                    </Swiper>
                              </WhyCol>
                        </AboutRow>
                  </Container>
            </>
      );
};

export default WhoWeAre;
