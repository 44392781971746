import styled from 'styled-components';

export const DashboardTable = styled.div`
      max-width: 1536px;
`;

export const DashboardTableContainer = styled.div`
      border-radius: 0.375rem;
`;

export const DashboardTableContent = styled.div`
      display: flex;
      flex-direction: column;
`;

export const DashboardTableTitle = styled.h3`
      margin-bottom: 1.25rem;
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 500;
`;

export const DashboardTableGrid = styled.div`
      overflow-x: auto;
      margin-top: -0.5rem;
      margin-bottom: -0.5rem;

      @media screen and (min-width: 640px) {
            margin-left: -1.5rem;
            margin-right: -1.5rem;
      }

      @media screen and (min-width: 1024px) {
            margin-left: -2rem;
            margin-right: -2rem;
      }
`;

export const DashboardTableGroup = styled.div`
      display: inline-block;
      padding-bottom: 0.5rem;
      vertical-align: middle;
      min-width: 100%;
      border-radius: 0.375rem;
      border-width: 1px;
      border-color: #f3f4f6;

      @media screen and (min-width: 640px) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
      }

      @media screen and (min-width: 1024px) {
            padding-left: 2rem;
            padding-right: 2rem;
      }
`;

export const DashboardTableBody = styled.div`
      overflow: hidden;
      border-radius: 0.375rem;
      border-bottom-width: 1px;
      border-color: #f3f4f6;
`;

export const DashboardTableDetail = styled.table`
      min-width: 100%;
      border-width: 1px;
      border-color: #f3f4f6;
      border-top-width: 1px;
      border-color: #e5e7eb;
      table-layout: auto;

      thead {
            background-color: #f2f2f2;
            th {
                  text-align: center;

                  &:first-child {
                        text-align: left;
                  }
            }
      }

      tbody {
            > :not([hidden]) ~ :not([hidden]) {
                  border-color: rgb(229 231 235);
            }

            > :not([hidden]) ~ :not([hidden]) {
                  --tw-divide-y-reverse: 0;
                  border-top-width: calc(
                        1px * calc(1 - var(--tw-divide-y-reverse))
                  );
                  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
            }
      }
`;

export const DashboardTableHeader = styled.th`
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      color: #374151;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 600;
      letter-spacing: 0.05em;
      text-transform: uppercase;
`;

export const DashboardTableData = styled.td`
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      line-height: 1.5rem;
      white-space: nowrap;
      font-weight: ${(props) => props.weight || '500'};
      text-align: ${(props) => props.align || 'center'};
`;
