import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ShowroomContainer = styled.div`
      margin: 3rem 0;
`;

export const ShowroomTitle = styled.div`
      font-size: 24px;
      letter-spacing: 1px;
      font-weight: 600;
      margin-bottom: 10px;
`;

export const ShowroomImage = styled.div`
      width: 100%;
      overflow: hidden;
      height: 100%;

      img {
            width: 100%;
            transition: all 0.3s ease-in-out;
      }
`;

export const ShowroomSummary = styled.div`
      letter-spacing: 1px;
      font-size: 16px;
      margin-top: 10px;
`;

export const ShowroomItem = styled(Link)`
      display: inline-block;
      color: #575757;
      transition: all 0.3s ease-in-out;
      text-decoration: none;

      &:hover {
            ${ShowroomTitle} {
                  color: ${(props) => props.theme.primaryColor};
            }

            ${ShowroomSummary} {
                  color: #575757;
            }

            ${ShowroomImage} {
                  img {
                        transform: scale(1.1);
                        transition: transform 0.28s ease 0s;
                  }
            }
      }
`;
