import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';

import 'swiper/css';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom';
import { Navigation } from 'swiper';
import axios from 'axios';
import { AboutRow, AboutShowroomItem } from '../../../../styles/frontend/about';
import {
      CategorySliderItem,
      ProductSlider
} from '../../../../styles/frontend/home';

const HomeCategoryList = () => {
      const [loading, setLoading] = useState('');
      const [categories, setCategories] = useState([]);

      useEffect(() => {
            const loadData = async () => {
                  setLoading(true);
                  await axios(
                        `${process.env.REACT_APP_SECRET_KEY}/api/categories/homepage/list`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        }
                  )
                        .then((response) => {
                              setCategories(response.data.categories);
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
                  setLoading(false);
            };
            loadData();
      }, []);

      return (
            <>
                  <Container fluid className="p-0">
                        <Container>
                              <AboutRow className="g-0 pb-5">
                                    <Col lg={6}>
                                          <Row className="justify-content-start g-0">
                                                <Col
                                                      lg={9}
                                                      className="pt-5 pb-3"
                                                >
                                                      <AboutShowroomItem>
                                                            <h2>
                                                                  Explore Our
                                                                  Category
                                                            </h2>
                                                            <p>
                                                                  Bathnroom
                                                                  brings to you
                                                                  premium wall
                                                                  tiles that are
                                                                  detailed with
                                                                  perfection.
                                                                  Each tile is
                                                                  made using
                                                                  state of the
                                                                  art technology
                                                                  making them
                                                                  strong,
                                                                  durable & easy
                                                                  to maintain.
                                                            </p>
                                                      </AboutShowroomItem>
                                                </Col>
                                          </Row>
                                    </Col>
                                    <Col lg={6}>
                                          <ProductSlider
                                                loop={false}
                                                slidesPerView={1}
                                                spaceBetween={0}
                                                modules={[Navigation]}
                                          >
                                                {!loading ? (
                                                      categories.length > 0 ? (
                                                            categories.map(
                                                                  (
                                                                        category,
                                                                        index
                                                                  ) => (
                                                                        <CategorySliderItem
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <Row className="p-0 g-0">
                                                                                    {category.map(
                                                                                          (
                                                                                                item,
                                                                                                key
                                                                                          ) => (
                                                                                                <Col
                                                                                                      lg={
                                                                                                            4
                                                                                                      }
                                                                                                      className="col-4"
                                                                                                      md={
                                                                                                            4
                                                                                                      }
                                                                                                      key={
                                                                                                            key
                                                                                                      }
                                                                                                >
                                                                                                      <Link
                                                                                                            className="d-block"
                                                                                                            to={`/products/categories/${item.slug}`}
                                                                                                      >
                                                                                                            <img
                                                                                                                  src={
                                                                                                                        item.icons
                                                                                                                  }
                                                                                                                  alt={
                                                                                                                        item.title
                                                                                                                  }
                                                                                                                  width="100%"
                                                                                                                  height="100%"
                                                                                                            />
                                                                                                            {
                                                                                                                  item.title
                                                                                                            }
                                                                                                      </Link>
                                                                                                </Col>
                                                                                          )
                                                                                    )}
                                                                              </Row>
                                                                        </CategorySliderItem>
                                                                  )
                                                            )
                                                      ) : (
                                                            <>Loading</>
                                                      )
                                                ) : (
                                                      <>
                                                            <Spinner />
                                                      </>
                                                )}
                                          </ProductSlider>
                                    </Col>
                              </AboutRow>
                        </Container>
                  </Container>
            </>
      );
};

export default HomeCategoryList;
