import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/common/breadcrumb';
import axios from 'axios';
import { Col, Container, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import Progress from 'react-progress-2';
import {
      CategoryListFilter,
      CategoryListItem,
      CategoryListRemove,
      CategoryListRemoveIcon,
      DesktopFilter,
      FilterContainer,
      MobileFilter,
      MobileFilterBtn,
      MobileFilterIcon
} from '../../styles/frontend/category';

import { toast } from 'react-hot-toast';
import 'overlayscrollbars/overlayscrollbars.css';
import CategoryDesktopFilter from './CategoryDesktopFilter';
import ProductSection from './ProductSection';
import CategoryMobileFilter from './CategoryMobileFilter';
import { Helmet } from 'react-helmet';

const CategoryPage = () => {
      const { categoryId } = useParams();
      const [category, setCategory] = useState([]);
      const [products, setProducts] = useState([]);
      const [loading, setLoading] = useState(false);
      const [totalProducts, setTotalProducts] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const [loadingCategory, setLoadingCategory] = useState(false);
      const [categories, setCategories] = useState([]);
      const [brands, setBrands] = useState([]);
      const [colors, setColors] = useState([]);
      const [sizes, setSizes] = useState([]);

      const [sort, setSort] = useState('desc');
      const [min, setMin] = useState('');
      const [max, setMax] = useState('');

      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      const [selectedColors, setSelectedColors] = useState([]);
      const [selectedColorLists, setSelectedColorLists] = useState([]);

      const [selectedBrands, setSelectedBrands] = useState([]);
      const [selectedBrandLists, setSelectedBrandLists] = useState([]);

      const [selectedCuisines, setSelectedCuisines] = useState([]);
      const [selectedCategories, setSelectedCategories] = useState([]);

      const [selectedSizes, setSelectedSizes] = useState([]);
      const [selectedSizeLists, setSelectedSizeLists] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios(
                  `${process.env.REACT_APP_SECRET_KEY}/api/categories/${categoryId}`,
                  {
                        headers: {
                              apikey: process.env.REACT_APP_Api_Key
                        }
                  }
            )
                  .then((response) => {
                        setCategory(response.data.category);
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.perPages);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      const loadCategory = async () => {
            setLoadingCategory(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/filters/${categoryId}/categories`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        }
                  )
                  .then((response) => {
                        setCategories(response.data.categories);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/filters/${categoryId}/brands`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        }
                  )
                  .then((response) => {
                        setBrands(response.data.brands);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/filters/${categoryId}/colors`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        }
                  )
                  .then((response) => {
                        setColors(response.data.colors);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/filters/${categoryId}/sizes`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        }
                  )
                  .then((response) => {
                        setSizes(response.data.sizes);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoadingCategory(false);
      };

      useEffect(() => {
            loadData();
            loadCategory();
      }, [categoryId]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            Progress.show();
            setLoading(true);

            try {
                  const filters = []; // Define your filter parameters here
                  if (selectedBrands.length) {
                        filters.push(`brands=${selectedBrands.join(',')}`);
                  }
                  if (selectedCuisines.length) {
                        filters.push(
                              `categories=${selectedCuisines.join(',')}`
                        );
                  }
                  if (selectedColors.length) {
                        filters.push(`colors=${selectedColors.join(',')}`);
                  }
                  if (selectedSizes.length) {
                        filters.push(`sizes=${selectedSizes.join(',')}`);
                  }
                  const filterQueryString =
                        filters.length > 0 ? `&${filters.join('&')}` : '';
                  const url = `${process.env.REACT_APP_SECRET_KEY}/api/categories/${categoryId}?${filterQueryString}&page=${pageNumber}&sort=${sort}&min=${min}&max=${max}`;
                  console.log(selectedColors);
                  const response = await axios
                        .get(url, {
                              headers: {
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });

                  setProducts(response.data.products);
                  setTotalProducts(response.data.totalProducts);
                  setPerPages(response.data.itemsCountPerPage);
                  setCurrentPage(response.data.currentPage);

                  Progress.hide();
                  setLoading(false);
            } catch (error) {
                  console.log(error.message);
                  Progress.hide();
                  setLoading(false);
            }
      }

      const handleChangeCategory = (id, type) => {
            const updatedCategories = categories.map((item) => {
                  if (type === 'category') {
                        return item.id === id
                              ? { ...item, checked: !item.checked }
                              : item;
                  } else if (type === 'subCategory') {
                        const updatedSubCategory = item.subCategory.map(
                              (subCategory) => {
                                    return subCategory.id === id
                                          ? {
                                                  ...subCategory,
                                                  checked: !subCategory.checked
                                            }
                                          : subCategory;
                              }
                        );
                        return { ...item, subCategory: updatedSubCategory };
                  } else if (type === 'childCategory') {
                        const updatedSubCategory = item.subCategory.map(
                              (subCategory) => {
                                    const updatedChildCategory =
                                          subCategory.childCategory.map(
                                                (childCategory) => {
                                                      return childCategory.id ===
                                                            id
                                                            ? {
                                                                    ...childCategory,
                                                                    checked: !childCategory.checked
                                                              }
                                                            : childCategory;
                                                }
                                          );
                                    return {
                                          ...subCategory,
                                          childCategory: updatedChildCategory
                                    };
                              }
                        );
                        return { ...item, subCategory: updatedSubCategory };
                  }
                  return item;
            });

            setCategories(updatedCategories);
      };

      const handleChangeBrand = (id, type) => {
            const brandsStateList = brands;
            const changeCheckedBrand = brandsStateList.map((item) => {
                  if (type === 'brand') {
                        return item.id === id
                              ? { ...item, checked: !item.checked }
                              : item;
                  }
                  return item;
            });
            setBrands(changeCheckedBrand);
      };

      const handleChangeColor = (id, type) => {
            const colorsStateList = colors;
            const changeCheckedColor = colorsStateList.map((item) => {
                  if (type === 'color') {
                        return item.id === id
                              ? { ...item, checked: !item.checked }
                              : item;
                  }
                  return item;
            });
            setColors(changeCheckedColor);
      };

      const handleChangeSize = (id, type) => {
            const sizesStateList = sizes;
            const changeCheckedSize = sizesStateList.map((item) => {
                  if (type === 'size') {
                        return item.id === id
                              ? { ...item, checked: !item.checked }
                              : item;
                  }
                  return item;
            });
            setSizes(changeCheckedSize);
      };

      const changePrice = async () => {
            const filters = [];

            const brandsChecked = brands
                  .filter((item) => item.checked)
                  .map((item) => ({
                        id: item.id,
                        title: item.title,
                        type: 'brand'
                  }));

            const selectedBrandIds = brandsChecked.map((item) => item.id);
            setSelectedBrands(selectedBrandIds);
            setSelectedBrandLists(brandsChecked);

            if (selectedBrandIds.length) {
                  filters.push(`brands=${selectedBrandIds.join(',')}`);
            }

            const colorsChecked = colors
                  .filter((item) => item.checked)
                  .map((item) => ({
                        id: item.id,
                        title: item.title,
                        subtitle: item.subtitle,
                        type: 'color'
                  }));

            const selectedColorIds = colorsChecked.map((item) => item.subtitle);
            setSelectedColors(selectedColorIds);
            setSelectedColorLists(colorsChecked);

            if (selectedColorIds.length) {
                  filters.push(`colors=${selectedColorIds.join(',')}`);
            }

            const sizesChecked = sizes
                  .filter((item) => item.checked)
                  .map((item) => ({
                        id: item.id,
                        title: item.title,
                        subtitle: item.subtitle,
                        type: 'size'
                  }));

            const selectedSizeIds = sizesChecked.map((item) => item.subtitle);
            setSelectedSizes(selectedSizeIds);
            setSelectedSizeLists(sizesChecked);

            if (selectedSizeIds.length) {
                  filters.push(`sizes=${selectedSizeIds.join(',')}`);
            }

            const cuisinesChecked = categories
                  .filter((item) => item.checked)
                  .map((item) => ({
                        id: item.id,
                        title: item.title,
                        type: 'category'
                  }));

            const subCategoriesChecked = categories
                  .flatMap((item) =>
                        item.subCategory.filter((subItem) => subItem.checked)
                  )
                  .map((subItem) => ({
                        id: subItem.id,
                        title: subItem.title,
                        type: 'subCategory'
                  }));

            const childCategoriesChecked = categories
                  .flatMap((item) =>
                        item.subCategory.flatMap((subItem) =>
                              subItem.childCategory.filter(
                                    (childItem) => childItem.checked
                              )
                        )
                  )
                  .map((childItem) => ({
                        id: childItem.id,
                        title: childItem.title,
                        type: 'childCategory'
                  }));

            const allCheckedIds = [
                  ...cuisinesChecked,
                  ...subCategoriesChecked,
                  ...childCategoriesChecked
            ];

            const selectedCuisinesIds = allCheckedIds.map((item) => item.id);
            setSelectedCuisines(selectedCuisinesIds);
            setSelectedCategories(allCheckedIds);

            if (allCheckedIds.length) {
                  filters.push(`categories=${selectedCuisinesIds.join(',')}`);
            }
            const apiUrl = `${
                  process.env.REACT_APP_SECRET_KEY
            }/api/categories/${categoryId}${
                  filters.length > 0 ? '?' + filters.join('&') : '?'
            }${sort ? `&sort=${sort}` : ''}${
                  max > 0 ? `&min=${min}&max=${max}` : ``
            }`;
            console.log(apiUrl);

            await axios
                  .get(apiUrl, {
                        headers: {
                              apikey: process.env.REACT_APP_Api_Key
                        }
                  })
                  .then((response) => {
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      };

      useEffect(() => {
            changePrice();
      }, [brands, categories, sizes, colors, sort]);

      return (
            <>
                  {!loading && (
                        <Helmet>
                              <title>
                                    {category.seo_title || category.title}
                              </title>
                              {category.seo_description && (
                                    <meta
                                          name="description"
                                          content={category.seo_description}
                                    />
                              )}
                              {category.seo_keyword && (
                                    <meta
                                          name="keyword"
                                          content={category.seo_keyword}
                                    />
                              )}
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  )}
                  <Breadcrumb
                        otherLink="/products"
                        otherTitle="Products"
                        title={category.title}
                        back="/products"
                  />

                  <div
                        className="product__list py-4 "
                        style={{ backgroundColor: '#f2f2f2' }}
                  >
                        <Container fluid>
                              <Row>
                                    <DesktopFilter className="col-md-3">
                                          <CategoryDesktopFilter
                                                min={min}
                                                max={max}
                                                setMax={setMax}
                                                setMin={setMin}
                                                sizes={sizes}
                                                colors={colors}
                                                loading={loading}
                                                loadingCategory={
                                                      loadingCategory
                                                }
                                                brands={brands}
                                                categories={categories}
                                                handleChangeBrand={
                                                      handleChangeBrand
                                                }
                                                handleChangeColor={
                                                      handleChangeColor
                                                }
                                                handleChangeSize={
                                                      handleChangeSize
                                                }
                                                handleChangeCategory={
                                                      handleChangeCategory
                                                }
                                                changePrice={changePrice}
                                          />
                                    </DesktopFilter>
                                    <Col lg={9}>
                                          <FilterContainer>
                                                <CategoryMobileFilter
                                                      show={show}
                                                      handleClose={handleClose}
                                                      handleShow={handleShow}
                                                >
                                                      <CategoryDesktopFilter
                                                            min={min}
                                                            max={max}
                                                            setMax={setMax}
                                                            setMin={setMin}
                                                            sizes={sizes}
                                                            colors={colors}
                                                            loadingCategory={
                                                                  loadingCategory
                                                            }
                                                            brands={brands}
                                                            categories={
                                                                  categories
                                                            }
                                                            handleChangeBrand={
                                                                  handleChangeBrand
                                                            }
                                                            handleChangeColor={
                                                                  handleChangeColor
                                                            }
                                                            handleChangeSize={
                                                                  handleChangeSize
                                                            }
                                                            handleChangeCategory={
                                                                  handleChangeCategory
                                                            }
                                                            changePrice={
                                                                  changePrice
                                                            }
                                                      />
                                                </CategoryMobileFilter>
                                                <DesktopFilter>
                                                      {totalProducts} products
                                                      found{' '}
                                                      {category.title && (
                                                            <>
                                                                  on{' '}
                                                                  {
                                                                        category.title
                                                                  }
                                                            </>
                                                      )}
                                                </DesktopFilter>
                                                <div>
                                                      <select
                                                            className="form-select"
                                                            onChange={(event) =>
                                                                  setSort(
                                                                        event
                                                                              .target
                                                                              .value
                                                                  )
                                                            }
                                                      >
                                                            <option value="">
                                                                  Filter By
                                                            </option>
                                                            <option value="asc">
                                                                  Price low to
                                                                  high
                                                            </option>
                                                            <option value="desc">
                                                                  Price high to
                                                                  low
                                                            </option>
                                                      </select>
                                                </div>
                                          </FilterContainer>
                                          <div>
                                                <MobileFilter className="mb-3">
                                                      {totalProducts} products
                                                      found{' '}
                                                      {category.title && (
                                                            <>
                                                                  on{' '}
                                                                  {
                                                                        category.title
                                                                  }
                                                            </>
                                                      )}
                                                </MobileFilter>
                                                <CategoryListFilter>
                                                      {selectedSizeLists.length >
                                                            0 &&
                                                            selectedSizeLists.map(
                                                                  (
                                                                        size,
                                                                        index
                                                                  ) => (
                                                                        <li
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <CategoryListItem>
                                                                                    {
                                                                                          size.title
                                                                                    }
                                                                                    <CategoryListRemove
                                                                                          onClick={() =>
                                                                                                handleChangeSize(
                                                                                                      size.id,
                                                                                                      size.type
                                                                                                )
                                                                                          }
                                                                                    >
                                                                                          <CategoryListRemoveIcon>
                                                                                                <i className="bx bx-x"></i>
                                                                                          </CategoryListRemoveIcon>
                                                                                    </CategoryListRemove>
                                                                              </CategoryListItem>
                                                                        </li>
                                                                  )
                                                            )}
                                                      {selectedColorLists.length >
                                                            0 &&
                                                            selectedColorLists.map(
                                                                  (
                                                                        color,
                                                                        index
                                                                  ) => (
                                                                        <li
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <CategoryListItem>
                                                                                    {
                                                                                          color.title
                                                                                    }
                                                                                    <CategoryListRemove
                                                                                          onClick={() =>
                                                                                                handleChangeColor(
                                                                                                      color.id,
                                                                                                      color.type
                                                                                                )
                                                                                          }
                                                                                    >
                                                                                          <CategoryListRemoveIcon>
                                                                                                <i className="bx bx-x"></i>
                                                                                          </CategoryListRemoveIcon>
                                                                                    </CategoryListRemove>
                                                                              </CategoryListItem>
                                                                        </li>
                                                                  )
                                                            )}
                                                      {selectedBrandLists.length >
                                                            0 &&
                                                            selectedBrandLists.map(
                                                                  (
                                                                        brand,
                                                                        index
                                                                  ) => (
                                                                        <li
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <CategoryListItem>
                                                                                    {
                                                                                          brand.title
                                                                                    }
                                                                                    <CategoryListRemove
                                                                                          onClick={() =>
                                                                                                handleChangeBrand(
                                                                                                      brand.id,
                                                                                                      brand.type
                                                                                                )
                                                                                          }
                                                                                    >
                                                                                          <CategoryListRemoveIcon>
                                                                                                <i className="bx bx-x"></i>
                                                                                          </CategoryListRemoveIcon>
                                                                                    </CategoryListRemove>
                                                                              </CategoryListItem>
                                                                        </li>
                                                                  )
                                                            )}
                                                      {selectedCategories.length >
                                                            0 &&
                                                            selectedCategories.map(
                                                                  (
                                                                        item,
                                                                        index
                                                                  ) => (
                                                                        <li
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <CategoryListItem>
                                                                                    {
                                                                                          item.title
                                                                                    }
                                                                                    <CategoryListRemove
                                                                                          onClick={() =>
                                                                                                handleChangeCategory(
                                                                                                      item.id,
                                                                                                      item.type
                                                                                                )
                                                                                          }
                                                                                    >
                                                                                          <CategoryListRemoveIcon>
                                                                                                <i className="bx bx-x"></i>
                                                                                          </CategoryListRemoveIcon>
                                                                                    </CategoryListRemove>
                                                                              </CategoryListItem>
                                                                        </li>
                                                                  )
                                                            )}
                                                </CategoryListFilter>
                                          </div>

                                          <ProductSection
                                                loading={loading}
                                                products={products}
                                          />

                                          {totalProducts > 20 && (
                                                <div className="mt-4 float-end">
                                                      <Pagination
                                                            activePage={
                                                                  currentPage
                                                            }
                                                            totalItemsCount={
                                                                  totalProducts
                                                            }
                                                            itemsCountPerPage={
                                                                  perPages
                                                            }
                                                            onChange={(
                                                                  pageNumber
                                                            ) =>
                                                                  getData(
                                                                        pageNumber
                                                                  )
                                                            }
                                                            itemClass="page-item"
                                                            linkClass="page-link"
                                                            firstPageText="First"
                                                            lastPageText="Last"
                                                      />
                                                </div>
                                          )}
                                    </Col>
                              </Row>
                        </Container>
                  </div>
            </>
      );
};

export default CategoryPage;
