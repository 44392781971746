import React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { ProductImage } from '../../styles/frontend/category';
import { Link } from 'react-router-dom';
import { ProductItem } from '../../components/frontend/product/style';

const ProductSection = ({ products, loading }) => {
      return (
            <>
                  <Row className="g-4">
                        {!loading ? (
                              products.length > 0 ? (
                                    products.map((product, index) => (
                                          <Col
                                                lg={3}
                                                md={4}
                                                className="col-6"
                                                key={index}
                                          >
                                                <ProductItem>
                                                      <div className="product__container">
                                                            <ProductImage>
                                                                  <Link
                                                                        to={`/products/${product.slug}`}
                                                                  >
                                                                        <img
                                                                              src={
                                                                                    product.image
                                                                              }
                                                                              className="img-fluid"
                                                                              alt={
                                                                                    product.title
                                                                              }
                                                                              width="100%"
                                                                              height="100%"
                                                                        />
                                                                  </Link>
                                                            </ProductImage>
                                                            <div className="product__content d-flex justify-content-between">
                                                                  <div className="product__info">
                                                                        <div className="product__title">
                                                                              <Link
                                                                                    to={`/products/${product.slug}`}
                                                                              >
                                                                                    <h4>
                                                                                          {
                                                                                                product.title
                                                                                          }
                                                                                    </h4>
                                                                              </Link>
                                                                        </div>
                                                                        <div className="product__price">
                                                                              Rs:{' '}
                                                                              {
                                                                                    product.price
                                                                              }
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </ProductItem>
                                          </Col>
                                    ))
                              ) : (
                                    <>
                                          <div className="w-100 my-5 py-5 text-center fs-3">
                                                No product found
                                          </div>
                                    </>
                              )
                        ) : (
                              <>
                                    <Col
                                          lg={12}
                                          className="text-center my-5 py-5"
                                    >
                                          <Spinner />
                                    </Col>
                              </>
                        )}
                  </Row>
            </>
      );
};

export default ProductSection;
