import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { Swiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { Autoplay, EffectFade } from 'swiper';
import {
      AboutRow,
      AboutShowroomItem,
      AboutSliderItem
} from '../../../../styles/frontend/about';
import { WhyCol } from './style';

const WhyUs = () => {
      return (
            <>
                  <Container fluid className="p-0">
                        <AboutRow padding="0 15px" className="g-0 my-5">
                              <WhyCol order={2} lg={6}>
                                    <Row className="justify-content-end g-0">
                                          <Col lg={9} className="pt-5 pb-5">
                                                <AboutShowroomItem>
                                                      <h2>
                                                            UPGRADE YOUR SPACE
                                                            LIVE THE UBER LIFE
                                                      </h2>
                                                      <p>
                                                            It's now easier than
                                                            ever to give your
                                                            space a makeover you
                                                            deserve for. Visit
                                                            your nearest
                                                            Bathnroom World or
                                                            Orientation Center
                                                            or Authorized Dealer
                                                            to know more to
                                                            choose from the wide
                                                            range of complete
                                                            bathroom and
                                                            lighting solutions.
                                                      </p>
                                                </AboutShowroomItem>
                                          </Col>
                                    </Row>
                              </WhyCol>
                              <WhyCol order={1} lg={6}>
                                    <Swiper
                                          loop={true}
                                          autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false
                                          }}
                                          effect={'fade'}
                                          modules={[Autoplay, EffectFade]}
                                    >
                                          <AboutSliderItem>
                                                <img
                                                      src="/images/sliders/01.jpg"
                                                      alt="UPGRADE YOUR SPACE
                                                      LIVE THE UBER LIFE"
                                                      width="100%"
                                                      height="100%"
                                                />
                                          </AboutSliderItem>
                                          <AboutSliderItem>
                                                <img
                                                      src="/images/sliders/02.jpg"
                                                      alt="UPGRADE YOUR SPACE
                                                      LIVE THE UBER LIFE"
                                                      width="100%"
                                                      height="100%"
                                                />
                                          </AboutSliderItem>
                                    </Swiper>
                              </WhyCol>
                        </AboutRow>
                  </Container>
            </>
      );
};

export default WhyUs;
