import React from 'react';

const HeartIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">favorite</span>
            </>
      );
};

export default HeartIcon;
