import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Swiper } from 'swiper/react';

// Blog Banner Slider
export const BlogSlider = styled(Swiper)`
      border-radius: 10px;
`;

export const BlogContainer = styled(Container)`
      padding: 2rem 0 3rem;

      @media screen and (max-width: 600px) {
            padding: 15px 15px 3rem;
      }
`;

export const BlogSliderItem = styled(Link)`
      position: relative;

      img {
            border-radius: 10px;
      }

      &::before {
            content: '';
            background: linear-gradient(
                  to bottom,
                  transparent,
                  rgba(0, 0, 0, 0.6)
            );
            height: 100%;
            width: 100%;
            position: absolute;
            border-radius: 10px;
      }

      span {
            font-size: 48px;
            position: absolute;
            bottom: 20px;
            left: 0;
            color: ${(props) => props.theme.white};
            padding-left: 20px;
      }

      @media screen and (max-width: 600px) {
            span {
                  font-size: 18px;
            }
      }
`;

// Blogs
export const ExporeMore = styled.div`
      margin-top: 80px;
      text-align: center;

      h4 {
            color: ${(props) => props.theme.primaryColor};
            font-size: 32px;
            margin-bottom: 40px;
      }

      @media screen and (max-width: 600px) {
            margin-top: 1.5rem;

            h4 {
                  margin-bottom: 0.5rem;
                  font-size: 18px;
            }
      }
`;

export const BlogItem = styled.div`
      position: relative;

      a {
            display: block;

            &.blog__image {
                  height: 240px;
            }

            img {
                  width: 100%;
                  height: 100%;
                  border-radius: 10px;
                  object-fit: cover;
            }
      }
`;

export const BlogItemDetail = styled.div`
      margin-top: 20px;

      @media screen and (max-width: 600px) {
            margin-top: 10px;
      }
`;

export const BlogItemTitle = styled.h2`
      font-size: 20px;
      font-weight: 600 !important;
      line-height: 1.2;

      a {
            span {
                  color: #1b1b1b;
            }
      }

      @media screen and (max-width: 600px) {
            font-size: 16px;
      }
`;

export const BlogItemDate = styled.div`
      margin-top: 10px;

      span {
            font-size: 16px;
            color: #999999;
            font-weight: 400;
      }
`;

export const BlogItemTag = styled.div`
      margin-bottom: 10px;

      span {
            font-size: 14px;
            font-weight: 300;
      }
`;

export const SocialMediaContainer = styled.div`
      background-color: #f2f2f2;
      margin: 0;
      padding: 3rem 0 2rem;

      @media screen and (max-width: 600px) {
            padding: 2rem 0 2rem;
            
      }
`;

export const SocialMedia = styled.div`
      margin: 0;

      ul {
            padding-left: 0;
            display: flex;
            gap: 24px;
            margin: 0;

            a {
                  display: flex;
                  gap: 10px;
                  align-items: center;

                  i {
                        font-size: 24px;
                        padding-left: 8px;
                        line-height: 1;
                        color: white;
                  }
            }
      }

      @media screen and (max-width: 600px) {
            ul {
                  display: block;

                  li {
                        &:first-child {
                              display: block;
                        }

                        display: inline-block;
                        padding-right: 10px;
                        margin-bottom: 10px;
                  }
            }
      }
`;

export const SocialMediaLink = styled(Link)`
      background-color: ${(props) => props.primaryColor};
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      div:first-child {
            padding-left: ${(props) => (props.left ? props.left : '4px')};
            padding-right: ${(props) => (props.right ? props.right : '2px')};
            height: 24px;
      }

      div:last-child {
            background-color: ${(props) => props.secondaryColor};
            color: white;
            padding: 8px 16px;
            padding-right: 25px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;

            &:hover {
                  background-color: ${(props) => props.primaryColor} !important;
            }
      }
`;

// Blog Details

export const BlogImage = styled.div`
      img {
            border-radius: 10px;
            width: 100%;
            height: 100%;
            object-fit: contain;
      }
`;

export const BlogContent = styled.div`
      margin-top: 30px;
      background-color: #f2f2f2;
      padding: 20px;
      border-radius: 5px;
`;

export const BlogTitle = styled.h1`
      font-size: 32px;
      line-height: 1.2;
      margin-bottom: 10px;
`;

export const BlogDescription = styled.div`
      p {
            margin: 0;
            font-size: 18px;
            color: #636161;
      }

      ul {
            list-style-type: disc;
      }

      img {
            width: 100%;
            height: 100%;
            object-fit: contain;
      }
`;

export const BlogRelated = styled.div`
      margin-top: 30px;

      ul {
            padding-left: 0;

            a {
                  display: flex;
                  align-items: center;
                  gap: 20px;
                  color: ${(props) => props.theme.linkColor};
                  font-size: 18px;

                  div:first-child {
                        padding: 2px 16px;
                        color: white;
                        font-weight: 500;
                        background-color: ${(props) => props.theme.black};
                        font-size: 24px;
                  }
            }
      }
`;
