import { Container, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const ContactContainer = styled(Container)`
      margin-top: 3rem;
      padding: 0;

      @media screen and (max-width: 600px) {
            margin-top: 0rem;
      }
`;

export const ContactRow = styled(Row)`
      justify-content: end;
      gap: 0;

      @media screen and (max-width: 600px) {
            padding: 0 15px;

            .col-lg-7 {
                  display: none;
            }
      }
`;

export const ContactSlider = styled(Swiper)`
      border-bottom-right-radius: 50px;

      img {
            border-bottom-right-radius: 50px;
      }
`;

export const ContactFormContainer = styled(Form).attrs(() => ({
      className: 'row g-0 justify-content-center'
}))`
      .form-label {
            font-size: 17px;
            letter-spacing: 1px;
            font-weight: 600;
      }
      .form-control {
            border-radius: 0;
      }

      button {
            width: 100%;
            border: 1px solid ${(props) => props.theme.primaryColor};
            padding: 5px 60px;
            font-size: 18px;
            background-color: ${(props) => props.theme.primaryColor};
            color: white;
            transition: background-color 0.3s;
            border-radius: 5px;
            border: 0;

            &:hover {
                  background-color: ${(props) => props.theme.secondaryColor};
                  color: white;
                  border: 0;
            }
      }
`;
