import styled from 'styled-components';

export const Button = styled.button`
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: auto;
      border: 1px solid ${(props) => props.theme.primaryColor};
      padding: 10px 60px;
      font-size: 18px;
      text-transform: uppercase;
      background-color: ${(props) => props.theme.primaryColor};
      color: white;
      transition: background-color 0.3s;
      gap: 10px;
      letter-spacing: 1px;

      &:hover {
            background-color: ${(props) => props.theme.secondaryColor};
            color: white;
      }
`;
