import React, { useContext, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import {
      HeaderNavContainer,
      MenuContainer,
      MenuCustom,
      MenuWrapper
} from '../../../styles/layouts/header';
import { useEffect } from 'react';
import {
      HeaderContent,
      HeaderImageLogo,
      HeaderLogin,
      HomeHeaderContainer,
      ImageLogo,
      MobileMenuBox,
      WhiteImageLogo
} from './style';
import { UserIcon } from '../../icons';
import CartContext from '../../../stores/CartContext';

const NavBar = ({ handleShow, handleSearchShow }) => {
      const [isSticky, setIsSticky] = useState(false);
      useEffect(() => {
            const handleScroll = () => {
                  const scrollTop = window.pageYOffset;
                  setIsSticky(scrollTop > 200);
            };
            window.addEventListener('scroll', handleScroll);
            return () => {
                  window.removeEventListener('scroll', handleScroll);
            };
      }, []);

      const cartCtx = useContext(CartContext);

      return (
            <>
                  <HomeHeaderContainer
                        className={`${isSticky ? 'sticky-home' : ''}`}
                  >
                        <HeaderContent fluid sticky={isSticky}>
                              <HeaderImageLogo>
                                    <a href="/" className="d-inline-block">
                                          <WhiteImageLogo
                                                src={
                                                      isSticky
                                                            ? '/images/png/logo.jpg'
                                                            : '/images/png/logo.jpg'
                                                }
                                                alt="Bathnroom Official Logo"
                                                className="img-fluid"
                                                height="100%"
                                                width="100%"
                                          />
                                          <ImageLogo
                                                src={'/images/png/logo.jpg'}
                                                alt="Bathnroom Official Logo"
                                                height="100%"
                                                width="100%"
                                          />
                                    </a>
                              </HeaderImageLogo>
                              <HeaderNavContainer className="header__nav">
                                    <nav className="d-flex ">
                                          <ul className="list-inline d-flex align-items-center mb-0">
                                                <li className="list-inline-item text-uppercase dropdown__container">
                                                      <NavLink
                                                            to="/"
                                                            className="active"
                                                      >
                                                            about
                                                      </NavLink>
                                                      {/* <i className="bx bx-chevron-down text-white"></i> */}
                                                      <div className="dropdown__wrapper">
                                                            <div className="dropdown__content">
                                                                  <Link to="/who-we-are">
                                                                        Who We
                                                                        Are
                                                                  </Link>
                                                            </div>
                                                            <div className="dropdown__content">
                                                                  <Link to="/terms-and-conditions">
                                                                        Company
                                                                        Policy
                                                                  </Link>
                                                            </div>
                                                      </div>
                                                </li>
                                                <li className="list-inline-item text-uppercase">
                                                      <NavLink to="/products">
                                                            Product
                                                      </NavLink>
                                                </li>
                                                <li className="list-inline-item text-uppercase">
                                                      <NavLink to="/catalogues">
                                                            Catalogue
                                                      </NavLink>
                                                </li>
                                                <li className="list-inline-item text-uppercase">
                                                      <NavLink to="/showrooms">
                                                            showroom
                                                      </NavLink>
                                                </li>
                                                <li className="list-inline-item text-uppercase">
                                                      <NavLink to="/contact-us">
                                                            contact us
                                                      </NavLink>
                                                </li>
                                                <HeaderLogin>
                                                      <li>
                                                            <Link
                                                                  to="#"
                                                                  onClick={
                                                                        handleSearchShow
                                                                  }
                                                                  className="py-0"
                                                            >
                                                                  <span
                                                                        className="material-symbols-outlined"
                                                                        style={{
                                                                              lineHeight: 2
                                                                        }}
                                                                  >
                                                                        search
                                                                  </span>
                                                            </Link>
                                                      </li>
                                                      <li>
                                                            <Link
                                                                  to="/carts"
                                                                  className="px-1 py-0"
                                                            >
                                                                  <span
                                                                        className="material-symbols-outlined"
                                                                        style={{
                                                                              lineHeight: 2
                                                                        }}
                                                                  >
                                                                        shopping_cart
                                                                  </span>
                                                                  (
                                                                  {
                                                                        cartCtx.totalCart
                                                                  }
                                                                  )
                                                            </Link>
                                                      </li>
                                                      <li>
                                                            <Link
                                                                  to="/login"
                                                                  className="pe-0 py-0"
                                                            >
                                                                  <UserIcon />
                                                            </Link>
                                                      </li>
                                                </HeaderLogin>
                                                <li className="list-inline-item ">
                                                      <Button
                                                            variant="btn"
                                                            onClick={handleShow}
                                                            aria-label="Menu"
                                                      >
                                                            <div className="menu__wrapper">
                                                                  <div className="menu__container">
                                                                        <div className="menu__container--icon custom-2">
                                                                              <span className="hover top"></span>
                                                                              <span className="hover bottom"></span>
                                                                              <span className="default top"></span>
                                                                              <span className="default bottom"></span>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </Button>
                                                </li>
                                          </ul>
                                    </nav>
                              </HeaderNavContainer>

                              <MobileMenuBox>
                                    <MenuWrapper onClick={handleShow}>
                                          <MenuContainer>
                                                <MenuCustom>
                                                      <span className="hover top"></span>
                                                      <span className="hover bottom"></span>
                                                      <span className="default top"></span>
                                                      <span className="default bottom"></span>
                                                </MenuCustom>
                                          </MenuContainer>
                                    </MenuWrapper>
                              </MobileMenuBox>
                        </HeaderContent>
                  </HomeHeaderContainer>
            </>
      );
};
export default NavBar;
