import React from 'react';

const DashboardIcon = () => {
      return (
            <>
                  <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        class="flex-shrink-0 h-4 w-4"
                        aria-hidden="true"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                  >
                        <rect
                              width="176"
                              height="176"
                              x="48"
                              y="48"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="32"
                              rx="20"
                              ry="20"
                        ></rect>
                        <rect
                              width="176"
                              height="176"
                              x="288"
                              y="48"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="32"
                              rx="20"
                              ry="20"
                        ></rect>
                        <rect
                              width="176"
                              height="176"
                              x="48"
                              y="288"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="32"
                              rx="20"
                              ry="20"
                        ></rect>
                        <rect
                              width="176"
                              height="176"
                              x="288"
                              y="288"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="32"
                              rx="20"
                              ry="20"
                        ></rect>
                  </svg>
            </>
      );
};

export default DashboardIcon;
