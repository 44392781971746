import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HomeCategoryBox = styled.div`
      padding-top: 3rem;

      h3 {
            font-size: 20px;
            margin: 0 0 30px 0;
            font-weight: 300;

            span {
                  color: ${(props) => props.theme.primaryColor};
                  font-size: 43px;
                  font-weight: 300;
            }
      }
`;

export const ProductContainer = styled(Container)`
      ${HomeCategoryBox}:last-child {
            padding-bottom: 3rem !important;
      }

      @media screen and (max-width: 600px) {
            padding-left: 15px;
            padding-right: 15px;
      }
`;

export const CategoryBox = styled(Link)`
      display: block;
      position: relative;

      h4 {
            font-weight: 300;
            padding: 10px;
            color: ${(props) => props.theme.white};
            text-align: center;
            position: absolute;
            bottom: 0;
            font-size: 24px;
            font-weight: 500;
            background-color: rgba(0, 0, 0, 0.4);
            width: 100%;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
      }
`;

export const CategoryBoxImage = styled.div`
      border-radius: 10px;
      height: 236px;
      background-size: cover;

      img {
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
            border-radius: 10px;
            width: 100%;
            height: 100%;
            object-fit: cover;
      }

      ${CategoryBox}:hover {
            border: 1px solid ${(props) => props.theme.primaryColor};

            img {
                  transform: scale(-2.1);
            }
      }
`;

export const BrandItem = styled.div`
      img {
            height: 120px;
            padding: 16px;
            object-fit: contain;
            /* height: 120px; */
            width: 100%;
      }

      .row {
            background-color: #f5f5f5;
            .col-lg-2 {
                  a {
                        background-color: white;
                        margin-bottom: 1.5rem;
                        border: 1px;
                        border-style: solid;
                        border-color: #ddd;
                        color: ${(props) => props.theme.black};
                        font-size: 16px;
                        padding-bottom: 10px;
                        display: block;
                        text-align: center;
                        transition: all 0.3s ease-in-out;

                        &:hover {
                              text-align: center;
                              border-color: white;
                        }

                        img {
                              object-fit: contain;
                        }
                  }
            }
      }
`;

export const BrandMoreButton = styled.div`
      display: flex;
      justify-content: center;
      margin-top: 1rem;

      button {
            padding: 8px 20px;
            text-align: center;
            display: block;
            color: ${(props) => props.theme.white};
            background-color: ${(props) => props.theme.primaryColor};

            &:hover {
                  background-color: ${(props) => props.theme.secondaryColor};
            }
      }
`;

export const ServiceBox = styled.div`
      background: ${(props) => props.image && props.image};
      padding: 3rem 0;
      margin: 3rem 0;

      a {
            color: ${(props) => props.theme.white};
            margin-top: 10px;
            display: block;
            text-transform: none;
            display: flex;
            align-items: center;
            gap: 20px;

            i {
                  font-size: 16px;
            }

            &:hover {
                  color: ${(props) => props.theme.primaryColor};
            }
      }
`;
