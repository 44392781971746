import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import OtherHeader from './OtherHeader';
import HomeHeader from '../../components/common/header';
import { Modal, Offcanvas } from 'react-bootstrap';
import MobileMenu from './MobileMenu';
import { SearchBtn, SearchForm, SearchInput, SearchModal } from './style';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const Header = () => {
      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      const [menu, setMenu] = useState(null);

      useEffect(() => {
            if (show) {
                  setMenu(<MobileMenu setShow={setShow} />);
            } else {
                  setMenu(null);
            }
      }, [show]);

      const [searchButton, setSearchButton] = useState(false);

      const handleSearchClose = () => setSearchButton(false);
      const handleSearchShow = () => setSearchButton(true);

      const [loading, setLoading] = useState(false);
      const [plugins, setPlugins] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/plugins`, {
                        headers: {
                              apikey: process.env.REACT_APP_Api_Key
                        }
                  })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPlugins(response.data.plugins);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  {!loading ? (
                        <>
                              <Helmet>
                                    {!loading &&
                                          plugins?.map((plugin) =>
                                                plugin.tag_type ===
                                                'Script Link' ? (
                                                      <script
                                                            async={true}
                                                            src={plugin.code}
                                                      />
                                                ) : null
                                          )}
                                    {plugins?.map((plugin) =>
                                          plugin.tag_type === 'Script' ? (
                                                <script type="text/javascript">
                                                      {plugin.code}
                                                </script>
                                          ) : null
                                    )}
                                    {plugins?.map((plugin) =>
                                          plugin.tag_type === 'Meta' ? (
                                                <meta
                                                      name={plugin.title}
                                                      content={plugin.code}
                                                />
                                          ) : null
                                    )}
                              </Helmet>
                        </>
                  ) : null}
                  {useLocation().pathname === '/' ? (
                        <>
                              <HomeHeader
                                    handleShow={handleShow}
                                    handleSearchShow={handleSearchShow}
                              />
                        </>
                  ) : (
                        <>
                              <OtherHeader
                                    handleShow={handleShow}
                                    handleSearchShow={handleSearchShow}
                              />
                        </>
                  )}
                  <SearchModal
                        show={searchButton}
                        centered
                        keyboard={false}
                        onHide={handleSearchClose}
                  >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                              <SearchForm action="/search">
                                    <SearchInput
                                          type="text"
                                          placeholder="Search product ..."
                                          className="form-control"
                                          name="query"
                                          autoFocus="query"
                                    />
                                    <SearchBtn>
                                          <i className="bx bx-search"></i>
                                    </SearchBtn>
                              </SearchForm>
                        </Modal.Body>
                  </SearchModal>
                  <Offcanvas
                        className="sidebar__menu"
                        show={show}
                        placement={'end'}
                        onHide={handleClose}
                  >
                        <Offcanvas.Header
                              closeButton
                              style={{
                                    backgroundImage:
                                          'linear-gradient(to right, rgb(0, 128, 128), rgb(111 105 105))'
                              }}
                        >
                              <Offcanvas.Title>
                                    <div className="header__canvas--image">
                                          <Link to="/">
                                                <img
                                                      src="/images/png/logo-white.png"
                                                      className="img-fluid"
                                                      alt="Bathnroom Official Logo"
                                                      width={150}
                                                      height={150}
                                                />
                                          </Link>
                                    </div>
                              </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>{menu}</Offcanvas.Body>
                  </Offcanvas>
            </>
      );
};
export default Header;
