import React, { useEffect, useState } from 'react';
import DashboardLayout from '../DashboardLayout';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
      DashboardBreadcrumb,
      DashboardContentGrid,
      DashboardContentTitle
} from './style';
import { Container } from 'react-bootstrap';
import DashboardCard from '../DashboardCard';
import {
      CompletedOrder,
      PendingOrder,
      ProcessingOrder,
      TotalOrder
} from '../../components/icons';
import DashboardTableList from '../DashboardTableList';

const Dashboard = () => {
      const [orders, setOrders] = useState([]);
      const [loading, setLoading] = useState(false);
      const [totalOrder, setTotalOrder] = useState(0);
      const [pendingOrder, setPendingOrder] = useState(0);
      const [processingOrder, setProcessingOrder] = useState(0);
      const [completedOrder, setCompletedOrder] = useState(0);
      const [canceledOrder, setCanceledOrder] = useState(0);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/customer/dashboard`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setOrders(response.data.orders);
                              setTotalOrder(response.data.totalOrders);
                              setCompletedOrder(response.data.completedOrders);
                              setPendingOrder(response.data.pendingOrders);
                              setProcessingOrder(
                                    response.data.processingOrders
                              );
                              setCanceledOrder(response.data.canceledOrders);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <DashboardBreadcrumb>
                        <Container>
                              <div className="page-breadcrumb">
                                    <Link to="/">Home</Link> {'>'} Dashboard
                              </div>
                        </Container>
                  </DashboardBreadcrumb>
                  <DashboardLayout>
                        <DashboardContentTitle>Dashboard</DashboardContentTitle>

                        <DashboardContentGrid>
                              <DashboardCard
                                    icon={<TotalOrder />}
                                    title="Total Order"
                                    total={
                                          !loading && totalOrder
                                                ? totalOrder
                                                : 0
                                    }
                              />
                              <DashboardCard
                                    icon={<PendingOrder />}
                                    title="Pending Order"
                                    total={
                                          !loading && pendingOrder
                                                ? pendingOrder
                                                : 0
                                    }
                                    bgColor="rgb(254 215 170)"
                                    color="rgb(234 88 12)"
                              />

                              <DashboardCard
                                    icon={<ProcessingOrder />}
                                    title="Processing Order"
                                    total={
                                          !loading && processingOrder
                                                ? processingOrder
                                                : 0
                                    }
                                    bgColor="#c7d2fe"
                                    color="#4f46e5"
                              />

                              <DashboardCard
                                    icon={<CompletedOrder />}
                                    title="Complete Order"
                                    total={
                                          !loading && completedOrder
                                                ? completedOrder
                                                : 0
                                    }
                                    bgColor="rgb(167 243 208)"
                                    color="rgb(5 150 105)"
                              />
                              <DashboardCard
                                    icon={<TotalOrder />}
                                    title="Cancelled Order"
                                    total={
                                          !loading && canceledOrder
                                                ? canceledOrder
                                                : 0
                                    }
                              />
                        </DashboardContentGrid>

                        <DashboardTableList
                              loading={loading}
                              orders={orders}
                              loadData={loadData}
                        />
                  </DashboardLayout>
            </>
      );
};

export default Dashboard;
