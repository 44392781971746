import React from 'react';
import { SwiperSlide } from 'swiper/react';

import 'swiper/css';
import { BlogSlider, BlogSliderItem } from '../../styles/frontend/blogs';

const BlogBannerSlider = ({ loading, blogs }) => {
      return (
            <>
                  {!loading ? (
                        blogs.length > 0 ? (
                              <BlogSlider>
                                    {blogs.slice(0, 3).map((blog, index) => (
                                          <SwiperSlide key={index}>
                                                <BlogSliderItem
                                                      to={`/blogs/${blog.slug}`}
                                                >
                                                      <img
                                                            src={blog.image}
                                                            alt={blog.title}
                                                      />
                                                      <span>{blog.title}</span>
                                                </BlogSliderItem>
                                          </SwiperSlide>
                                    ))}
                              </BlogSlider>
                        ) : (
                              <></>
                        )
                  ) : (
                        <>Loading</>
                  )}
            </>
      );
};

export default BlogBannerSlider;
