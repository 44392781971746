import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BackButton = styled(Link)`
      display: none;

      @media screen and (max-width: 600px) {
            color: ${(props) => props.theme.primaryColor};
            background-color: ${(props) => props.theme.white};
            padding: 5px 10px;
            border-radius: 5px;
            font-weight: 500;
            display: inline-block;
            font-family: 'HelveticaNowDisplay-Medium';
      }
`;
