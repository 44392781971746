import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const CartTable = styled(Table)`
      td {
            vertical-align: middle;
            font-size: 16px;
      }

      th {
            font-size: 18px;
      }
`;

export const CartInfo = styled.div`
      display: flex;
      align-items: center;
      gap: 10px;
`;

export const CartDescription = styled.div`
      width: calc(100% - 60px);
      display: flex;
      align-items: center;
      gap: 10px;
`;

export const CartRemove = styled.div`
      font-weight: 600;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 3.7rem;
      background: #ffffff;
      -webkit-box-shadow: 0 2px 22px rgba(0, 0, 0, 0.16);
      box-shadow: 0 2px 22px rgba(0, 0, 0, 0.16);
      margin-right: 1.5rem;
      border-radius: 50%;
      border: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            svg {
                  fill: ${(props) => props.theme.primaryColor};
            }
      }
`;

export const CartTitle = styled.div`
      font-size: 20px;
      width: calc(100% - 120px);
`;

export const CartImage = styled.div`
      height: 80px;
      width: 80px;

      img {
            height: 100%;
            width: 100%;
            object-fit: contain;
      }
`;

export const CheckoutLink = styled(Link)`
      margin-top: 20px;
      display: inline-block;
      font-size: 17px;
      border-radius: 5px;
      padding: 10px 20px;
      color: ${(props) => props.theme.white};
      background-color: ${(props) => props.color || props.theme.primaryColor};
      transition: 0.3s all ease-in-out;

      &:first-child {
            margin-right: 20px;
      }

      &:hover {
            color: ${(props) => props.theme.white};
            background-color: ${(props) => props.theme.secondaryColor};
      }
`;
