import React, { useContext } from 'react';
import BreadCrumb from '../../components/common/breadcrumb';
import { Col, Container, Row } from 'react-bootstrap';
import ContactMap from './ContactMap';
import ContactForm from './ContactForm';
import FooterBreadcrumb from '../../components/common/FooterBreadcrumb';
import Service from '../component/shop/service';
import { ContactContainer } from '../../styles/frontend/contact';
import MetaContext from '../../stores/MetaContext';

const ContactPage = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('contact-us');
      return (
            <>
                  <BreadCrumb title="Contact Us" />
                  <ContactContainer fluid>
                        <Row className="g-0">
                              <Col lg={12}>
                                    <ContactForm />
                              </Col>
                        </Row>
                  </ContactContainer>
                  <Service />
                  <Container>
                        <Row>
                              <Col lg={12}>
                                    <ContactMap />
                              </Col>
                        </Row>
                  </Container>
                  <FooterBreadcrumb />
            </>
      );
};

export default ContactPage;
