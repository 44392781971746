import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const PolicyContainer = styled(Container)`
      padding-top: 3rem;

      @media screen and (max-width: 600px) {
            padding-left: 15px;
            padding-right: 15px;
      }
`;

export const PolicyTabLink = styled(Link)`
      background-color: transparent;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
      transition: background-color 0.3s;
      font-size: 18px;
      color: #575757 !important;

      :hover {
            border-bottom: 2px solid ${(props) => props.theme.primaryColor};
      }

      &.active {
            border: 0;
            border-bottom: 2px solid ${(props) => props.theme.primaryColor};

            &::after {
                  content: none;
            }
      }

      @media screen and (max-width: 600px) {
            min-width: fit-content;
            display: inline-block;

            &:first-child {
                  padding-left: 0;
            }
      }
`;
