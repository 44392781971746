import React, { useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import { CommonContainer } from './style';
import FormButton from '../../components/common/form/FormButton';
import axios from 'axios';
import toast from 'react-hot-toast';

const Login = () => {
      const [type, setType] = useState('text');
      const handleType = () => {
            setType('password');
      };

      const [redirect, setRedirect] = useState(false);

      const [email, setEmail] = useState('');
      const [password, setPassword] = useState('');
      const [isLoading, setIsLoading] = useState(false);

      // Error Handle
      const [validated, setValidated] = useState(false);
      const handleButtonClick = async (event) => {
            event.preventDefault();
            setIsLoading(true);

            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                  event.preventDefault();
                  event.stopPropagation();
            }

            setValidated(true);

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/customer/login`,
                        {
                              email,
                              password
                        }
                  )
                  .then((response) => {
                        if (response.data.token !== null) {
                              if (response.data.result === 'success') {
                                    toast.success(
                                          'You are successfully Loggedin'
                                    );
                                    localStorage.setItem('isLoggedIn', true);
                                    localStorage.setItem(
                                          'token',
                                          JSON.stringify(response.data.token)
                                    );
                                    localStorage.setItem(
                                          'tokenExpiration',
                                          new Date(
                                                new Date().getTime() +
                                                      response.data
                                                            .token_validity *
                                                            1000
                                          )
                                    );
                                    setRedirect(true);
                              }
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message) {
                                    if (response.data.message.email) {
                                          toast.error(
                                                response.data.message.email
                                          );
                                    }
                                    if (response.data.message.password) {
                                          toast.error(
                                                response.data.message.password
                                          );
                                    }
                              }
                              if (response.data.errorMessage) {
                                    toast.error(response.data.errorMessage);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setIsLoading(false);
      };

      if (redirect) {
            return <Navigate to="/dashboard" />;
      }
      const login = localStorage.getItem('isLoggedIn');
      if (login) {
            return <Navigate to="/dashboard" />;
      }

      return (
            <>
                  <Breadcrumb title="Login" />

                  <CommonContainer>
                        <Row>
                              <Col md={5}>
                                    <h5 className="mb-3">New Customer</h5>
                                    <h6>Create New Account</h6>
                                    <p>
                                          By creating an account you will be
                                          able to shop faster, be up to date on
                                          an order's status, and keep track of
                                          the orders you have perviously made.
                                    </p>
                                    <Link className="form-btn" to="/register">
                                          Continue
                                    </Link>
                              </Col>
                              <Col md={5} className="mb-5">
                                    <h5 className="mb-3">Already Registered</h5>
                                    <h6 className="mb-3">
                                          Login to your Account
                                    </h6>
                                    <Form
                                          onSubmit={handleButtonClick}
                                          method="post"
                                    >
                                          <div className="form-div">
                                                <Form.Control
                                                      type="text"
                                                      name="email"
                                                      required={true}
                                                      placeholder="Email Address"
                                                      onChange={(event) =>
                                                            setEmail(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </div>
                                          <div className="mt-4 mb-2">
                                                <Form.Control
                                                      type={type}
                                                      name="password"
                                                      placeholder="Password"
                                                      required
                                                      onChange={(event) =>
                                                            setPassword(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                      onClick={handleType}
                                                      onBlur={handleType}
                                                />
                                          </div>
                                          <FormButton
                                                loading={isLoading}
                                                title="Login"
                                          />
                                    </Form>
                              </Col>
                        </Row>
                  </CommonContainer>
            </>
      );
};

export default Login;
