import React from 'react';
import { Link } from 'react-router-dom';
import { useSwiper } from 'swiper/react';

const ProductButton = ({ link }) => {
      const swiper = useSwiper();

      return (
            <div className="swiper-nav-btns">
                  <div>
                        <Link to={link}>Explore More</Link>
                  </div>
                  <div>
                        <button
                              aria-label="Previous Product"
                              onClick={() => swiper.slidePrev()}
                        >
                              <i className="bx bx-chevron-left"></i>
                        </button>
                        <button
                              aria-label="Next Product"
                              onClick={() => swiper.slideNext()}
                        >
                              <i className="bx bx-chevron-right"></i>
                        </button>
                  </div>
            </div>
      );
};

export default ProductButton;
