export const lightTheme = {
      body: '#F0F5F1',
      bodyRgba: '252, 246, 244',
      textRgba: '0,0,0',
      black: '#363636',
      white: '#FFFFFF',
      linkColor: '#333333',
      primaryColor: '#0F5C4A',
      secondaryColor: '#19504c',
      textColor: '#e6e6e6',
      dividerColor: '#18181833',
      paragraphColor: '#646464',
      borderColor: '#e4e4e4',
      footerBorderColor: '#d2d2d2',
      footerBackground: '#1D1D1D',
      footerPagraphColor: 'rgb(188 183 183)',
      backgroundColor: '#1c1c1c'
};

export const DarkTheme = {
      body: '#363636',
      text: '#FCF6F4',
      fontFamily: "'Manrope', sans-serif",
      textRgba: '252, 246, 244',
      bodyRgba: '0,0,0'
};
