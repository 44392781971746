import React from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { createContext, useState, useEffect } from 'react';

const CartContext = createContext({
      loading: false,
      carts: [],
      totalCart: 0,
      totalAmount: 0,
      addCartSubmit: () => {},
      removeProduct: () => {},
      updateQuantity: () => {}
});

export function CartContextProvider(props) {
      const [loading, setLoading] = useState(false);
      const [userCartCount, setUserCartCount] = useState(0);
      const token = JSON.parse(localStorage.getItem('token'));
      const [carts, setCarts] = useState();
      const [totalAmount, setTotalAmount] = useState(0);

      const loadData = async () => {
            if (token != null) {
                  setLoading(true);
                  await axios
                        .get(
                              `${process.env.REACT_APP_SECRET_KEY}/api/carts/count`,
                              {
                                    headers: {
                                          Authorization: `Bearer ${token}`
                                    }
                              }
                        )
                        .then((response) => {
                              if (response.data.result === 'success') {
                                    setUserCartCount(response.data.totalCart);
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
                  await axios
                        .get(`${process.env.REACT_APP_SECRET_KEY}/api/carts`, {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        })
                        .then((response) => {
                              if (response.data.result === 'success') {
                                    setCarts(response.data.products);
                                    setTotalAmount(response.data.totalAmount);
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
                  setLoading(false);
            }
      };

      useEffect(() => {
            loadData();
      }, []);

      const addCartSubmit = async (slug, quantity) => {
            console.log(quantity);
            setLoading(true);
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/carts/${slug}/added`,
                        {
                              quantity: quantity
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              loadData();
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      const updateQuantity = async (itemId, newQuantity, index) => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/carts/${itemId}/update`,
                        {
                              quantity: newQuantity
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              const updatedCarts = [...carts];
                              updatedCarts[index].subtotal =
                                    response.data.amount;
                              setCarts(updatedCarts);
                              setTotalAmount(response.data.totalAmount);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      };

      const removeProduct = async (slug) => {
            await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/carts/${slug}`
                  )
                  .then((response) => {
                        loadData();
                        toast.success(response.data.message);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      };

      const context = {
            loading: loading,
            carts: carts,
            totalCart: userCartCount,
            addCartSubmit: addCartSubmit,
            removeProduct: removeProduct,
            updateQuantity: updateQuantity,
            totalAmount: totalAmount
      };

      return (
            <CartContext.Provider value={context}>
                  {props.children}
            </CartContext.Provider>
      );
}

export default CartContext;
