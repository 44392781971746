import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { BgGray } from '../DashboardLayout/style';
import { DashboardBreadcrumb } from '../Dashboard/style';
import {
      InvoiceContainer,
      InvoiceContent,
      InvoiceContentBody,
      InvoiceContentBodyDetail,
      InvoiceContentBodyImage,
      InvoiceContentBodySummary,
      InvoiceContentBodyTitle,
      InvoiceContentContainer,
      InvoiceDetail,
      InvoiceDetailContent,
      InvoiceDetailDescription,
      InvoiceDetailSummary,
      InvoiceDetailTitle,
      InvoiceTable,
      InvoiceTableContainer,
      InvoiceTableContent,
      InvoiceTableData,
      InvoiceTableHeader,
      InvoiceTableName,
      InvoiceTablePrice,
      InvoiceTableQuantity,
      InvoiceTableTotal
} from './style';

const OrderShow = () => {
      const { orderId } = useParams();
      const [loading, setLoading] = useState(false);
      const [order, setOrder] = useState([]);
      const [items, setItems] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/customer/orders/${orderId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setOrder(response.data.orders);
                              setItems(response.data.items);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <DashboardBreadcrumb>
                        <Container>
                              <div className="page-breadcrumb">
                                    <Link to="/dashboard">Dashboard</Link> {'>'}{' '}
                                    <Link to="/my-orders">My Order</Link> {'>'}{' '}
                                    Order View
                              </div>
                        </Container>
                  </DashboardBreadcrumb>
                  <BgGray>
                        <Container>
                              <Row className="justify-content-center">
                                    <Col lg={11}>
                                          <InvoiceContainer>
                                                <InvoiceContent>
                                                      <div>
                                                            <InvoiceContentContainer>
                                                                  <InvoiceContentBody>
                                                                        <InvoiceContentBodyTitle>
                                                                              Invoice
                                                                        </InvoiceContentBodyTitle>
                                                                        <InvoiceContentBodyDetail>
                                                                              <InvoiceContentBodyImage>
                                                                                    <a
                                                                                          class=""
                                                                                          href="/"
                                                                                    >
                                                                                          <span class="product__image-container">
                                                                                                <span class="product__image-content">
                                                                                                      <img
                                                                                                            alt=""
                                                                                                            aria-hidden="true"
                                                                                                            src="/images/png/logo.png"
                                                                                                            width="180px"
                                                                                                            class="product__image p-0 me-0 mb-2"
                                                                                                      />
                                                                                                </span>
                                                                                          </span>
                                                                                    </a>
                                                                              </InvoiceContentBodyImage>
                                                                              <InvoiceContentBodySummary>
                                                                                    Teku,{' '}
                                                                                    Kathmandu
                                                                                    <br />{' '}
                                                                                    01-5340610{' '}
                                                                              </InvoiceContentBodySummary>
                                                                        </InvoiceContentBodyDetail>
                                                                  </InvoiceContentBody>
                                                                  <InvoiceDetail>
                                                                        <InvoiceDetailContent>
                                                                              <InvoiceDetailTitle>
                                                                                    Date
                                                                              </InvoiceDetailTitle>
                                                                              <InvoiceDetailSummary>
                                                                                    <span>
                                                                                          {
                                                                                                order.date
                                                                                          }
                                                                                    </span>
                                                                              </InvoiceDetailSummary>
                                                                        </InvoiceDetailContent>
                                                                        <InvoiceDetailContent>
                                                                              <InvoiceDetailTitle>
                                                                                    Invoice
                                                                                    No.
                                                                              </InvoiceDetailTitle>
                                                                              <InvoiceDetailSummary>
                                                                                    #
                                                                                    {
                                                                                          order.order_name
                                                                                    }
                                                                              </InvoiceDetailSummary>
                                                                        </InvoiceDetailContent>
                                                                        <InvoiceDetailDescription>
                                                                              <InvoiceDetailTitle>
                                                                                    Invoice
                                                                                    To.
                                                                              </InvoiceDetailTitle>
                                                                              <InvoiceDetailSummary>
                                                                                    {
                                                                                          order.name
                                                                                    }
                                                                                    <br />
                                                                                    {
                                                                                          order.address
                                                                                    }
                                                                              </InvoiceDetailSummary>
                                                                        </InvoiceDetailDescription>
                                                                  </InvoiceDetail>
                                                            </InvoiceContentContainer>
                                                            <div>
                                                                  <InvoiceTable>
                                                                        <InvoiceTableContainer>
                                                                              <InvoiceTableContent>
                                                                                    <thead>
                                                                                          <tr>
                                                                                                <InvoiceTableHeader className="text-start">
                                                                                                      SN.
                                                                                                </InvoiceTableHeader>
                                                                                                <InvoiceTableHeader className="text-start">
                                                                                                      Product
                                                                                                      Name
                                                                                                </InvoiceTableHeader>
                                                                                                <InvoiceTableHeader className="text-center">
                                                                                                      Quantity
                                                                                                </InvoiceTableHeader>
                                                                                                <InvoiceTableHeader className="text-center">
                                                                                                      Item
                                                                                                      Price
                                                                                                </InvoiceTableHeader>
                                                                                                <InvoiceTableHeader className="text-end">
                                                                                                      Amount
                                                                                                </InvoiceTableHeader>
                                                                                          </tr>
                                                                                    </thead>
                                                                                    <tbody class="bg-white divide-y divide-gray-100 text-serif text-sm">
                                                                                          {!loading
                                                                                                ? items?.length >
                                                                                                  0
                                                                                                      ? items?.map(
                                                                                                              (
                                                                                                                    item,
                                                                                                                    index
                                                                                                              ) => (
                                                                                                                    <tr>
                                                                                                                          <InvoiceTableData>
                                                                                                                                {
                                                                                                                                      ++index
                                                                                                                                }{' '}
                                                                                                                          </InvoiceTableData>
                                                                                                                          <InvoiceTableName>
                                                                                                                                <div class="">
                                                                                                                                      <img
                                                                                                                                            width="60px"
                                                                                                                                            height="60px"
                                                                                                                                            src={
                                                                                                                                                  item.image
                                                                                                                                            }
                                                                                                                                            alt={
                                                                                                                                                  item.product_name
                                                                                                                                            }
                                                                                                                                      />
                                                                                                                                </div>
                                                                                                                                <div class="">
                                                                                                                                      {
                                                                                                                                            item.product_name
                                                                                                                                      }
                                                                                                                                </div>
                                                                                                                          </InvoiceTableName>
                                                                                                                          <InvoiceTableQuantity>
                                                                                                                                {
                                                                                                                                      item.quantity
                                                                                                                                }{' '}
                                                                                                                          </InvoiceTableQuantity>
                                                                                                                          <InvoiceTablePrice>
                                                                                                                                Rs.{' '}
                                                                                                                                {
                                                                                                                                      item.amount
                                                                                                                                }{' '}
                                                                                                                          </InvoiceTablePrice>
                                                                                                                          <InvoiceTableTotal>
                                                                                                                                Rs.{' '}
                                                                                                                                {
                                                                                                                                      item.total
                                                                                                                                }
                                                                                                                          </InvoiceTableTotal>
                                                                                                                    </tr>
                                                                                                              )
                                                                                                        )
                                                                                                      : null
                                                                                                : null}
                                                                                    </tbody>
                                                                              </InvoiceTableContent>
                                                                        </InvoiceTableContainer>
                                                                  </InvoiceTable>
                                                            </div>
                                                            <div class="invoice__payment bg-emerald-50">
                                                                  <div class="invoice__payment-content">
                                                                        <div class="invoice__details-content sm:flex-wrap">
                                                                              <span class="mb-1 invoice__details-title">
                                                                                    Payment
                                                                                    Method
                                                                              </span>
                                                                              <span class="text-sm text-gray-500 font-semibold font-serif block">
                                                                                    {
                                                                                          order.payment
                                                                                    }
                                                                              </span>
                                                                        </div>
                                                                        <div class="flex flex-col sm:flex-wrap">
                                                                              <span class="mb-1 invoice__details-title">
                                                                                    Total
                                                                                    Amount
                                                                              </span>
                                                                              <span class="text-2xl font-serif font-bold text-red-500 block">
                                                                                    Rs.{' '}
                                                                                    {
                                                                                          order.total_amount
                                                                                    }
                                                                              </span>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </InvoiceContent>
                                          </InvoiceContainer>
                                    </Col>
                              </Row>
                        </Container>
                  </BgGray>
            </>
      );
};

export default OrderShow;
