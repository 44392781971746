import React, { useContext, useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { CommonContainer } from '../../auth/Login/style';
import { Col, FormControl, FormLabel, Row } from 'react-bootstrap';
import {
      CheckoutButton,
      CheckoutContent,
      CheckoutForm,
      CheckoutInfo,
      CheckoutItem,
      CheckoutItemImage,
      CheckoutItemTitle,
      CheckoutSummary,
      CheckoutTitle
} from './style';
import CartContext from '../../stores/CartContext';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Loading } from '../../styles/components/form/Loading';
import { Navigate } from 'react-router-dom';

const Checkout = () => {
      const cartCtx = useContext(CartContext);
      const [loading, setLoading] = useState(false);
      const [slug, setSlug] = useState("");
      const [redirect, setRedirect] = useState(false)

      const token = JSON.parse(localStorage.getItem('token'));

      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [companyName, setCompanyName] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [state, setState] = useState('');
      const [city, setCity] = useState('');
      const [streetAddress, setStreetAddress] = useState('');
      const [note, setNote] = useState('');

      const handleForm = async () => {
            setLoading(true);
            const data = {
                  name: name,
                  email: email,
                  company_name: companyName,
                  state: state,
                  city: city,
                  phone_number: phoneNumber,
                  street_address: streetAddress,
                  note: note
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/checkouts`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === 'success') {
                                    toast.success(response.data.message);
                                    setSlug(response.data.slug);
                                    setRedirect(true);
                                    setLoading(false)
                              }
                              if (response.data.result === 'error') {
                                    if (response.data.message) {
                                          if (response.data.message.name) {
                                                toast.error(
                                                      response.data.message.name
                                                );
                                          }
                                          if (response.data.message.email) {
                                                toast.error(
                                                      response.data.message.email
                                                );
                                          }
                                          if (response.data.message.phone_number) {
                                                toast.error(
                                                      response.data.message
                                                            .phone_number
                                                );
                                          }
                                          if (response.data.message.state) {
                                                toast.error(
                                                      response.data.message.state
                                                );
                                          }
                                          if (response.data.message.city) {
                                                toast.error(
                                                      response.data.message.city
                                                );
                                          }
                                          if (response.data.message.note) {
                                                toast.error(
                                                      response.data.message.note
                                                );
                                          }
                                          if (response.data.message.street_address) {
                                                toast.error(
                                                      response.data.message
                                                            .street_address
                                                );
                                          }
                                          if (response.data.message.company_name) {
                                                toast.error(
                                                      response.data.message
                                                            .company_name
                                                );
                                          }
                                    }
                              }
                        }, 3000)
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
                  setLoading(false)
      };


      if (redirect) {
            return <Navigate to={`/my-orders/${slug}`} />;
      }

      if (cartCtx.totalCart === 0) {
            toast.error('Your cart is empty.');
            return <Navigate to="/products" />;
      }

      return (
            <>
                  <Breadcrumb
                        otherLink="/carts"
                        otherTitle="Shopping Cart"
                        title="Checkout"
                  />
                  <CommonContainer>
                        <Row>
                              <Col lg={7}>
                                    <CheckoutForm>
                                          <Row className="g-4">
                                                <Col lg={12}>
                                                      <FormLabel>
                                                            Full Name
                                                      </FormLabel>
                                                      <FormControl
                                                            type="text"
                                                            onChange={(event) =>
                                                                  setName(
                                                                        event
                                                                              .target
                                                                              .value
                                                                  )
                                                            }
                                                      />
                                                </Col>
                                                <Col lg={12}>
                                                      <FormLabel>
                                                            Company Name
                                                            (optional)
                                                      </FormLabel>
                                                      <FormControl
                                                            type="text"
                                                            onChange={(event) =>
                                                                  setCompanyName(
                                                                        event
                                                                              .target
                                                                              .value
                                                                  )
                                                            }
                                                      />
                                                </Col>
                                                <Col lg={6}>
                                                      <FormLabel>
                                                            Email Address
                                                      </FormLabel>
                                                      <FormControl
                                                            type="email"
                                                            onChange={(event) =>
                                                                  setEmail(
                                                                        event
                                                                              .target
                                                                              .value
                                                                  )
                                                            }
                                                      />
                                                </Col>
                                                <Col lg={6}>
                                                      <FormLabel>
                                                            Phone No
                                                      </FormLabel>
                                                      <FormControl
                                                            type="text"
                                                            onChange={(event) =>
                                                                  setPhoneNumber(
                                                                        event
                                                                              .target
                                                                              .value
                                                                  )
                                                            }
                                                      />
                                                </Col>
                                                <Col lg={6}>
                                                      <FormLabel>
                                                            State / Zone /
                                                            Province
                                                      </FormLabel>
                                                      <FormControl
                                                            type="text"
                                                            onChange={(event) =>
                                                                  setState(
                                                                        event
                                                                              .target
                                                                              .value
                                                                  )
                                                            }
                                                      />
                                                </Col>
                                                <Col lg={6}>
                                                      <FormLabel>
                                                            City
                                                      </FormLabel>
                                                      <FormControl
                                                            type="text"
                                                            onChange={(event) =>
                                                                  setCity(
                                                                        event
                                                                              .target
                                                                              .value
                                                                  )
                                                            }
                                                      />
                                                </Col>
                                                <Col lg={12}>
                                                      <FormLabel>
                                                            Street Address
                                                      </FormLabel>
                                                      <FormControl
                                                            type="text"
                                                            onChange={(event) =>
                                                                  setStreetAddress(
                                                                        event
                                                                              .target
                                                                              .value
                                                                  )
                                                            }
                                                      />
                                                </Col>
                                                <Col lg={12}>
                                                      <FormLabel>
                                                            Order Note
                                                            (optional)
                                                      </FormLabel>
                                                      <FormControl
                                                            as="textarea"
                                                            rows={4}
                                                            onChange={(event) =>
                                                                  setNote(
                                                                        event
                                                                              .target
                                                                              .value
                                                                  )
                                                            }
                                                      />
                                                </Col>
                                          </Row>
                                    </CheckoutForm>
                              </Col>
                              <Col lg={5}>
                                    <CheckoutSummary>
                                          <CheckoutTitle>
                                                Your Order Summary
                                          </CheckoutTitle>
                                          <CheckoutContent>
                                                {cartCtx.carts?.length > 0
                                                      ? cartCtx.carts?.map(
                                                              (item, index) => (
                                                                    <CheckoutItem
                                                                          key={
                                                                                index
                                                                          }
                                                                    >
                                                                          <CheckoutInfo>
                                                                                <CheckoutItemImage>
                                                                                      <img
                                                                                            src={
                                                                                                  item.image
                                                                                            }
                                                                                            alt={
                                                                                                  item.title
                                                                                            }
                                                                                      />
                                                                                </CheckoutItemImage>
                                                                                <CheckoutItemTitle width="calc(100% - 80px)">
                                                                                      {
                                                                                            item.title
                                                                                      }
                                                                                      <div className="text-muted">
                                                                                            Qty
                                                                                            :{' '}
                                                                                            {
                                                                                                  item.qty
                                                                                            }
                                                                                      </div>
                                                                                </CheckoutItemTitle>
                                                                          </CheckoutInfo>
                                                                          <CheckoutItemTitle size="18px">
                                                                                Rs{' '}
                                                                                {
                                                                                      item.price
                                                                                }
                                                                          </CheckoutItemTitle>
                                                                    </CheckoutItem>
                                                              )
                                                        )
                                                      : null}
                                                <CheckoutItem className="mt-4">
                                                      <CheckoutItemTitle>
                                                            Sub Total
                                                      </CheckoutItemTitle>
                                                      <CheckoutItemTitle>
                                                            Rs{' '}
                                                            {
                                                                  cartCtx.totalAmount
                                                            }
                                                      </CheckoutItemTitle>
                                                </CheckoutItem>
                                                <CheckoutItem>
                                                      <CheckoutItemTitle>
                                                            Total Amount
                                                      </CheckoutItemTitle>
                                                      <CheckoutItemTitle size="22px">
                                                            Rs{' '}
                                                            {
                                                                  cartCtx.totalAmount
                                                            }
                                                      </CheckoutItemTitle>
                                                </CheckoutItem>
                                          </CheckoutContent>
                                          <CheckoutButton onClick={handleForm}>
                                                {!loading ? (
                                                      'Checkout Now'
                                                ) : (
                                                      <Loading />
                                                )}
                                          </CheckoutButton>
                                    </CheckoutSummary>
                              </Col>
                        </Row>
                  </CommonContainer>
            </>
      );
};

export default Checkout;
