import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import FormButton from '../form/FormButton';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';

const LoginBox = ({ handleClose, show }) => {
      const [loading, setLoading] = useState();

      const [email, setEmail] = useState('');
      const [password, setPassword] = useState('');

      const handleForm = async (event) => {
            setLoading(true);
            event.preventDefault();

            const data = {
                  email: email,
                  password: password
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/customer/login`,
                        data,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.token) {
                                    toast.success(
                                          'You are successfully login.'
                                    );
                                    localStorage.setItem('isLoggedIn', true);
                                    localStorage.setItem(
                                          'token',
                                          JSON.stringify(response.data.token)
                                    );
                                    localStorage.setItem(
                                          'tokenExpiration',
                                          new Date(
                                                new Date().getTime() +
                                                      response.data
                                                            .token_validity *
                                                            1000
                                          )
                                    );
                                    setTimeout(() => {
                                          window.location.reload();
                                    }, 5000);
                              }
                              if (response.data.result === 'error') {
                                    if (response.data.message) {
                                          if (response.data.message.email) {
                                                toast.error(
                                                      response.data.message
                                                            .email
                                                );
                                          }
                                          if (response.data.message.password) {
                                                toast.error(
                                                      response.data.message
                                                            .password
                                                );
                                          }
                                    }
                                    if (response.data.errorMessage) {
                                          toast.error(
                                                response.data.errorMessage
                                          );
                                    }
                              }
                              setLoading(false);
                        }, 2500);
                  });
      };

      return (
            <>
                  <Modal
                        show={show}
                        centered
                        onHide={handleClose}
                        backdrop="static"
                  >
                        <Modal.Header closeButton>
                              <Modal.Title>Login </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                              <Form className="mb-4" onSubmit={handleForm}>
                                    <Row className="g-3">
                                          <Col lg={12}>
                                                <Form.Label>
                                                      Email Address
                                                </Form.Label>
                                                <Form.Control
                                                      type="email"
                                                      onChange={(event) =>
                                                            setEmail(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </Col>

                                          <Col lg={12}>
                                                <Form.Label>
                                                      Password
                                                </Form.Label>
                                                <Form.Control
                                                      type="password"
                                                      onChange={(event) =>
                                                            setPassword(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </Col>
                                          <Col lg={12}>
                                                <Link to="/forget-password">
                                                      Forget password
                                                </Link>
                                          </Col>
                                          <Col lg={12}>
                                                <FormButton
                                                      title="Login"
                                                      loading={loading}
                                                />
                                          </Col>
                                          <Col lg={12} className="text-center">
                                                <Link to="/register">
                                                      Create new account
                                                </Link>
                                          </Col>
                                    </Row>
                              </Form>
                        </Modal.Body>
                  </Modal>
            </>
      );
};

export default LoginBox;
