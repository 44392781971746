import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const FooterMobile = styled.div`
      position: fixed;
      bottom: 0;
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
            rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      z-index: 999999;
      padding: 0 30px;
      background-color: ${(props) => props.theme.white};

      @media screen and (min-width: 1224px) {
            display: none;
      }
`;

export const MobileContent = styled.div`
      display: flex;
      align-items: center;
      padding: 20px 0;
      justify-content: space-between;
`;

export const MobileItem = styled(NavLink)`
      color: ${(props) => props.theme.black};

      span {
            font-weight: 600;
      }

      &.active {
            color: ${(props) => props.theme.primaryColor};
      }
`;
