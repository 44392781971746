import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { FaqAccordion } from '../../styles/frontend/faqs';

const AccordionContainer = ({ loading, items }) => {
      return (
            <>
                  <FaqAccordion defaultActiveKey={0} flush>
                        {!loading ? (
                              items.length > 0 ? (
                                    items.map((item, index) => (
                                          <Accordion.Item eventKey={index}>
                                                <Accordion.Header>
                                                      {item.question}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                      {item.answer}
                                                </Accordion.Body>
                                          </Accordion.Item>
                                    ))
                              ) : (
                                    <>Loading</>
                              )
                        ) : (
                              <>Loading</>
                        )}
                  </FaqAccordion>
            </>
      );
};

export default AccordionContainer;
