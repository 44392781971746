import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';

import 'swiper/css';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom';
import { Autoplay, Navigation } from 'swiper';
import { AboutRow, AboutShowroomItem } from '../../../../styles/frontend/about';
import {
      ProductSlider,
      ProductSliderItem
} from '../../../../styles/frontend/home';
import { ProductButton } from '../../../sliders';
import axios from 'axios';

const HomeProductSlider = () => {
      const [products, setProducts] = useState([]);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/products/trending/list`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProducts(response.data.products);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Container
                        fluid
                        style={{ backgroundColor: '#f5f5f5' }}
                        className="p-0"
                  >
                        <Container>
                              <AboutRow className="g-0 my-5 pb-5">
                                    <Col lg={12}>
                                          <Row className="justify-content-start g-0">
                                                <Col
                                                      lg={9}
                                                      className="pt-5 pb-3"
                                                >
                                                      <AboutShowroomItem>
                                                            <h2>
                                                                  Trending
                                                                  Product
                                                            </h2>
                                                      </AboutShowroomItem>
                                                </Col>
                                          </Row>
                                    </Col>
                                    <Col lg={12}>
                                          {!loading ? (
                                                <>
                                                      <ProductSlider
                                                            loop={false}
                                                            slidesPerView={1}
                                                            spaceBetween={0}
                                                            autoplay={{
                                                                  delay: 3000,
                                                                  disableOnInteraction: false
                                                            }}
                                                            breakpoints={{
                                                                  640: {
                                                                        slidesPerView: 1,
                                                                        spaceBetween: 0
                                                                  },
                                                                  768: {
                                                                        slidesPerView: 2,
                                                                        spaceBetween: 10
                                                                  },
                                                                  1024: {
                                                                        slidesPerView: 3,
                                                                        spaceBetween: 0
                                                                  }
                                                            }}
                                                            modules={[
                                                                  Autoplay,
                                                                  Navigation
                                                            ]}
                                                      >
                                                            {products?.map(
                                                                  (
                                                                        item,
                                                                        index
                                                                  ) => (
                                                                        <ProductSliderItem
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <Link
                                                                                    to={`/products/${item.product_slug}`}
                                                                              >
                                                                                    <img
                                                                                          src={
                                                                                                item.image
                                                                                          }
                                                                                          alt={
                                                                                                item.title
                                                                                          }
                                                                                          width="100%"
                                                                                          height="100%"
                                                                                    />
                                                                                    <div>
                                                                                          {
                                                                                                item.title
                                                                                          }
                                                                                    </div>
                                                                              </Link>
                                                                        </ProductSliderItem>
                                                                  )
                                                            )}
                                                            <ProductButton link="/products" />
                                                      </ProductSlider>
                                                </>
                                          ) : (
                                                <>
                                                      <Spinner />
                                                </>
                                          )}
                                    </Col>
                              </AboutRow>
                        </Container>
                  </Container>
            </>
      );
};

export default HomeProductSlider;
