import React from 'react';
import { Col, Form } from 'react-bootstrap';

const FormTextarea = ({ title, required, handleChange, classes }) => {
      return (
            <>
                  <Form.Group as={Col} lg={classes ? classes : 6}>
                        <Form.Label>
                              {title}{' '}
                              {required && (
                                    <span className="text-danger">*</span>
                              )}
                        </Form.Label>
                        <Form.Control
                              as="textarea"
                              required={required}
                              rows={6}
                              type="text"
                              onChange={(event) =>
                                    handleChange(event.target.value)
                              }
                        />
                  </Form.Group>
            </>
      );
};

export default FormTextarea;
