import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';

export const AboutRow = styled(Row)`
      align-items: center;
      justify-content: space-between;
      margin-top: 3rem;
      margin-bottom: 3rem;
      padding-bottom: 3rem;
      background-color: ${(props) => props.bgColor};

      h2 {
            letter-spacing: 1.5px;
            text-transform: uppercase;
            font-weight: 300 !important;
            font-family: 'HelveticaNowDisplay-Light', sans-serif;
            padding-bottom: 20px;
            margin-bottom: 20px;
            font-size: 36px;
            position: relative;

            &::before {
                  content: '';
                  display: block;
                  height: 0.5px;
                  width: 200%;
                  background: ${(props) =>
                        props.color ? props.theme.white : '#004650'};
                  position: absolute;
                  left: -188%;
                  bottom: 0;
            }
      }

      p {
            font-family: 'HelveticaNowDisplay-Light', sans-serif;
            line-height: 1.6;
            font-size: 16px;
            letter-spacing: 0.9px;
      }

      ul {
            margin: 0;
            padding: 0;
            margin-top: 10px;

            li {
                  display: inline-block;
                  border-left: 1px solid ${(props) => props.theme.primaryColor};
                  padding-left: 15px;
                  margin-left: 15px;

                  a {
                        color: ${(props) => props.theme.black};
                        line-height: 1.6;
                        font-size: 18px;
                        font-weight: 300 !important;
                        letter-spacing: 0.9px;
                        text-transform: uppercase;
                        font-family: 'HelveticaNowDisplay-Medium', sans-serif;

                        &:hover {
                              color: ${(props) => props.theme.primaryColor};
                        }
                  }

                  &:first-child {
                        border: 0;
                        padding-left: 0;
                        margin-left: 0;
                  }
            }
      }

      @media screen and (max-width: 600px) {
            padding: ${(props) => (props.padding ? props.padding : '0 5px')};

            h2 {
                  font-size: 28px;
                  line-height: 1.1;

                  &::before {
                        left: -160%;
                  }
            }
      }
`;

export const AboutSliderItem = styled(SwiperSlide)`
      img {
            height: 400px;
            object-fit: cover;
            width: 100%;
      }
      @media screen and (max-width: 600px) {
            margin-bottom: 1rem;
            img {
                  height: 320px;
            }
      }
`;

export const AboutShowroomItem = styled.div`
      padding-right: 3rem;
      padding-top: 3rem;
      padding-bottom: 3rem;

      @media screen and (max-width: 600px) {
            padding-right: 0;
            padding-top: 1rem;
      }
`;
