import React from 'react';
import BreadcrumbItem from './BreadcrumbItem';
import {
      BreadcrumbContainer,
      BreadcrumbContent,
      BreadcrumbMenu,
      BreadcrumbTitle
} from '../../../styles/components/common/breadcrumb';
import { BackButton } from './style';

const Breadcrumb = ({ title, link, otherLink, otherTitle, back }) => {
      return (
            <>
                  <BreadcrumbContainer>
                        <BreadcrumbContent>
                              <BreadcrumbTitle>
                                    <h1>{title}</h1>
                              </BreadcrumbTitle>
                              <BreadcrumbMenu>
                                    <BreadcrumbItem link="/" title="Home" />
                                    {otherTitle && (
                                          <BreadcrumbItem
                                                title={otherTitle}
                                                link={otherLink}
                                          />
                                    )}
                                    <BreadcrumbItem title={title} link={link} />
                              </BreadcrumbMenu>
                              <BackButton to={back ? back : '/'}>
                                    Back
                              </BackButton>
                        </BreadcrumbContent>
                  </BreadcrumbContainer>
            </>
      );
};

export default Breadcrumb;
