import React, { useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { SwiperSlide } from 'swiper/react';
import {
      ContactFormContainer,
      ContactRow,
      ContactSlider
} from '../../styles/frontend/contact';

import $ from 'jquery';
import axios from 'axios';
import toast from 'react-hot-toast';

const ContactForm = () => {
      const [loading, setLoading] = useState(false);

      const [name, setName] = useState('');
      const [email, setEmail] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [message, setMessage] = useState('');

      const handleForm = async (event) => {
            setLoading(true);
            event.preventDefault();

            const data = {
                  name: name,
                  email: email,
                  phone_number: phoneNumber,
                  message: message
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/contacts`,
                        data,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                              if (response.data.message.message) {
                                    toast.error(response.data.message.message);
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      return (
            <>
                  <ContactRow>
                        <Col lg={7}>
                              <ContactSlider>
                                    <SwiperSlide>
                                          <img
                                                src="/images/sliders/enq-banner03.jpeg"
                                                alt=""
                                          />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                          <img
                                                src="/images/sliders/enq-banner.jpeg"
                                                alt=""
                                          />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                          <img
                                                src="/images/sliders/enq-banner02.jpeg"
                                                alt=""
                                          />
                                    </SwiperSlide>
                              </ContactSlider>
                        </Col>
                        <Col lg={5}>
                              <ContactFormContainer onSubmit={handleForm}>
                                    <Col lg={9} className="row g-3">
                                          <Form.Group as={Col} lg={12}>
                                                <Form.Label>
                                                      <span className="text-danger">
                                                            *
                                                      </span>{' '}
                                                      Your Name
                                                </Form.Label>
                                                <Form.Control
                                                      type="text"
                                                      onChange={(event) =>
                                                            setName(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </Form.Group>
                                          <Form.Group as={Col} lg={12}>
                                                <Form.Label>
                                                      <span className="text-danger">
                                                            *
                                                      </span>{' '}
                                                      Email
                                                </Form.Label>
                                                <Form.Control
                                                      type="email"
                                                      onChange={(event) =>
                                                            setEmail(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </Form.Group>
                                          <Form.Group as={Col} lg={12}>
                                                <Form.Label>
                                                      <span className="text-danger">
                                                            *
                                                      </span>{' '}
                                                      Phone Number.
                                                </Form.Label>
                                                <Form.Control
                                                      type="text"
                                                      onChange={(event) =>
                                                            setPhoneNumber(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </Form.Group>
                                          <Form.Group className="col-md-12">
                                                <Form.Label>
                                                      <span className="text-danger">
                                                            *
                                                      </span>{' '}
                                                      Message
                                                </Form.Label>
                                                <Form.Control
                                                      as="textarea"
                                                      type="text"
                                                      rows="4"
                                                      onChange={(event) =>
                                                            setMessage(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </Form.Group>
                                          <Form.Group className="col-md-12">
                                                <Button
                                                      variant="primary"
                                                      size="md"
                                                      type="submit"
                                                      block
                                                >
                                                      Submit Request
                                                      {loading && <Spinner />}
                                                </Button>
                                          </Form.Group>
                                    </Col>
                              </ContactFormContainer>
                        </Col>
                  </ContactRow>
            </>
      );
};

export default ContactForm;
