import React from 'react';
import { EnquiryFooter, EnquirySlider } from '../../../styles/frontend/dealers';
import { Container } from 'react-bootstrap';
import { SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import SupportTicketIcon from '../../icons/SupportTicketIcon';
import { DealerIcon, PrivacyIcon, TermsCondition } from '../../icons';

const FooterBreadcrumb = () => {
      const options = {
            margin: 30,
            responsiveClass: true,
            nav: false,
            dots: false,
            lazyLoad: true,
            autoplay: false,
            navText: ['Prev', 'Next'],
            smartSpeed: 1000,
            responsive: {
                  0: {
                        items: 1
                  },
                  400: {
                        items: 1
                  },
                  600: {
                        items: 2
                  },
                  700: {
                        items: 3
                  },
                  1000: {
                        items: 4
                  }
            }
      };
      return (
            <>
                  <EnquiryFooter>
                        <Container>
                              <EnquirySlider {...options} className="owl-theme">
                                    <SwiperSlide>
                                          <Link to="/customer-enquiry">
                                                <div>
                                                      <SupportTicketIcon />
                                                </div>
                                                <div>Customer Enquiry</div>
                                          </Link>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                          <Link to="/dealer-enquiry">
                                                <div>
                                                      <DealerIcon />
                                                </div>
                                                <div>Dealer Enquiry</div>
                                          </Link>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                          <Link to="/terms-and-conditions">
                                                <div>
                                                      <TermsCondition />
                                                </div>
                                                <div>Terms & Conditions</div>
                                          </Link>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                          <Link to="/support-ticket">
                                                <div>
                                                      <SupportTicketIcon />
                                                </div>
                                                <div>Support Ticket</div>
                                          </Link>
                                    </SwiperSlide>
                              </EnquirySlider>
                        </Container>
                  </EnquiryFooter>
            </>
      );
};

export default FooterBreadcrumb;
