import React, { useContext } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import CatalogueView from './CatalogueView';
import {
      CatalogueBody,
      CatalogueItem,
      CatalogueItemDetail,
      CatalogueItemIcon,
      CatalogueItemImage,
      CatalogueItemTitle
} from '../../styles/frontend/catalogue';
import MetaContext from '../../stores/MetaContext';

const Catalogue = () => {
      const [catalogues, setCatalogues] = useState([]);
      const [loading, setLoading] = useState(false);

      const [slug, setSlug] = useState('');
      const [title, setTitle] = useState('');
      const [fullscreen, setFullscreen] = useState(true);
      const [show, setShow] = useState(false);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('catalogues');

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/catalogues`, {
                        headers: {
                              apikey: process.env.REACT_APP_Api_Key
                        }
                  })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCatalogues(response.data.catalogues);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleCatalogue = (dataSlug, dataTitle) => {
            setTitle(dataTitle);
            setSlug(dataSlug);
            setShow(true);
      };

      return (
            <>
                  <Breadcrumb title="Catalogue" />

                  <Container className="mt-5">
                        {!loading ? (
                              catalogues.length > 0 ? (
                                    <CatalogueBody>
                                          {catalogues.map(
                                                (catalogue, index) => (
                                                      <CatalogueItem
                                                            key={index}
                                                      >
                                                            <CatalogueItemImage>
                                                                  <a
                                                                        role="button"
                                                                        onClick={() =>
                                                                              handleCatalogue(
                                                                                    catalogue.slug,
                                                                                    catalogue.title
                                                                              )
                                                                        }
                                                                        href="#"
                                                                  >
                                                                        <img
                                                                              src={`${process.env.REACT_APP_SECRET_KEY}/frontend/images/catalogues/${catalogue.image}`}
                                                                              alt={
                                                                                    catalogue.title
                                                                              }
                                                                        />
                                                                  </a>
                                                            </CatalogueItemImage>
                                                            <CatalogueItemDetail>
                                                                  <CatalogueItemTitle>
                                                                        <a
                                                                              role="button"
                                                                              href="#"
                                                                        >
                                                                              {
                                                                                    catalogue.title
                                                                              }
                                                                        </a>
                                                                  </CatalogueItemTitle>
                                                                  <CatalogueItemIcon>
                                                                        <div className="d-flex align-items-center gap-2">
                                                                              <a
                                                                                    onClick={() =>
                                                                                          handleCatalogue(
                                                                                                catalogue.slug,
                                                                                                catalogue.title
                                                                                          )
                                                                                    }
                                                                                    href="#"
                                                                              >
                                                                                    View
                                                                              </a>
                                                                              <span>
                                                                                    |
                                                                              </span>
                                                                              <a
                                                                                    download={
                                                                                          true
                                                                                    }
                                                                                    href={`${process.env.REACT_APP_SECRET_KEY}/frontend/images/catalogues/${catalogue.file}`}
                                                                              >
                                                                                    Download
                                                                              </a>
                                                                        </div>
                                                                        {/* <CatalogueItemShare>
                                                                              <button
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                          handleRequest(
                                                                                                catalogue.slug
                                                                                          )
                                                                                    }
                                                                              >
                                                                                    <i className="bx bx-share-alt"></i>
                                                                              </button>
                                                                        </CatalogueItemShare> */}
                                                                  </CatalogueItemIcon>
                                                            </CatalogueItemDetail>
                                                      </CatalogueItem>
                                                )
                                          )}
                                    </CatalogueBody>
                              ) : (
                                    <Row>
                                          <Col lg={12}>
                                                <h4>No catalogue found</h4>
                                          </Col>
                                    </Row>
                              )
                        ) : (
                              <>
                                    <div
                                          className="text-center"
                                          style={{
                                                height: '50vh',
                                                paddingTop: '20vh'
                                          }}
                                    >
                                          <Spinner
                                                animation="border"
                                                role="status"
                                          >
                                                <span className="visually-hidden">
                                                      Loading...
                                                </span>
                                          </Spinner>
                                    </div>
                              </>
                        )}
                  </Container>

                  <Modal
                        style={{ zIndex: '999999999' }}
                        show={show}
                        fullscreen={fullscreen}
                        onHide={() => setShow(false)}
                  >
                        <Modal.Header closeButton>
                              <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                              style={{ padding: '0', overflow: 'hidden' }}
                        >
                              <CatalogueView slug={slug} />
                        </Modal.Body>
                  </Modal>
            </>
      );
};

export default Catalogue;
