import React, { useState, useEffect } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import { Col, Container, Row } from 'react-bootstrap';

import axios from 'axios';
import { toast } from 'react-hot-toast';
import { AboutRow, AboutShowroomItem } from '../../../../styles/frontend/about';
import {
      TestimonialBox,
      TestimonialContent,
      TestimonialDate,
      TestimonialDescription,
      TestimonialImage,
      TestimonialTitle,
      TestimonialWrapper
} from '../../../../styles/frontend/home';

const Testimonial = () => {
      const [loading, setLoading] = useState(false);
      const [testimonials, setTestimonials] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/testimonials`, {
                        headers: {
                              apikey: process.env.REACT_APP_Api_Key
                        }
                  })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setTestimonials(response.data.testimonials);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Container fluid className="p-0 position-relative">
                        <AboutRow
                              padding="0 15px"
                              className="g-0 align-items-center"
                        >
                              <Col lg={6}>
                                    <Row className="justify-content-end g-0">
                                          <Col lg={10} className="pt-5 pb-5">
                                                <AboutShowroomItem>
                                                      <h2>
                                                            Why Choose our
                                                            Brands ?
                                                      </h2>
                                                      <p>
                                                            It's now easier than
                                                            ever to give your
                                                            space a makeover you
                                                            deserve for. Visit
                                                            your nearest
                                                            Bathnroom World or
                                                            Orientation Center
                                                            or Authorized Dealer
                                                            to know more to
                                                            choose from the wide
                                                            range of complete
                                                            bathroom and
                                                            lighting solutions.
                                                      </p>
                                                </AboutShowroomItem>
                                          </Col>
                                    </Row>
                              </Col>
                              <Col lg={6}>
                                    <TestimonialBox>
                                          <div className="testimonial__container">
                                                {!loading &&
                                                      testimonials.length >
                                                            0 && (
                                                            <Swiper
                                                                  slidesPerView={
                                                                        1
                                                                  }
                                                                  spaceBetween={
                                                                        30
                                                                  }
                                                                  centeredSlides={
                                                                        true
                                                                  }
                                                                  className="mySwiper"
                                                                  autoplay={{
                                                                        delay: 500,
                                                                        disableOnInteraction: false
                                                                  }}
                                                                  modules={
                                                                        Autoplay
                                                                  }
                                                            >
                                                                  {testimonials.map(
                                                                        (
                                                                              testimonial,
                                                                              index
                                                                        ) => (
                                                                              <SwiperSlide
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <TestimonialWrapper>
                                                                                          <TestimonialImage>
                                                                                                <img
                                                                                                      src={
                                                                                                            testimonial.image
                                                                                                      }
                                                                                                      alt={
                                                                                                            testimonial.name
                                                                                                      }
                                                                                                      width="100%"
                                                                                                      height="100%"
                                                                                                      className="img-fluid left__rounded__corner"
                                                                                                />
                                                                                          </TestimonialImage>
                                                                                          <TestimonialContent>
                                                                                                <TestimonialDate>
                                                                                                      <span>
                                                                                                            {
                                                                                                                  testimonial.date
                                                                                                            }
                                                                                                      </span>
                                                                                                </TestimonialDate>
                                                                                                <TestimonialTitle>
                                                                                                      <span>
                                                                                                            {
                                                                                                                  testimonial.name
                                                                                                            }
                                                                                                      </span>
                                                                                                </TestimonialTitle>
                                                                                                <TestimonialDescription>
                                                                                                      <p>
                                                                                                            {
                                                                                                                  testimonial.message
                                                                                                            }
                                                                                                      </p>
                                                                                                </TestimonialDescription>
                                                                                          </TestimonialContent>
                                                                                    </TestimonialWrapper>
                                                                              </SwiperSlide>
                                                                        )
                                                                  )}
                                                            </Swiper>
                                                      )}
                                          </div>
                                    </TestimonialBox>
                              </Col>
                        </AboutRow>
                  </Container>
            </>
      );
};
export default Testimonial;
