import React, { useEffect, useState } from 'react';
import DashboardLayout from '../DashboardLayout';
import { Link } from 'react-router-dom';

import $ from 'jquery';
import axios from 'axios';
import toast from 'react-hot-toast';
import {
      Col,
      Container,
      Form,
      FormControl,
      FormLabel,
      Row
} from 'react-bootstrap';
import FormButton from '../../components/common/form/FormButton';
import { DashboardBreadcrumb, DashboardContentTitle } from '../Dashboard/style';

const ChangePassword = () => {
      const [loading, setLoading] = useState(false);
      const [oldPassword, setOldPassword] = useState('');
      const [newPassword, setNewPassword] = useState('');
      const [confirmPassword, setConfirmPassword] = useState('');

      const handleSubmit = async (event) => {
            event.preventDefault();

            setLoading(true);

            const data = {
                  oldpassword: oldPassword,
                  password: newPassword,
                  password_confirmation: confirmPassword
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/customer/change-password`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                        }
                        if (response.data.result === 'error') {
                              if (response.data.message) {
                                    toast.error(response.data.message);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoading(false);
      };

      return (
            <>
                  <DashboardBreadcrumb>
                        <Container>
                              <div className="page-breadcrumb">
                                    <Link to="/dashboard">Dashboard</Link> {'>'}{' '}
                                    Change Password
                              </div>
                        </Container>
                  </DashboardBreadcrumb>
                  <DashboardLayout>
                        <DashboardContentTitle>
                              Change Password
                        </DashboardContentTitle>

                        <Row className="row">
                              <Col md={12}>
                                    <Form onSubmit={handleSubmit}>
                                          <Row className="g-3">
                                                <Col md={6}>
                                                      <FormLabel>
                                                            Current Password
                                                      </FormLabel>
                                                      <FormControl
                                                            type="password"
                                                            onChange={(event) =>
                                                                  setOldPassword(
                                                                        event
                                                                              .target
                                                                              .value
                                                                  )
                                                            }
                                                      />
                                                </Col>
                                                <Col md={6}></Col>
                                                <Col md={6}>
                                                      <FormLabel>
                                                            Password
                                                      </FormLabel>
                                                      <FormControl
                                                            type="password"
                                                            onChange={(event) =>
                                                                  setNewPassword(
                                                                        event
                                                                              .target
                                                                              .value
                                                                  )
                                                            }
                                                      />
                                                </Col>
                                                <Col md={6}>
                                                      <FormLabel>
                                                            Confirm Password
                                                      </FormLabel>
                                                      <FormControl
                                                            type="password"
                                                            onChange={(event) =>
                                                                  setConfirmPassword(
                                                                        event
                                                                              .target
                                                                              .value
                                                                  )
                                                            }
                                                      />
                                                </Col>
                                                <FormButton
                                                      title="Submit"
                                                      loading={loading}
                                                />
                                          </Row>
                                    </Form>
                              </Col>
                        </Row>
                  </DashboardLayout>
            </>
      );
};

export default ChangePassword;
