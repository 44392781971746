import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const FooterSection = styled.section`
      margin-top: 0rem;

      @media screen and (max-width: 1224px) {
            margin-bottom: 4.4rem;
      }
`;

export const FooterContainer = styled.div`
      background-color: ${(props) => props.theme.footerBackground};
      padding: 70px 0;

      @media screen and (max-width: 600px) {
            padding: 40px 0;
      }
`;

export const FooterRow = styled(Row)`
      align-items: center;
      --bs-gutter-y: 0;
      --bs-gutter-x: 0;

      @media screen and (max-width: 1200px) {
            .col-lg-6:first-child {
                  /* display: none; */
                  width: 40%;
            }

            .col-lg-6:last-child {
                  /* width: 100%; */
                  width: 60%;
            }
      }
`;

export const FooterBannerContainer = styled(Col)`
      @media screen and (max-width: 996px) {
            display: none;
      }
`;

export const FooterBanner = styled.div`
      img {
            width: 100%;
      }
`;

export const FooterContentContainer = styled(Col)`
      @media screen and (max-width: 996px) {
            width: 100% !important;
      }
`;

export const FooterContent = styled.div`
      text-align: left;
      span {
            color: ${(props) => props.theme.primaryColor};
      }
`;

export const FooterTitle = styled.div`
      margin-top: 0rem;
      padding-left: 40px;
      margin-bottom: 3rem;

      h3 {
            text-transform: uppercase;
            font-size: 40px;
            font-weight: 300;
            color: ${(props) => props.theme.white};

            span {
                  color: ${(props) => props.theme.primaryColor};
            }
      }

      @media screen and (max-width: 600px) {
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 1rem;
            h3 {
                  font-size: 28px;
            }
      }
`;

export const FooterContentGrid = styled.div`
      padding-bottom: 20px;
      border-bottom: 1px solid ${(props) => props.theme.black};
      display: flex;
      margin-top: 1rem;
      padding-left: 40px;

      &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
      }

      @media screen and (max-width: 600px) {
            overflow-x: scroll;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 5px;
            width: calc(100% - 0px);

            .footer-subscribe {
                  width: 100%;
            }

            &::-webkit-scrollbar {
                  width: 0;
            }
      }
`;

export const FooterContentWrapper = styled.div`
      margin-right: 1.5rem;

      a {
            color: ${(props) => props.theme.footerPagraphColor};
            font-size: 18px;
            padding-right: 10px;

            h4 {
                  padding-bottom: 0;
                  display: inline-flex;
            }
            &:hover {
                  color: ${(props) => props.theme.primaryColor};
            }
      }

      h4 {
            text-transform: uppercase;
            color: ${(props) => props.theme.borderColor};
            padding-bottom: 1rem;
            font-weight: 300;
            width: max-content;
      }

      h4,
      p {
            font-size: 1rem;
            margin-bottom: 0.25rem;
      }

      p {
            color: ${(props) => props.theme.footerPagraphColor};
      }

      &:last-child {
            margin-right: 0rem;
      }

      @media screen and (max-width: 600px) {
            margin-right: 0.6rem;

            &.ps-5 {
                  padding-left: 0.6rem !important;
            }

            a {
                  font-size: 16px;
            }

            h4 {
                  padding-bottom: 0px;
            }

            h4,
            p {
                  font-size: 14px;
            }
      }
`;

export const FooterSubscriber = styled.div``;

export const FooterSubscriberTitle = styled.div`
      h4 {
            text-transform: uppercase;
            font-size: 1rem;
            color: ${(props) => props.theme.borderColor};
            font-weight: 300;
      }

      @media screen and (max-width: 600px) {
            h4 {
                  font-size: 14px;
            }
      }
`;

export const FooterSubscriberBox = styled.div`
      background-color: ${(props) => props.theme.textColor};
      width: 400px;
      height: auto;
      margin-top: 1.5rem;
      border-radius: 0.375rem;

      form {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 1rem;
            padding-right: 0;

            input {
                  outline: none;
                  border: none;
                  background: transparent;
                  width: calc(100% - 38px);
            }

            button {
                  background-color: ${(props) => props.theme.primaryColor};

                  i {
                        color: ${(props) => props.theme.white};
                  }

                  &:hover {
                        background-color: ${(props) =>
                              props.theme.secondaryColor};
                  }
            }
      }

      @media screen and (max-width: 600px) {
            width: 100%;

            form {
                  width: 100%;
            }

            input {
                  width: calc(100% - 38px);
            }
      }
`;

export const FooterCredential = styled.div`
      display: flex;
      justify-content: space-between;
      background-color: #0f0f0f;
      font-size: 14px;
      padding: 20px 50px;
      padding-bottom: 20px;
      color: ${(props) => props.theme.borderColor};

      @media screen and (max-width: 600px) {
            flex-direction: column;
            padding-left: 20px;
            padding-right: 20px;
            font-size: 13px;
      }

      a,
      span {
            color: #05b1a9;
      }
`;
