import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import {
      CareerCard,
      CareerContainer,
      CareerHeader,
      CareerHeaderIcon,
      CareerHeaderImage,
      CareerLink,
      CareerTable
} from '../../styles/frontend/career';
import { ClockIcon } from '../../components/icons';
import { Link, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';

export const CareerDetail = () => {
      const { careerId } = useParams();

      const [loading, setLoading] = useState(false);
      const [career, setCareer] = useState([]);
      const [jobs, setJobs] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/careers/${careerId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCareer(response.data.job);
                              setJobs(response.data.jobs);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Breadcrumb
                        otherTitle="Career"
                        otherLink="/careers"
                        title="Career Details"
                        back="/careers"
                  />

                  <CareerContainer>
                        <Row>
                              <Col lg={8}>
                                    <CareerCard>
                                          <Card.Body>
                                                <CareerTable hover>
                                                      <tbody>
                                                            <tr>
                                                                  <td colspan="3">
                                                                        <strong>
                                                                              Basic
                                                                              Information
                                                                        </strong>
                                                                  </td>
                                                            </tr>
                                                            <tr>
                                                                  <td className="w-25">
                                                                        Title
                                                                  </td>
                                                                  <td className="w-3">
                                                                        :
                                                                  </td>
                                                                  <td className="w-64">
                                                                        {
                                                                              career.title
                                                                        }
                                                                  </td>
                                                            </tr>
                                                            <tr>
                                                                  <td className="w-25">
                                                                        Job Type
                                                                  </td>
                                                                  <td className="w-3">
                                                                        :
                                                                  </td>
                                                                  <td className="w-64">
                                                                        {
                                                                              career.type
                                                                        }
                                                                  </td>
                                                            </tr>
                                                            <tr>
                                                                  <td className="w-25">
                                                                        No. of
                                                                        Vacancy
                                                                  </td>
                                                                  <td className="w-3">
                                                                        :
                                                                  </td>
                                                                  <td className="w-64">
                                                                        {
                                                                              career.vacancy
                                                                        }
                                                                  </td>
                                                            </tr>
                                                            <tr>
                                                                  <td className="w-25">
                                                                        Salary
                                                                  </td>
                                                                  <td className="w-3">
                                                                        :
                                                                  </td>
                                                                  <td className="w-64">
                                                                        {
                                                                              career.salary
                                                                        }
                                                                  </td>
                                                            </tr>
                                                            <tr>
                                                                  <td className="w-25">
                                                                        Qualification
                                                                  </td>
                                                                  <td className="w-3">
                                                                        :
                                                                  </td>
                                                                  <td className="w-64">
                                                                        {
                                                                              career.education
                                                                        }
                                                                  </td>
                                                            </tr>
                                                            <tr>
                                                                  <td className="w-25">
                                                                        Experience
                                                                  </td>
                                                                  <td className="w-3">
                                                                        :
                                                                  </td>
                                                                  <td className="w-64">
                                                                        {
                                                                              career.experience
                                                                        }
                                                                  </td>
                                                            </tr>
                                                            <tr>
                                                                  <td className="w-25">
                                                                        Deadline
                                                                  </td>
                                                                  <td className="w-3">
                                                                        :
                                                                  </td>
                                                                  <td className="w-64">
                                                                        {
                                                                              career.deadline
                                                                        }
                                                                  </td>
                                                            </tr>
                                                      </tbody>
                                                </CareerTable>
                                                <CareerTable className="table  mt-4">
                                                      <tbody>
                                                            <tr>
                                                                  <td colspan="3">
                                                                        <strong>
                                                                              Other
                                                                              Details
                                                                        </strong>
                                                                  </td>
                                                            </tr>
                                                            <tr>
                                                                  <td colspan="3">
                                                                        <div
                                                                              dangerouslySetInnerHTML={{
                                                                                    __html: career.description
                                                                              }}
                                                                        ></div>
                                                                  </td>
                                                            </tr>
                                                      </tbody>
                                                </CareerTable>
                                                <CareerLink className="text-end mb-3">
                                                      <Link
                                                            to={`/careers/${careerId}/apply`}
                                                      >
                                                            Apply Now
                                                      </Link>
                                                </CareerLink>
                                          </Card.Body>
                                    </CareerCard>
                              </Col>

                              <Col lg={4}>
                                    {!loading && jobs.length > 0 && (
                                          <>
                                                <div className="border-bottom pb-3">
                                                      <h4>Other Jobs</h4>
                                                </div>
                                                {jobs.map((job, index) => (
                                                      <div
                                                            className="mt-3"
                                                            key={index}
                                                      >
                                                            <CareerHeader>
                                                                  <Link
                                                                        to={`/careers/${job.slug}`}
                                                                  >
                                                                        <CareerHeaderImage>
                                                                              <img
                                                                                    src="/images/png/headerlogo.png"
                                                                                    alt={
                                                                                          job.title
                                                                                    }
                                                                              />
                                                                              <div>
                                                                                    {
                                                                                          job.title
                                                                                    }
                                                                                    <CareerHeaderIcon
                                                                                          fontSize="16px"
                                                                                          className="mt-2"
                                                                                          fontWeigth="500"
                                                                                    >
                                                                                          <ClockIcon />
                                                                                          <div>
                                                                                                Posted
                                                                                                {
                                                                                                      job.date
                                                                                                }
                                                                                          </div>
                                                                                    </CareerHeaderIcon>
                                                                              </div>
                                                                        </CareerHeaderImage>
                                                                  </Link>
                                                            </CareerHeader>
                                                      </div>
                                                ))}
                                          </>
                                    )}
                              </Col>
                        </Row>
                  </CareerContainer>
            </>
      );
};
