import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CatalogueBox, CatalogueDetail, CatalogueDownload } from '../../../../styles/frontend/home';

const Catalogue = () => {
      return (
            <>
                  <Container>
                        <CatalogueBox>
                              <Row>
                                    <Col>
                                          <CatalogueDownload>
                                                <span>
                                                      EXPLORE OUR WIDE RANGE OF
                                                      CATALOGUE
                                                </span>
                                                <h2>Product Catalogue</h2>
                                          </CatalogueDownload>
                                    </Col>
                                    <Col>
                                          <CatalogueDetail>
                                                <p>
                                                      If you'd like to view our
                                                      latest product catalogue
                                                      you can download it here.
                                                </p>
                                                <div>
                                                      <Link to="/catalogues">
                                                            View Catalogue
                                                      </Link>
                                                </div>
                                          </CatalogueDetail>
                                    </Col>
                              </Row>
                        </CatalogueBox>
                  </Container>
            </>
      );
};

export default Catalogue;
