import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap');
  :root {
        --primary-color: #008080;
        --secondary-color: #19504c;
        --black-color: #091e1c;
        --pitch-black: #010101;
        --white-color: #ffffff;
        --text-color: #611212;
        --divider-color: #18181833;
        --paragraph-color: #646464;
        --border-color: #e4e4e4;
        --footer-border-color: #d2d2d2;
        --footer-paragraph-color: #f5f5f5;
        --background-color: #1c1c1c;
  }

  @font-face {
    font-family: 'HelveticaNowDisplay-Light';
    src: url('/fonts/HelveticaNowDisplay-Light.woff') format('truetype');
    font-weight: 100;
  }

  @font-face {
    font-family: 'HelveticaNowDisplay-Medium';
    src: url('/fonts/HelveticaNowDisplay-Medium.woff') format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'HelveticaNowDisplay-Regular';
    src: url('/fonts/HelveticaNowDisplay-Regular.woff') format('truetype');
    font-weight: 400;
  }

  * {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      font-family: 'HelveticaNowDisplay-Light', sans-serif;
}


ul,
li {
    list-style-type: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  color: inherit;
  margin: 0;
}
a:hover {
  text-decoration: none !important;
}



`;
