import React, { useState } from 'react';
import {
      DashboardTable,
      DashboardTableBody,
      DashboardTableContainer,
      DashboardTableContent,
      DashboardTableData,
      DashboardTableDetail,
      DashboardTableGrid,
      DashboardTableGroup,
      DashboardTableHeader,
      DashboardTableTitle
} from './style';
import { Link } from 'react-router-dom';
import { Loading } from '../../styles/components/form/Loading';
import axios from 'axios';
import toast from 'react-hot-toast';

const DashboardTableList = ({ orders, loading, title, loadData }) => {
      const [loadingData, setLoadingData] = useState(false);

      const cancelOrder = async (order) => {
            setLoadingData(true);
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/customer/orders/${order.slug}`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem('token')
                                    )}`
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              loadData();
                              toast.success(response.data.message);
                        }
                        if (response.data.result === 'error') {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoadingData(false);
      };

      return (
            <>
                  <DashboardTable>
                        <DashboardTableContainer>
                              <DashboardTableContent>
                                    <DashboardTableTitle>
                                          {title || 'Recent Order'}
                                    </DashboardTableTitle>
                                    <DashboardTableGrid>
                                          <DashboardTableGroup>
                                                <DashboardTableBody>
                                                      <DashboardTableDetail>
                                                            <thead>
                                                                  <tr>
                                                                        <DashboardTableHeader>
                                                                              ID
                                                                        </DashboardTableHeader>
                                                                        <DashboardTableHeader>
                                                                              OrderTime
                                                                        </DashboardTableHeader>
                                                                        <DashboardTableHeader>
                                                                              Method
                                                                        </DashboardTableHeader>
                                                                        <DashboardTableHeader>
                                                                              Status
                                                                        </DashboardTableHeader>
                                                                        <DashboardTableHeader>
                                                                              Total
                                                                        </DashboardTableHeader>
                                                                        <DashboardTableHeader>
                                                                              Action
                                                                        </DashboardTableHeader>
                                                                  </tr>
                                                            </thead>
                                                            <tbody>
                                                                  {!loading ? (
                                                                        orders?.length >
                                                                        0 ? (
                                                                              orders?.map(
                                                                                    (
                                                                                          item,
                                                                                          index
                                                                                    ) => (
                                                                                          <tr
                                                                                                key={
                                                                                                      index
                                                                                                }
                                                                                          >
                                                                                                <DashboardTableData align="left">
                                                                                                      <span className="dashboard__table-id">
                                                                                                            {
                                                                                                                  item.order_name
                                                                                                            }
                                                                                                      </span>
                                                                                                </DashboardTableData>
                                                                                                <DashboardTableData>
                                                                                                      <span className="text-sm">
                                                                                                            {
                                                                                                                  item.date
                                                                                                            }
                                                                                                      </span>
                                                                                                </DashboardTableData>
                                                                                                <DashboardTableData>
                                                                                                      <span className="text-sm">
                                                                                                            {
                                                                                                                  item.payment
                                                                                                            }
                                                                                                      </span>
                                                                                                </DashboardTableData>
                                                                                                <DashboardTableData>
                                                                                                      {item.status ===
                                                                                                            0 && (
                                                                                                            <span className="text-warning">
                                                                                                                  Pending
                                                                                                            </span>
                                                                                                      )}
                                                                                                      {item.status ===
                                                                                                            1 && (
                                                                                                            <span className="text-secondary">
                                                                                                                  Processing
                                                                                                            </span>
                                                                                                      )}
                                                                                                      {item.status ===
                                                                                                            2 && (
                                                                                                            <span className="text-primary">
                                                                                                                  Shipping
                                                                                                            </span>
                                                                                                      )}
                                                                                                      {item.status ===
                                                                                                            3 && (
                                                                                                            <span className="text-success">
                                                                                                                  Delivered
                                                                                                            </span>
                                                                                                      )}
                                                                                                      {item.status ===
                                                                                                            4 && (
                                                                                                            <span className="text-danger">
                                                                                                                  Rejected
                                                                                                            </span>
                                                                                                      )}
                                                                                                      {item.status ===
                                                                                                            5 && (
                                                                                                            <span className="text-danger">
                                                                                                                  Cancelled
                                                                                                            </span>
                                                                                                      )}
                                                                                                </DashboardTableData>
                                                                                                <DashboardTableData weight="600">
                                                                                                      Rs{' '}
                                                                                                      {
                                                                                                            item.total_amount
                                                                                                      }
                                                                                                </DashboardTableData>
                                                                                                <DashboardTableData>
                                                                                                      <Link
                                                                                                            to={`/my-orders/${item.slug}`}
                                                                                                            className={`btn btn-success btn-sm ${
                                                                                                                  item.status ===
                                                                                                                  0
                                                                                                                        ? 'me-3'
                                                                                                                        : ''
                                                                                                            }`}
                                                                                                      >
                                                                                                            View
                                                                                                      </Link>
                                                                                                      {item.status ===
                                                                                                            0 && (
                                                                                                            <button
                                                                                                                  type="button"
                                                                                                                  onClick={() =>
                                                                                                                        cancelOrder(
                                                                                                                              item
                                                                                                                        )
                                                                                                                  }
                                                                                                                  style={{
                                                                                                                        width: '60px'
                                                                                                                  }}
                                                                                                                  className="btn btn-warning btn-sm text-center"
                                                                                                            >
                                                                                                                  Cancel
                                                                                                            </button>
                                                                                                      )}
                                                                                                </DashboardTableData>
                                                                                          </tr>
                                                                                    )
                                                                              )
                                                                        ) : (
                                                                              <>
                                                                                    <tr>
                                                                                          <td
                                                                                                colSpan={
                                                                                                      6
                                                                                                }
                                                                                                className="text-center"
                                                                                          >
                                                                                                No
                                                                                                order
                                                                                                found
                                                                                          </td>
                                                                                    </tr>
                                                                              </>
                                                                        )
                                                                  ) : (
                                                                        <>
                                                                              <tr>
                                                                                    <td
                                                                                          colSpan={
                                                                                                6
                                                                                          }
                                                                                          className="text-center py-2"
                                                                                    >
                                                                                          <Loading />
                                                                                    </td>
                                                                              </tr>
                                                                        </>
                                                                  )}
                                                            </tbody>
                                                      </DashboardTableDetail>
                                                </DashboardTableBody>
                                          </DashboardTableGroup>
                                    </DashboardTableGrid>
                              </DashboardTableContent>
                        </DashboardTableContainer>
                  </DashboardTable>
            </>
      );
};

export default DashboardTableList;
