import React from 'react';

const StoreIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">storefront</span>
            </>
      );
};

export default StoreIcon;
