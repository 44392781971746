import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import axios from 'axios';
import { SlideButton, SlideContent } from './style';
import { HomeHeaderSwiper } from '../../common/header/style';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';

const SliderBanner = () => {
      const [loading, setLoading] = useState(false);
      const [banners, setBanners] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/banners`, {
                        headers: {
                              apikey: process.env.REACT_APP_Api_Key
                        }
                  })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBanners(response.data.banners);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  {!loading && banners.length > 0 && (
                        <HomeHeaderSwiper
                              spaceBetween={0}
                              effect={'fade'}
                              navigation={false}
                              autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false
                              }}
                              pagination={false}
                              modules={[
                                    Autoplay,
                                    EffectFade,
                                    Navigation,
                                    Pagination
                              ]}
                              className="mySwiper"
                        >
                              {banners.map((banner, index) => (
                                    <SwiperSlide key={index}>
                                          <SlideContent>
                                                <h2 className="text-uppercase ">
                                                      {banner.title}
                                                </h2>
                                                <p className="text-left">
                                                      {banner.description}
                                                </p>

                                                {/* <SlideButton
                                                      to="/who-we-are"
                                                      className=" position"
                                                >
                                                      About Us
                                                </SlideButton> */}
                                          </SlideContent>
                                          <div className="swiper__image">
                                                <img
                                                      src={banner.image}
                                                      alt={banner.title}
                                                      width="100%"
                                                      height="100%"
                                                />
                                          </div>
                                    </SwiperSlide>
                              ))}
                        </HomeHeaderSwiper>
                  )}
            </>
      );
};
export default SliderBanner;
