import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../components/common/breadcrumb';

import $ from 'jquery';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import {
      CareerCard,
      CareerContainer,
      CareerTitle
} from '../../styles/frontend/career';
import { Card, Col, Form, Row } from 'react-bootstrap';
import FormInput from '../../components/common/form/FormInput';
import FormSelect from '../../components/common/form/FormSelect';
import FormFile from '../../components/common/form/FormFile';
import FormTextarea from '../../components/common/form/FormTextarea';
import FormButton from '../../components/common/form/FormButton';

const options = [
      {
            id: 'Male',
            title: 'Male'
      },
      {
            id: 'Female',
            title: 'Female'
      },
      {
            id: 'Other',
            title: 'Other'
      }
];

const CareerApply = () => {
      const { careerId } = useParams();

      const [loading, setLoading] = useState(false);
      const [career, setCareer] = useState([]);

      const [firstName, setFirstName] = useState('');
      const [lastName, setLastName] = useState('');
      const [email, setEmail] = useState('');
      const [phoneNumber, setPhoneNumber] = useState('');
      const [dateOfBirth, setDateOfBirth] = useState('');
      const [gender, setGender] = useState('');
      const [currentAddress, setCurrentAddress] = useState('');
      const [permanentAddress, setPermanentAddress] = useState('');
      const [qualification, setQualification] = useState('');
      const [experience, setExperience] = useState('');
      const [resume, setResume] = useState('');
      const [aboutYourself, setAboutYourself] = useState('');

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/careers/${careerId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCareer(response.data.job);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleForm = async (event) => {
            event.preventDefault();

            setLoading(true);

            const data = {
                  first_name: firstName,
                  last_name: lastName,
                  email: email,
                  phone_number: phoneNumber,
                  date_of_birth: dateOfBirth,
                  gender: gender,
                  current_address: currentAddress,
                  permanent_address: permanentAddress,
                  qualification: qualification,
                  experience: experience,
                  resume: resume,
                  about_yourself: aboutYourself
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/careers/${careerId}`,
                        data,
                        {
                              headers: {
                                    'Content-Type': 'multipart/form-data',
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        }
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                              $('form').trigger('reset');
                        }

                        if (response.data.result === 'error') {
                              if (response.data.message.first_name) {
                                    toast.error(
                                          response.data.message.first_name
                                    );
                              }
                              if (response.data.message.last_name) {
                                    toast.error(
                                          response.data.message.last_name
                                    );
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                              if (response.data.message.gender) {
                                    toast.error(response.data.message.gender);
                              }
                              if (response.data.message.date_of_birth) {
                                    toast.error(
                                          response.data.message.date_of_birth
                                    );
                              }
                              if (response.data.message.current_address) {
                                    toast.error(
                                          response.data.message.current_address
                                    );
                              }
                              if (response.data.message.permanent_address) {
                                    toast.error(
                                          response.data.message
                                                .permanent_address
                                    );
                              }
                              if (response.data.message.qualification) {
                                    toast.error(
                                          response.data.message.qualification
                                    );
                              }
                              if (response.data.message.experience) {
                                    toast.error(
                                          response.data.message.experience
                                    );
                              }
                              if (response.data.message.resume) {
                                    toast.error(response.data.message.resume);
                              }
                              if (response.data.message.about_yourself) {
                                    toast.error(
                                          response.data.message.about_yourself
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <Breadcrumb
                        otherTitle={`${career.title}`}
                        otherLink={`/careers/${career.slug}`}
                        title={`Apply for ${career.title}`}
                        back={`/careers/${career.slug}`}
                  />

                  <CareerContainer>
                        <Row className="justify-content-center">
                              <Col lg={8}>
                                    <CareerCard>
                                          <Card.Body>
                                                <Form onSubmit={handleForm}>
                                                      <Row className="g-3">
                                                            <Col lg={12}>
                                                                  <CareerTitle>
                                                                        Personal
                                                                        Details
                                                                  </CareerTitle>
                                                            </Col>
                                                            <FormInput
                                                                  title="First Name"
                                                                  required={
                                                                        true
                                                                  }
                                                                  handleChange={
                                                                        setFirstName
                                                                  }
                                                            />
                                                            <FormInput
                                                                  title="Last Name"
                                                                  required={
                                                                        true
                                                                  }
                                                                  handleChange={
                                                                        setLastName
                                                                  }
                                                            />
                                                            <FormSelect
                                                                  title="Gender"
                                                                  required={
                                                                        true
                                                                  }
                                                                  options={
                                                                        options
                                                                  }
                                                                  setData={
                                                                        setGender
                                                                  }
                                                            />
                                                            <FormInput
                                                                  title="Date of Birth"
                                                                  type="date"
                                                                  required={
                                                                        true
                                                                  }
                                                                  handleChange={
                                                                        setDateOfBirth
                                                                  }
                                                            />
                                                            <FormInput
                                                                  title="Email Address"
                                                                  type="email"
                                                                  required={
                                                                        true
                                                                  }
                                                                  handleChange={
                                                                        setEmail
                                                                  }
                                                            />
                                                            <FormInput
                                                                  title="Phone No."
                                                                  required={
                                                                        true
                                                                  }
                                                                  handleChange={
                                                                        setPhoneNumber
                                                                  }
                                                            />
                                                            <Col lg={12}>
                                                                  <CareerTitle>
                                                                        Address
                                                                        Details
                                                                  </CareerTitle>
                                                            </Col>
                                                            <FormInput
                                                                  title="Current Address"
                                                                  required={
                                                                        true
                                                                  }
                                                                  handleChange={
                                                                        setCurrentAddress
                                                                  }
                                                            />
                                                            <FormInput
                                                                  title="Permanent Address"
                                                                  required={
                                                                        true
                                                                  }
                                                                  handleChange={
                                                                        setPermanentAddress
                                                                  }
                                                            />
                                                            <Col lg={12}>
                                                                  <CareerTitle>
                                                                        Education
                                                                        &
                                                                        Experince
                                                                        Detail
                                                                  </CareerTitle>
                                                            </Col>
                                                            <FormInput
                                                                  title="Qualification"
                                                                  required={
                                                                        true
                                                                  }
                                                                  handleChange={
                                                                        setQualification
                                                                  }
                                                            />
                                                            <FormInput
                                                                  title="Experience"
                                                                  required={
                                                                        true
                                                                  }
                                                                  handleChange={
                                                                        setExperience
                                                                  }
                                                            />
                                                            <FormFile
                                                                  title="Attach your Resume"
                                                                  required={
                                                                        true
                                                                  }
                                                                  handleChange={
                                                                        setResume
                                                                  }
                                                            />
                                                            <FormTextarea
                                                                  title="About your self"
                                                                  classes={12}
                                                                  required={
                                                                        true
                                                                  }
                                                                  handleChange={
                                                                        setAboutYourself
                                                                  }
                                                            />
                                                            <FormButton
                                                                  loading={
                                                                        loading
                                                                  }
                                                            />
                                                      </Row>
                                                </Form>
                                          </Card.Body>
                                    </CareerCard>
                              </Col>
                        </Row>
                  </CareerContainer>
            </>
      );
};

export default CareerApply;
