import styled from 'styled-components';

export const InvoiceContainer = styled.div`
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      padding-top: 0rem;
      padding-bottom: 2.5rem;
      max-width: 1536px;

      @media screen and (min-width: 640px) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
      }
`;

export const InvoiceTitleContainer = styled.div`
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      padding-left: 1rem;
      padding-right: 1rem;
      margin-bottom: 1.25rem;
      border-radius: 0.375rem;
`;

export const InvoiceContent = styled.div`
      border-radius: 0.5rem;
      background-color: #fff;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
`;

export const InvoiceContentContainer = styled.div`
      padding: 2rem;
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
`;

export const InvoiceContentBody = styled.div`
      display: flex;
      padding-bottom: 1rem;
      flex-direction: column;
      justify-content: space-between;
      border-bottom-width: 1px;
      border-color: #f2f2f2;

      @media screen and (min-width: 768px) {
            flex-direction: row;
      }

      @media screen and (min-width: 1024px) {
            flex-direction: row;
            align-items: center;
      }
`;

export const InvoiceContentBodyTitle = styled.h1`
      font-size: 1.5rem;
      line-height: 2rem;
      text-transform: uppercase;
      font-weight: 700;
`;

export const InvoiceContentBodyDetail = styled.div`
      text-align: right;

      @media screen and (min-width: 1024px) {
            text-align: right;
      }
`;

export const InvoiceContentBodySummary = styled.p`
      color: #6b7280;
      font-size: 0.875rem;
      line-height: 1.25rem;
`;

export const InvoiceContentBodyImage = styled.h2`
      margin-top: 1rem;
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 600;

      @media screen and (min-width: 640px) {
            margin-top: 0;
      }

      @media screen and (min-width: 1024px) {
            margin-top: 0;
      }
`;

export const InvoiceDetail = styled.div`
      display: flex;
      padding-top: 1rem;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (min-width: 768px) {
            flex-direction: row;
      }

      @media screen and (min-width: 1024px) {
            flex-direction: row;
      }
`;

export const InvoiceDetailSummary = styled.span`
      display: block;
      color: #6b7280;
      font-size: 0.875rem;
      line-height: 1.25rem;
`;

export const InvoiceDetailTitle = styled.span`
      display: block;
      color: #4b5563;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 700;
      text-transform: uppercase;

      @media screen and (min-width: 1024px) {
            margin-bottom: 0;
      }
`;

export const InvoiceDetailContent = styled.div`
      display: flex;
      margin-bottom: 0.75rem;
      flex-direction: column;

      @media screen and (min-width: 768px) {
            margin-bottom: 0;
      }
`;

export const InvoiceDetailDescription = styled.div`
      display: flex;
      text-align: left;
      flex-direction: column;

      @media screen and (min-width: 1024px) {
            text-align: right;
      }
`;

export const InvoiceTable = styled.div`
      overflow: hidden;
      padding-left: 2rem;
      padding-right: 2rem;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;

      @media screen and (min-width: 1024px) {
            overflow: visible;
      }
`;

export const InvoiceTableContainer = styled.div`
      overflow-x: auto;
      margin-top: -0.5rem;
      margin-bottom: -0.5rem;
`;

export const InvoiceTableContent = styled.table`
      min-width: 100%;
      border-width: 1px;
      border-color: #f3f4f6;
      border-top-width: 1px;
      border-color: #e5e7eb;
      table-layout: auto;

      thead {
            background-color: #f2f2f2;
      }
`;

export const InvoiceTableHeader = styled.th`
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      color: #374151;
      font-weight: 600;
      letter-spacing: 0.05em;
      text-transform: uppercase;
`;

export const InvoiceTableData = styled.th`
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      font-weight: 400;
      white-space: nowrap;
`;

export const InvoiceTableName = styled.td`
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      color: #6b7280;
      font-weight: 400;
      white-space: nowrap;
      display: flex;
      align-items: center;
      grid-gap: 10px;

      img {
            object-fit: contain;
            background-color: #f2f2f2;
            border-radius: 5px;
            padding: 5px;
      }
`;

export const InvoiceTableQuantity = styled.td`
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      font-weight: 700;
      text-align: center;
      white-space: nowrap;
`;

export const InvoiceTableTotal = styled.td`
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      color: #ef4444;
      font-weight: 700;
      text-align: right;
      white-space: nowrap;
`;

export const InvoiceTablePrice = styled.td`
      ${InvoiceTableQuantity}
      color: red;
      text-align: center;
`;

export const InvoicePayment = styled.div`
      padding: 2.5rem;
      border-top-width: 1px;
      border-bottom-width: 1px;
      border-color: #f3f4f6;
`;

export const InvoicePaymentContent = styled.div`
      display: flex;
      padding-top: 1rem;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (min-width: 768px) {
            flex-direction: row;
      }

      @media screen and (min-width: 1024px) {
            flex-direction: row;
      }
`;
