import React, { useContext, useState } from 'react';
import {
      FooterBanner,
      FooterBannerContainer,
      FooterContainer,
      FooterContent,
      FooterContentContainer,
      FooterContentGrid,
      FooterContentWrapper,
      FooterCredential,
      FooterRow,
      FooterSection,
      FooterSubscriberBox,
      FooterSubscriberTitle,
      FooterTitle
} from '../../styles/layouts/footer';
import { Link } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Form, Spinner } from 'react-bootstrap';
import { HiddenText } from '../../components/common/HiddenText';
import { FooterMobile, MobileContent, MobileItem } from './style';
import {
      HeartIcon,
      HomeIcon,
      ShippingIcon,
      ShoppingCart,
      StoreIcon,
      UserIcon
} from '../../components/icons';
import CartContext from '../../stores/CartContext';
import WishlistContext from '../../stores/WishlistContext';

const Footer = () => {
      const [loading, setLoading] = useState(false);
      const cartCtx = useContext(CartContext);
      const wishlistCtx = useContext(WishlistContext);

      const handleForm = async (event) => {
            setLoading(true);
            event.preventDefault();

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/subscribers`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success(response.data.message);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      return (
            <>
                  <FooterSection>
                        <FooterContainer>
                              <FooterRow>
                                    <FooterBannerContainer lg={6}>
                                          <FooterBanner>
                                                <img
                                                      src="/images/img/footer.jpg"
                                                      alt="Bathnroom Footer Banner"
                                                      width="100%"
                                                      height="100%"
                                                />
                                          </FooterBanner>
                                    </FooterBannerContainer>
                                    <FooterContentContainer lg={6}>
                                          <FooterContent>
                                                <FooterTitle>
                                                      <h3>
                                                            company{' '}
                                                            <span>
                                                                  overview
                                                            </span>
                                                      </h3>
                                                </FooterTitle>
                                                <FooterContentGrid>
                                                      <FooterContentWrapper>
                                                            <h4>
                                                                  Head Office
                                                                  Address
                                                            </h4>
                                                            <p>Tripura Marg</p>
                                                            <p>
                                                                  (Old Bakery
                                                                  Cafe)
                                                            </p>
                                                      </FooterContentWrapper>
                                                      <FooterContentWrapper>
                                                            <h4>
                                                                  Chabahil
                                                                  Branch
                                                            </h4>
                                                            <p>
                                                                  Charumati
                                                                  Bhawan
                                                            </p>
                                                            <p>
                                                                  (Opposite to
                                                                  Om Hospital)
                                                            </p>
                                                      </FooterContentWrapper>
                                                      <FooterContentWrapper>
                                                            <h4>
                                                                  Pepsicola
                                                                  Branch
                                                            </h4>
                                                            <p>Chhapro</p>
                                                      </FooterContentWrapper>
                                                </FooterContentGrid>
                                                <FooterContentGrid>
                                                      <FooterContentWrapper>
                                                            <h4>Contact Us</h4>
                                                            <p>
                                                                  +977-1-5340610
                                                            </p>
                                                            <p>
                                                                  info@bathnroom.com
                                                            </p>
                                                      </FooterContentWrapper>
                                                      <FooterContentWrapper>
                                                            <h4>
                                                                  Working Hours
                                                            </h4>
                                                            <p>
                                                                  10:00Am -
                                                                  6:00PM
                                                            </p>
                                                            <p>
                                                                  (Sunday -
                                                                  Friday)
                                                            </p>
                                                      </FooterContentWrapper>

                                                      <FooterContentWrapper className="ps-5">
                                                            <h4>
                                                                  Social Media
                                                            </h4>
                                                            <p>
                                                                  <Link
                                                                        to="https://www.facebook.com/BathNRoom"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                  >
                                                                        <HiddenText>
                                                                              Bathnroom
                                                                              Pvt.Ltd.
                                                                              Official
                                                                              Facebook
                                                                              Page
                                                                        </HiddenText>
                                                                        <i className="bx bxl-facebook"></i>
                                                                  </Link>
                                                                  <Link
                                                                        to="https://www.instagram.com/bathnroomtrade/"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        title="Bathnroom Pvt.Ltd. Official Instagram Page"
                                                                  >
                                                                        <HiddenText>
                                                                              Bathnroom
                                                                              Pvt.Ltd.
                                                                              Official
                                                                              Instagram
                                                                              Page
                                                                        </HiddenText>
                                                                        <i className="bx bxl-instagram"></i>
                                                                  </Link>
                                                                  <Link
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        title="Bathnroom Pvt.Ltd. Official Linkedin Page"
                                                                        to="https://www.linkedin.com/company/bathnroom"
                                                                  >
                                                                        <HiddenText>
                                                                              Bathnroom
                                                                              Pvt.Ltd.
                                                                              Official
                                                                              Linkedin
                                                                              Page
                                                                        </HiddenText>
                                                                        <i className="bx bxl-linkedin"></i>
                                                                  </Link>
                                                                  <Link
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        title="Bathnroom Pvt.Ltd. Official Pinterest Page"
                                                                        to="https://www.pinterest.com/bathnroomtrade"
                                                                  >
                                                                        <HiddenText>
                                                                              Bathnroom
                                                                              Pvt.Ltd.
                                                                              Official
                                                                              Pinterest
                                                                              Page
                                                                        </HiddenText>
                                                                        <i className="bx bxl-pinterest-alt"></i>
                                                                  </Link>
                                                                  <Link
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        title="Bathnroom Pvt.Ltd. Official Twitter Page"
                                                                        to="https://twitter.com/bathnroom"
                                                                  >
                                                                        <HiddenText>
                                                                              Bathnroom
                                                                              Pvt.Ltd.
                                                                              Official
                                                                              Twitter
                                                                              Page
                                                                        </HiddenText>
                                                                        <i className="bx bxl-twitter"></i>
                                                                  </Link>
                                                                  <Link
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        title="Bathnroom Pvt.Ltd. Official YouTube Page"
                                                                        to="https://www.youtube.com/@bathnroom"
                                                                  >
                                                                        <HiddenText>
                                                                              Bathnroom
                                                                              Pvt.Ltd.
                                                                              Official
                                                                              YouTube
                                                                              Page
                                                                        </HiddenText>
                                                                        <i className="bx bxl-youtube"></i>
                                                                  </Link>
                                                            </p>
                                                            {/* <p>
                                                                  <Link to="">
                                                                        <i className="bx bxl-tiktok"></i>
                                                                  </Link>
                                                                  <Link>
                                                                        <i className="bx bxl-youtube"></i>
                                                                  </Link>
                                                                  <Link>
                                                                        <i className="bx bxl-twitter"></i>
                                                                  </Link>
                                                            </p> */}
                                                      </FooterContentWrapper>
                                                </FooterContentGrid>
                                                <FooterContentGrid>
                                                      <FooterContentWrapper>
                                                            <Link to="/faqs">
                                                                  <h4>FAQs</h4>
                                                            </Link>
                                                      </FooterContentWrapper>
                                                      <FooterContentWrapper>
                                                            <Link to="/blogs">
                                                                  <h4>Blogs</h4>
                                                            </Link>
                                                      </FooterContentWrapper>
                                                      <FooterContentWrapper>
                                                            <Link to="/careers">
                                                                  <h4>
                                                                        Career
                                                                  </h4>
                                                            </Link>
                                                      </FooterContentWrapper>
                                                      <FooterContentWrapper>
                                                            <Link to="/dealer-enquiry">
                                                                  <h4>
                                                                        Dealer
                                                                        Enquiry
                                                                  </h4>
                                                            </Link>
                                                      </FooterContentWrapper>
                                                      <FooterContentWrapper>
                                                            <Link to="/terms-and-conditions">
                                                                  <h4>
                                                                        Company
                                                                        Policy
                                                                  </h4>
                                                            </Link>
                                                      </FooterContentWrapper>
                                                </FooterContentGrid>
                                                <FooterContentGrid>
                                                      <div className="footer-subscribe">
                                                            <FooterSubscriberTitle>
                                                                  <h4>
                                                                        Subscribe
                                                                        to get
                                                                        daily
                                                                        updates.
                                                                  </h4>
                                                            </FooterSubscriberTitle>
                                                            <FooterSubscriberBox>
                                                                  <Form
                                                                        onSubmit={
                                                                              handleForm
                                                                        }
                                                                  >
                                                                        <input
                                                                              type="email"
                                                                              placeholder="Enter Your Email:"
                                                                        />
                                                                        <button
                                                                              aria-label="Subscribe"
                                                                              type="submit"
                                                                              className="btn rounded-0  rounded-end-1"
                                                                        >
                                                                              {loading ? (
                                                                                    <Spinner />
                                                                              ) : (
                                                                                    <i className="bx bxs-paper-plane"></i>
                                                                              )}
                                                                        </button>
                                                                  </Form>
                                                            </FooterSubscriberBox>
                                                      </div>
                                                </FooterContentGrid>
                                          </FooterContent>
                                    </FooterContentContainer>
                              </FooterRow>
                        </FooterContainer>
                        <FooterCredential>
                              <div className="footer-copyright">
                                    Copyright ©{' '}
                                    <span>
                                          Bath N Room Trade Concern Pvt. LTD.{' '}
                                    </span>{' '}
                                    All rights reserved.
                              </div>
                              <div className="footer-infinity-company">
                                    Powered By{' '}
                                    <a
                                          href="https://infinityinfosys.com/"
                                          className="text-decoration-none"
                                          title="Infinity Infosys"
                                    >
                                          Infinity Infosys
                                    </a>
                              </div>
                        </FooterCredential>
                  </FooterSection>
                  <FooterMobile>
                        <MobileContent>
                              <MobileItem to="/">
                                    <HomeIcon />
                              </MobileItem>
                              <MobileItem to="/products">
                                    <StoreIcon />
                              </MobileItem>
                              <MobileItem to="/carts">
                                    <ShoppingCart />({cartCtx.totalCart})
                              </MobileItem>
                              <MobileItem to="/wishlists">
                                    <HeartIcon />({wishlistCtx.totalWishlist})
                              </MobileItem>
                              <MobileItem to="/login">
                                    <UserIcon />
                              </MobileItem>
                        </MobileContent>
                  </FooterMobile>
            </>
      );
};
export default Footer;
