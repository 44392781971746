import React, { useContext, useEffect, useState } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Col, Container, Row } from 'react-bootstrap';
import {
      ShowroomContainer,
      ShowroomImage,
      ShowroomItem,
      ShowroomSummary,
      ShowroomTitle
} from './style';
import axios from 'axios';
import MetaContext from '../../stores/MetaContext';

const Showroom = () => {
      const [loading, setLoading] = useState(false);
      const [showrooms, setShowrooms] = useState([]);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('showroom');

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/showrooms`, {
                        headers: {
                              apikey: process.env.REACT_APP_Api_Key
                        }
                  })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setShowrooms(response.data.showrooms);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Breadcrumb title="Showroom" />
                  <Container>
                        <ShowroomContainer>
                              <Row className="g-5">
                                    {!loading
                                          ? showrooms?.map(
                                                  (showroom, index) => (
                                                        <Col lg={6} key={index}>
                                                              <ShowroomItem
                                                                    to={`/showrooms/${showroom.slug}`}
                                                                    target="_blank"
                                                              >
                                                                    <ShowroomTitle>
                                                                          {
                                                                                showroom.title
                                                                          }
                                                                    </ShowroomTitle>
                                                                    <ShowroomImage>
                                                                          <img
                                                                                src={
                                                                                      showroom.image
                                                                                }
                                                                                alt={
                                                                                      showroom.title
                                                                                }
                                                                          />
                                                                    </ShowroomImage>
                                                                    <ShowroomSummary>
                                                                          <p>
                                                                                {
                                                                                      showroom.summary
                                                                                }
                                                                          </p>
                                                                    </ShowroomSummary>
                                                              </ShowroomItem>
                                                        </Col>
                                                  )
                                            )
                                          : null}
                              </Row>
                        </ShowroomContainer>
                  </Container>
            </>
      );
};

export default Showroom;
