import axios from 'axios';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Row } from 'react-bootstrap';
import { BlogContainer, ExporeMore } from '../../styles/frontend/blogs';
import BlogItemBox from '../../components/frontend/blogs';
import BlogBannerSlider from '../../components/sliders/BlogBannerSlider';
import SocialMediaList from '../../components/frontend/blogs/SocialMediaList';
import MetaContext from '../../stores/MetaContext';

export const BlogList = () => {
      const [blogs, setBlogs] = useState([]);
      const [loading, setLoading] = useState(false);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('blogs');

      useEffect(() => {
            const loadData = async () => {
                  setLoading(true);
                  await axios
                        .get(`${process.env.REACT_APP_SECRET_KEY}/api/blogs`, {
                              headers: {
                                    apikey: process.env.REACT_APP_Api_Key
                              }
                        })
                        .then((response) => {
                              if (response.data.result === 'success') {
                                    setBlogs(response.data.blogs);
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
                  setLoading(false);
            };
            loadData();
      }, []);

      return (
            <>
                  <BlogContainer>
                        <BlogBannerSlider loading={loading} blogs={blogs} />

                        <ExporeMore>
                              <h4>Explore More</h4>
                        </ExporeMore>

                        <Row className="g-4">
                              {!loading ? (
                                    blogs.length > 0 ? (
                                          blogs.map((blog, index) => (
                                                <BlogItemBox
                                                      key={index}
                                                      title={blog.title}
                                                      tags="Bathnroom, Shopping Guide"
                                                      image={blog.image}
                                                      date={blog.date}
                                                      link={`/blogs/${blog.slug}`}
                                                />
                                          ))
                                    ) : (
                                          <>No Blog Found</>
                                    )
                              ) : (
                                    <>Loading</>
                              )}
                        </Row>
                  </BlogContainer>
                  <SocialMediaList />
            </>
      );
};
