import React from 'react';
import {
      DashboardContentCol,
      DashboardContentCount,
      DashboardContentDetail,
      DashboardContentIcon,
      DashboardContentSummary,
      DashboardContentTotal
} from './style';

const DashboardCard = ({ icon, title, total, bgColor, color }) => {
      return (
            <>
                  <DashboardContentCol>
                        <DashboardContentSummary>
                              <DashboardContentIcon
                                    bgColor={bgColor}
                                    color={color}
                              >
                                    {icon}
                              </DashboardContentIcon>
                              <DashboardContentDetail>
                                    <DashboardContentTotal>
                                          {title}
                                    </DashboardContentTotal>
                                    <DashboardContentCount>
                                          {total}
                                    </DashboardContentCount>
                              </DashboardContentDetail>
                        </DashboardContentSummary>
                  </DashboardContentCol>
            </>
      );
};

export default DashboardCard;
