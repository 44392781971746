import { CategoryBox, CategoryBoxImage } from '../../../styles/frontend/shop';

const CategoryItem = ({ title, image, link }) => {
      return (
            <>
                  <CategoryBox to={link}>
                        <CategoryBoxImage>
                              <img
                                    src={image}
                                    alt={title}
                                    className="img-fluid"
                              />
                        </CategoryBoxImage>
                        <h4>{title}</h4>
                  </CategoryBox>
            </>
      );
};
export default CategoryItem;
