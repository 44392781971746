import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { Loading } from '../../../../styles/components/form/Loading';
import { Button } from '../../../../styles/components/form/Button';

const FormButton = ({ loading, title }) => {
      return (
            <>
                  <Form.Group as={Col} lg={12} className="text-center pt-2">
                        <Button type="submit">
                              {title || 'Submit'}
                              {loading && <Loading />}
                        </Button>
                  </Form.Group>
            </>
      );
};

export default FormButton;
