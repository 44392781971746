import styled from 'styled-components';

export const DashboardContentCol = styled.div`
      display: flex;
      height: 100%;
`;

export const DashboardContentSummary = styled.div`
      display: flex;
      padding: 1rem;
      align-items: center;
      width: 100%;
      border-radius: 0.5rem;
      border: 1px solid #f2f2f2;
`;

export const DashboardContentIcon = styled.div`
      display: flex;
      padding: 1rem;
      margin-right: 1rem;
      background-color: ${(props) => props.bgColor || '#fecaca'};
      color: ${(props) => props.color || '#dc2626'};
      font-size: 1.25rem;
      line-height: 1.75rem;
      text-align: center;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      border-radius: 9999px;
`;

export const DashboardContentDetail = styled.div``;

export const DashboardContentTotal = styled.h5`
      margin-bottom: 0.5rem;
      color: #374151;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 500;
      line-height: 1;
      width: max-content;
`;

export const DashboardContentCount = styled.p`
      color: #1f2937;
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 700;
      line-height: 1;
`;
