import React, { useContext } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Col, Container, Row } from 'react-bootstrap';
import {
      FaqTabButton,
      FaqTabContainer,
      FaqTabContent,
      FaqTabHeader,
      FaqTabPanel
} from '../../styles/frontend/faqs';
import { useState } from 'react';
import AccordionContainer from './AccordionContainer';
import { useEffect } from 'react';
import axios from 'axios';
import { EnquiryFooter, EnquirySlider } from '../../styles/frontend/dealers';
import { SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import SupportTicketIcon from '../../components/icons/SupportTicketIcon';
import {
      DealerIcon,
      PrivacyIcon,
      TermsCondition
} from '../../components/icons';
import MetaContext from '../../stores/MetaContext';

const Faq = () => {
      const [activeTab, setActiveTab] = useState(0);
      const [faqTypes, setFaqTypes] = useState([]);
      const [loading, setLoading] = useState(false);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('faqs');

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/faqs`, {
                        headers: {
                              apikey: process.env.REACT_APP_Api_Key
                        }
                  })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setFaqTypes(response.data.types);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleTabClick = (tabName) => {
            setActiveTab(tabName);
      };

      const options = {
            margin: 30,
            responsiveClass: true,
            nav: false,
            dots: false,
            lazyLoad: true,
            autoplay: false,
            navText: ['Prev', 'Next'],
            smartSpeed: 1000,
            responsive: {
                  0: {
                        items: 1
                  },
                  400: {
                        items: 1
                  },
                  600: {
                        items: 2
                  },
                  700: {
                        items: 3
                  },
                  1000: {
                        items: 4
                  }
            }
      };

      return (
            <>
                  <Breadcrumb title="FAQs" />

                  <Container className="mt-5">
                        <Row>
                              <Col lg={12}>
                                    <FaqTabContainer>
                                          <FaqTabHeader>
                                                {!loading ? (
                                                      faqTypes.length > 0 &&
                                                      faqTypes.map(
                                                            (type, index) => (
                                                                  <FaqTabButton
                                                                        className={`${
                                                                              activeTab ===
                                                                              index
                                                                                    ? 'active'
                                                                                    : ''
                                                                        }`}
                                                                        onClick={() =>
                                                                              handleTabClick(
                                                                                    index
                                                                              )
                                                                        }
                                                                  >
                                                                        {
                                                                              type.title
                                                                        }
                                                                  </FaqTabButton>
                                                            )
                                                      )
                                                ) : (
                                                      <>Loading</>
                                                )}
                                          </FaqTabHeader>

                                          <FaqTabContent>
                                                {!loading ? (
                                                      faqTypes.length > 0 &&
                                                      faqTypes.map(
                                                            (type, index) => (
                                                                  <FaqTabPanel
                                                                        className={`${
                                                                              activeTab ===
                                                                              index
                                                                                    ? 'active'
                                                                                    : ''
                                                                        }`}
                                                                  >
                                                                        {type
                                                                              .faqs
                                                                              .length >
                                                                              0 && (
                                                                              <AccordionContainer
                                                                                    items={
                                                                                          type.faqs
                                                                                    }
                                                                              />
                                                                        )}
                                                                  </FaqTabPanel>
                                                            )
                                                      )
                                                ) : (
                                                      <>Loading</>
                                                )}
                                          </FaqTabContent>
                                    </FaqTabContainer>
                              </Col>
                        </Row>
                  </Container>

                  <EnquiryFooter>
                        <Container>
                              <EnquirySlider {...options} className="owl-theme">
                                    <SwiperSlide>
                                          <Link to="/customer-enquiry">
                                                <div>
                                                      <SupportTicketIcon />
                                                </div>
                                                <div>Customer Enquiry</div>
                                          </Link>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                          <Link to="/dealer-enquiry">
                                                <div>
                                                      <DealerIcon />
                                                </div>
                                                <div>Dealer Enquiry</div>
                                          </Link>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                          <Link to="/terms-and-conditions">
                                                <div>
                                                      <TermsCondition />
                                                </div>
                                                <div>Terms & Conditions</div>
                                          </Link>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                          <Link to="/support-ticket">
                                                <div>
                                                      <SupportTicketIcon />
                                                </div>
                                                <div>Support Ticket</div>
                                          </Link>
                                    </SwiperSlide>
                              </EnquirySlider>
                        </Container>
                  </EnquiryFooter>
            </>
      );
};

export default Faq;
