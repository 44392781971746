import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const AdditionalDetail = styled.ul`
      margin-top: 15px;

      li {
            font-size: 18px;

            span:first-child {
                  margin-right: 20px;
            }
      }
`;

export const ReviewContent = styled.div`
      margin-top: 2rem;
`;

export const ReviewLogin = styled.div`
      /* height: 450px; */
      width: 100%;
      text-align: center;

      p {
            font-size: 24px;
      }
`;

export const ReviewLoginContent = styled.div`
      display: flex;
      gap: 20px;
      justify-content: center;
      margin-top: 10px;
`;

export const LoginButton = styled(Link)`
      background-color: ${(props) => props.theme.primaryColor};
      color: ${(props) => props.theme.white};
      padding: 8px 30px;
      display: block;
      width: max-content;

      &:hover {
            color: ${(props) => props.theme.white};
            background-color: ${(props) => props.theme.secondaryColor};
      }

      &:last-child {
            background-color: ${(props) => props.theme.secondaryColor};

            &:hover {
                  background-color: ${(props) => props.theme.primaryColor};
            }
      }
`;
