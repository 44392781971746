import React, { useState, useEffect, useContext } from 'react';
import Category from '../../component/category';
import ShopSlider from '../../component/shop/shopslider';
import Service from '../../component/shop/service';
import ShopBanner from '../../component/shop/shopslider/ShopBanner';
import Brands from '../../component/shop/brands';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Container } from 'react-bootstrap';

import axios from 'axios';
import { ProductContainer } from '../../../styles/frontend/shop';
import MetaContext from '../../../stores/MetaContext';

const ShopLanding = () => {
      const [categories, setCategories] = useState([]);
      const [brands, setBrands] = useState([]);
      const [loading, setLoading] = useState(false);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('products');

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/categories`, {
                        headers: {
                              apikey: process.env.REACT_APP_Api_Key
                        }
                  })
                  .then((response) => {
                        setCategories(response.data.categories);
                        setBrands(response.data.brands);
                        setLoading(false);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      };
      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Breadcrumb title="Product List" />
                  <ProductContainer>
                        {!loading ? (
                              categories.map((category, index) => (
                                    <Category
                                          key={index}
                                          categories={category}
                                          loading={loading}
                                    />
                              ))
                        ) : (
                              <div>Loading</div>
                        )}
                  </ProductContainer>
                  <ProductContainer>
                        <ShopSlider />
                  </ProductContainer>
                  <Service />
                  <Container>
                        <ShopBanner />
                  </Container>
                  <Brands brands={brands} loading={loading} />
            </>
      );
};
export default ShopLanding;
