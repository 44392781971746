import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper';
import { HomeCategoryBox } from '../../../styles/frontend/shop';
import CategoryItem from './CategoryItem';

export default function Category({ categories, loading }) {
      return (
            <>
                  <HomeCategoryBox>
                        {!loading ? (
                              categories.length > 0 ? (
                                    <Swiper
                                          slidesPerView={1}
                                          spaceBetween={0}
                                          pagination={{
                                                clickable: true
                                          }}
                                          autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false
                                          }}
                                          breakpoints={{
                                                640: {
                                                      slidesPerView: 2,
                                                      spaceBetween: 20
                                                },
                                                768: {
                                                      slidesPerView: 4,
                                                      spaceBetween: 40
                                                },
                                                1024: {
                                                      slidesPerView: 4,
                                                      spaceBetween: 20
                                                }
                                          }}
                                          modules={[Autoplay]}
                                          className="mySwiper"
                                    >
                                          {categories
                                                .slice(0, 4)
                                                .map((category, index) => (
                                                      <SwiperSlide key={index}>
                                                            <CategoryItem
                                                                  title={
                                                                        category.title
                                                                  }
                                                                  link={`/products/categories/${category.slug}`}
                                                                  image={
                                                                        category.image
                                                                  }
                                                            />
                                                      </SwiperSlide>
                                                ))}
                                    </Swiper>
                              ) : (
                                    <>Loading</>
                              )
                        ) : (
                              <>Loading</>
                        )}
                  </HomeCategoryBox>
            </>
      );
}
