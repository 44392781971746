import React from 'react';
import {
      MobileFilter,
      MobileFilterBtn,
      MobileFilterIcon
} from '../../styles/frontend/category';
import { Offcanvas } from 'react-bootstrap';

const CategoryMobileFilter = ({ show, handleShow, handleClose, children }) => {
      return (
            <>
                  <MobileFilter>
                        <MobileFilterIcon>
                              <MobileFilterBtn
                                    onClick={handleShow}
                                    type="button"
                              >
                                    Filter
                              </MobileFilterBtn>
                        </MobileFilterIcon>
                  </MobileFilter>
                  <Offcanvas
                        style={{ background: 'white' }}
                        placement="end"
                        show={show}
                        onHide={handleClose}
                  >
                        <Offcanvas.Header closeButton>
                              <Offcanvas.Title>Advance Filter</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className="p-0">
                              {children}
                        </Offcanvas.Body>
                  </Offcanvas>
            </>
      );
};

export default CategoryMobileFilter;
