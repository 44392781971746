import styled from 'styled-components';

export const BreadcrumbContainer = styled.div`
      padding: 0 60px;
      background-image: linear-gradient(to right, rgb(0, 128, 128), #363636);

      @media screen and (max-width: 600px) {
            padding: 0 20px;
      }
`;

export const BreadcrumbContent = styled.div.attrs(() => ({
      className: 'container'
}))`
      display: flex;
      height: 122px;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 600px) {
            padding: 0;
            height: 100px;
      }
`;

export const BreadcrumbTitle = styled.div`
      color: white;

      h1 {
            font-weight: 300 !important;
            text-transform: capitalize;
            font-size: 40px;
            font-family: 'HelveticaNowDisplay-Light', sans-serif;
      }

      @media screen and (max-width: 600px) {
            h1 {
                  font-size: 24px;
                  line-height: 1;
            }
      }
`;

export const BreadcrumbMenu = styled.ol`
      padding: 0;
      margin: 0;
      display: flex;
      text-transform: capitalize;

      li {
            a {
                  color: white;

                  &:hover {
                        color: #fafafa;
                  }
            }

            &::after {
                  content: '';
                  background-color: rgba(229, 229, 229, 0.5);
                  height: 11px;
                  width: 2px;
                  display: inline-block;
                  margin: 0 25px;
                  vertical-align: middle;
            }

            &:last-child::after {
                  content: none;
            }
      }

      @media screen and (max-width: 600px) {
            display: none;
      }
`;
