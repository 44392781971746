import React from 'react';

const LogoutIcon = () => {
      return (
            <>
                  <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                  >
                        <path
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="32"
                              d="M336 112a80 80 0 00-160 0v96"
                        ></path>
                        <rect
                              width="320"
                              height="272"
                              x="96"
                              y="208"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="32"
                              rx="48"
                              ry="48"
                        ></rect>
                  </svg>
            </>
      );
};

export default LogoutIcon;
